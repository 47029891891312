import * as React from 'react';

function SvgPlayCircle(props) {
  return (
    <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6 1.5a4.5 4.5 0 110 9 4.5 4.5 0 010-9zm0-.75a5.25 5.25 0 100 10.5A5.25 5.25 0 006 .75zM4.5 8.625a.375.375 0 01-.375-.375v-4.5c0-.132.07-.255.184-.322a.375.375 0 01.375 0l4.125 2.25a.375.375 0 010 .66l-4.125 2.25a.375.375 0 01-.184.037zm.375-4.245v3.24L7.841 6 4.875 4.38z"
        fill="#000"
        fillRule="evenodd"
        opacity={0.7}
      />
    </svg>
  );
}

export default SvgPlayCircle;
