import React from 'react';
import { Trans } from '@lingui/react';
import styled from 'styled-components';

const Element = styled.div`
  margin-bottom: ${props =>
    'undefined' !== typeof props.marginBottom
      ? `${props.marginBottom}px`
      : '20px'};
  & > label {
    margin-bottom: 7px;
    display: block;
  }
  label {
    font-size: 13px;
    font-weight: 600;
    letter-spacing: normal;
    color: ${props => props.theme.colors.neutrals.black};
  }
`;
const HelperMessage = styled.span`
  display: block;
  color: ${props => props.theme.colors.secondary['red-1']};
  font-size: 13px;
  font-weight: 400;
  margin-top: 6px;
`;

const FormGroup = props => {
  const { 'data-testid': dataTestId = 'formGroup' } = props;

  return (
    <Element marginBottom={props.marginBottom} data-testid={dataTestId}>
      {props.children}
      {!props.i18n && props.errorMsg ? (
        <HelperMessage data-testid={`${dataTestId}--error`}>
          {props.errorMsg}
        </HelperMessage>
      ) : null}
      {props.i18n && props.errorMsg ? (
        <HelperMessage data-testid={`${dataTestId}--error`}>
          <Trans id={props.errorMsg} />
        </HelperMessage>
      ) : null}
    </Element>
  );
};

export default FormGroup;
