import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

type Props = {
  title: string;
  description?: string;
  thumbnailUrl?: string;
  asPath?: string;
  availability?: string;
  brand?: string;
  condition?: string;
  price?: string;
  currency?: string;
  retailerId?: string;
};

const MetaTags = (props: Props) => {
  const router = useRouter();

  let thumbnail = process.env.DOMAIN + '/static/opengraph.png';
  if (props.thumbnailUrl) {
    if (
      props.thumbnailUrl?.startsWith('http') ||
      props.thumbnailUrl?.startsWith('//')
    ) {
      thumbnail = props.thumbnailUrl;
    } else {
      thumbnail = process.env.DOMAIN + props.thumbnailUrl;
    }
  }

  return (
    <Head>
      <title>{props.title + ' - Yogobe'}</title>
      {props.description ? (
        <meta name="description" content={props.description} />
      ) : null}

      {/* Open Graph / Facebook */}
      <meta property="og:url" content={process.env.DOMAIN + router.asPath} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={props.title + ' - Yogobe'} />
      <meta property="og:description" content={props.description ?? ''} />
      <meta property="og:image" content={thumbnail} />
      <meta property="fb:app_id" content="313516218776996" />
      {/* Twitter */}
      <meta
        property="twitter:title"
        content="Yoga och träning online för hållbar hälsa | YOGOBE - Yogobe"
      />
      <meta property="twitter:title" content={props.title + ' - Yogobe'} />
      <meta property="twitter:card" content={props.description ?? ''} />
      <meta property="twitter:description" content={props.description ?? ''} />
      <meta property="twitter:site" content={process.env.DOMAIN} />
      <meta property="twitter:image" content={thumbnail} />
      <meta property="twitter:creator" content="Yogobe" />
      {props.brand && <meta property="product:brand" content={props.brand} />}

      {/*  */}
      {props.availability && (
        <meta property="product:availability" content={props.availability} />
      )}

      {props.condition && (
        <meta property="product:condition" content={props.condition} />
      )}

      {typeof props.price === 'number' && (
        <meta property="product:price:amount" content={props.price} />
      )}

      {props.currency && (
        <meta property="product:price:currency" content={props.currency} />
      )}

      {props.retailerId && (
        <meta property="product:retailer_item_id" content={props.retailerId} />
      )}
    </Head>
  );
};

export default MetaTags;
