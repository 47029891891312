import { ProgramCategory } from '@gql/generated';

const theme = {
  colors: {
    channels: {
      yoga: '#129edc',
      breathing: '#88bfbd',
      workout: '#cc531d',
      meditation: '#965780',
    },
    neutrals: {
      realBlack: '#000',
      black: '#242424',
      'black-ligther': '#3c3c3c',
      'gray-dark': '#6e6e6e',
      gray: '#7d7d7d',
      'gray-3': '#c7c7c7',
      'white-darkest': '#eaeaea',
      'white-darker': '#f7f7f7',
      'light-gray': '#f2f2f2',
      white: '#ffffff',
      'black-1': '#cccccc',
      'black-2': '#e5e5e5',
      'black-3': 'rgba(0, 0, 0, 0.175)',
    },
    primary: {
      'blue-1': '#129edc',
      'blue-2': '#56b9e5',
      'blue-3': '#c5edff',
      'blue-4': '#dff5ff',
      'blue-5': '#51667a',
      'pink-1': '#cd206a',
      'pink-2': '#ea6ea3',
      'pink-3': '#fae0eb',
      'pink-4': '#fbedf3',
      stone: '#667c91',
      green: '#32847e',
      'green-1': '#d9e8e7',
      'green-2': '#213e3d',
      crystalBlue: '#6C97A7',
    },
    secondary: {
      green: '#11852E',
      'green-2': '#2F7518',
      'green-3': '#88bfbd',
      'green-4': '#9dc9c7',
      'green-5': '#d9e8e7',
      'gray-1': '#6e96a8',
      'gray-2': '#87a5b4',
      'gray-3': '#d1d9df',
      'gray-4': '#eeeeee',
      'gray-5': '#667c91',
      'grey-6': '#566776',
      black: '#d9d9d9',
      'red-1': '#e53b4d',
      'red-2': '#e86062',
      yellow: '#e2d2af',
      blue: '#119edd',
      orange: '#F3A661',
      'orange-1': '#ffa351',
      'light-bronze': '#F2ECDD',
      gold: '#CCB375',
    },
    interactive: {
      'color-1': '#41B1E3',
      'color-2': '#108DC5',
      'color-3': '#D7D7D7',
      'color-4': '#D74C87',
      'color-5': '#B81C5F',
      'color-6': '#454857',
      'color-7': '#6A6C78',
      'color-8': '#3A3A3A',
      'color-9': '#B0D3D2',
      'color-10': '#8CB4B2',

      'color-11': '#77C7EA',
      'color-12': '#4DA6CD',
      'color-13': '#EE8BB5',
      'color-14': '#D26292',
      'color-15': '#404040',
      'color-16': '#666666',
      'color-17': '#393939',
      'color-18': '#dddddd',
    },
  },
  shadows: {
    light: 'rgba(0, 0, 0, 0.05)',
  },
  viewports: {
    'x-small': 320,
    mobile: 425,
    tablet: 768,
    laptop: 1024,
    desktop: 1220,
  },
  breakpoints: ['426px', '769px', '1024px'],
  sizes: {
    MobileBreakpoint: 320,
    MobileLargeBreakpoint: 425,
    TabletBreakpoint: 768,
    Breakpoint980: 980,
    Breakpoint740: 740,
    LaptopBreakPoint: 1024,
    DesktopBreakpoint: 1220,
    DesktopLargeBreakpoint: 1220,
    DesktopUpBreakpoint: 1400,
    FourKBreakPoint: 2560,
  },
  helpers: {
    getProgramThemeColor: (category: ProgramCategory) => {
      if (category === ProgramCategory['Challenges']) {
        return theme.colors.primary['green'];
      } else {
        return theme.colors.primary['stone'];
      }
    },
  },
};

export default theme;
