import React from 'react';
import styled from 'styled-components';
import { Box, Flex } from '@components/layout/Grid';

const StyledFooter = styled.div`
  position: sticky;
  bottom: 0;
  border-top: 1px solid ${props => props.theme.colors.secondary['gray-4']};
  background-color: white;

  @media (max-width: ${props => `${props.theme.viewports.tablet + 1}px`}) {
    width: 100%;
  }
`;

const Footer = props => {
  return (
    <Box
      className={props.className}
      px={[25, 25, 50]}
      py={[10, 15, 25]}
      as={StyledFooter}
    >
      <Flex justifyContent="space-between" alignItems="center" flexWrap="wrap">
        {props.children}
      </Flex>
    </Box>
  );
};

export default Footer;
