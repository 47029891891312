import * as React from 'react';

function SvgLinkIcon(props) {
  return (
    <svg width={16} height={16} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.625 3.38a3 3 0 00-4.25 0l.71.71a2.005 2.005 0 112.835 2.835l-4 4a2.003 2.003 0 01-2.835-2.83l.705-.71-.705-.71-.71.71a3 3 0 000 4.25A3 3 0 008.5 12.5a3 3 0 002.135-.88l4-4a3 3 0 00-.01-4.24zm-12.53 9.03a2 2 0 010-2.835l4-4a2 2 0 012.835 0c.375.378.58.892.57 1.425a2 2 0 01-.585 1.425L7.855 9.5l.71.71 1.06-1.06A3.009 3.009 0 105.37 4.895l-4 4a3 3 0 000 4.255A3 3 0 003.5 14c.802 0 1.57-.316 2.14-.88l-.71-.71a2 2 0 01-2.835 0z"
        fill="#000"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgLinkIcon;
