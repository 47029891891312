import React from 'react';
import Styled from 'styled-components';
import autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

const Autocomplete = Styled(autocomplete)<{ errorStyle?: string }>`
  font-size: 16px;
  border: 1px solid ${props => props.theme.colors.interactive['color-18']};
  border-color:  ${props =>
    props.errorStyle && props.theme.colors.secondary['red-1']};
  border-radius: 5px;
  background-color: ${props => props.theme.colors.neutrals.white};
  outline: none;
  cursor: pointer;

  &.Mui-focused {
    border: 1px solid #129edc;
  }

  /* descendents style */
  .MuiOutlinedInput-notchedOutline {
    display: none;
  }

  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 4px;
  }
`;

declare type Props = {
  ref?: React.Ref<unknown>;
  className?: string;
  name?: string;
  options: Array<flexibleObject>;
  defaultValue?: flexibleObject;
  getOptionDisabled?(flexibleObject): boolean;
  getOptionLabel?(flexibleObject): string;
  getOptionSelected?(arg0: Country, unknown): boolean;
  renderOption?(flexibleObject): any;
  onChange?(value: Country, e: React.ChangeEvent<unknown>): void;
  onBlur?(e: React.ChangeEvent<unknown>): void;
  onInputChange?(event: React.ChangeEvent<HTMLInputElement>): void;
  onInputChange?(event: React.ChangeEvent<HTMLInputElement>): void;
  value?: any;
};

const AutoComplete = React.forwardRef<unknown, Props>(
  ({ className, onChange, ...otherProps }, ref) => {
    return (
      <Autocomplete
        ref={ref}
        className={className}
        onBlur={otherProps.onBlur}
        renderInput={params => {
          return (
            <TextField
              {...params}
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
                name: otherProps.name,
              }}
            />
          );
        }}
        onChange={(e, value) => onChange && onChange(value, e)}
        autoHighlight
        {...otherProps}
      />
    );
  }
);

export default AutoComplete;
