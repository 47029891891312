import React, { useCallback, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import debounce from 'lodash/debounce';
import Link from 'next/link';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e8e8e8;
  pointer-events: auto;

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    /*  */
    width: 100%;
    flex-direction: row;
    align-items: start;
    justify-content: center;
    flex-wrap: wrap;
    border: 1px solid lightgrey;
    border-radius: 4px;
    padding: 20px 32px;
    margin: 0 auto;
    background-color: white;
  }
`;

export const Dropdown = styled.div`
  height: 0;
  width: 100%;
  overflow: hidden;
  box-sizing: content-box;
  transition: height 500ms ease-out;

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    position: absolute;
    top: 52px;
    left: 0;
    width: auto;
    opacity: 0;
    transform-origin: top;
    transition: opacity 200ms ease-out, transform 500ms ease,
      height 500ms ease-out;
    pointer-events: none;

    &.open {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(min-content, max-content));
    width: max-content;
    padding: 0;
  }
`;

export const Element = styled.div`
  margin: 12px 0;

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    margin-top: 10px;
    margin-right: 30px;
    margin-bottom: 10px;
  }
`;

export const Anchor = styled(Link).attrs({ className: 'SingleLink' })`
  display: block;
  text-decoration: none;
  cursor: pointer;
`;

export const Title = styled.p`
  display: flex;
  align-items: center;
  color: #242424;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  margin: 0;
  transition: color 300ms ease;

  ${Anchor} &:after {
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    margin: 0 0 0 auto;
    background-image: url(/static/icons/arrow-right-dark.svg);
    background-repeat: no-repeat;
    background-size: 14px;
    background-position: center 100%;
  }

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    ${Anchor} &:after {
      content: '';
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-left: 6px;
      background-image: url(/static/icons/arrow-right-blue.svg);
      background-repeat: no-repeat;
      background-size: 14px;
      background-position: center 100%;
      opacity: 0;
      transform: translateX(10px);
      transition: opacity 200ms ease, transform 200ms ease;
    }

    ${Anchor}:hover & {
      color: ${({ theme }) => theme.colors.primary['blue-1']};

      &:after {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
`;

export const Description = styled.p`
  width: 80%;
  color: rgba(36, 36, 36, 0.8);
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin: 4px 0 0;

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    width: 100%;
  }
`;

function useCombinedRefs(...refs) {
  const targetRef = React.useRef();

  React.useEffect(() => {
    refs.forEach(ref => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
}

type Props = {
  className?: string;
  items: React.ReactNode[];
  banner?: React.ReactNode;
  open?: boolean;
};

const MenuContent = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { className = '', items, banner = null, open = false } = props;
  const localRef = React.useRef<HTMLDivElement>();
  const theme = useTheme();

  const proxyRef = useCombinedRefs(ref, localRef);

  const updateHeight = () => {
    const isLargeScreen = window.matchMedia(
      `(min-width: ${theme.viewports.laptop}px)`
    )?.matches;
    const child = localRef.current?.querySelector(
      Content.toString()
    ) as HTMLDivElement;
    if (child !== undefined) {
      let height = `0px`;
      if (open) {
        if (isLargeScreen) {
          height = '100vh';
        } else {
          height = child.getBoundingClientRect().height + 'px';
          setTimeout(() => {
            // to keep element dynamic height once open
            localRef.current.style.height = 'auto';
          }, 200);
        }
      } else {
        // we switch elment from height auto to set height before shrinking to enable animation
        const height = localRef.current.getBoundingClientRect().height + 'px';
        localRef.current.style.height = height;
      }
      requestAnimationFrame(() => {
        localRef.current.style.height = height;
      });
    }
  };

  const resizeHandler = useCallback(
    debounce(updateHeight, 500, { maxWait: 2000 }),
    []
  );

  useEffect(() => {
    updateHeight();

    if (window.matchMedia(`(${theme.viewports.tablet}px)`)) {
      window.addEventListener('resize', resizeHandler);
      return () => window.removeEventListener('resize', resizeHandler);
    }
  }, [props.open]);

  return (
    <Dropdown
      ref={proxyRef}
      className={[className, open ? 'open' : null].join(' ')}
    >
      <Content>
        <Items>
          {items.map((item, i) => {
            return <Element key={i}>{item}</Element>;
          })}
        </Items>
        {banner}
      </Content>
    </Dropdown>
  );
});

export default MenuContent;
