import React from 'react';
import Styled from 'styled-components';
import { Body, Footer } from '@organisms/modals/base';
import { Trans } from '@lingui/react';
import Button from '@components/buttons';
import Icon from '@components/Icon';
import { Flex } from '@components/layout/Grid';
import { CONTACT_EMAIL } from '@lib/constants';

const Title = Styled.p`
  font-size: 24px;
  font-weight: 600;
  margin-top: 0;
  text-align: center;
`;

const Description = Styled.p`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  text-align: center;
  margin-bottom: 50px;

  span:nth-of-type(2){
    margin-top: 20px;
  }
`;

declare type Props = {
  onClose(): void;
};

export const BundlePaymentFailedMessage = ({ onClose }: Props) => {
  return (
    <>
      <Body data-testid="BundlePaymentFailedMessage">
        <Flex>
          <Icon
            name="ygb-icon-cancel"
            fontSize="40px"
            color="#FB5A4F"
            style={{
              lineHeight: 1,
              border: '5px solid #FB5A4F',
              borderRadius: '50%',
              padding: '25px',
              margin: '0 auto 20px',
            }}
          />
        </Flex>
        <Title>
          <Trans id="metadata.paymentfail.title" />
        </Title>
        <Description>
          <Trans
            id="metadata.payment.failed"
            components={[
              <a key="0" href={`mailto:${CONTACT_EMAIL}`}>
                {CONTACT_EMAIL}
              </a>,
            ]}
          />
        </Description>
      </Body>
      <Footer>
        <Button appearance="default-blue" onClick={onClose}>
          <Trans id="metadata.close" />
        </Button>
      </Footer>
    </>
  );
};
