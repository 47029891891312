import AppleSignin from 'react-apple-signin-auth';
import { AuthCallback } from '@ecosystems/socialAuth';
import { Icon, SocialButton } from './Element';

type Props = {
  onAuth: AuthCallback;
};

export const AppleAuthbutton = (props: Props) => {
  const onAppleResponse = resp => {
    const { firstName, lastName } = resp?.user?.name || {};
    props?.onAuth(resp?.authorization?.id_token, { firstName, lastName });
  };

  return (
    <AppleSignin
      /** Auth options passed to AppleID.auth.init() */
      authOptions={{
        /** Client ID - eg: 'com.example.com' */
        clientId: process.env.APPLE_CLIENT_ID,
        /** Requested scopes, seperated by spaces - eg: 'email name' */
        scope: 'email name',
        /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
        redirectURI: process.env.DOMAIN,
        /** State string that is returned with the apple response */
        state: 'state',
        /** Nonce */
        nonce: 'nonce',
        /** Uses popup auth instead of redirection */
        usePopup: true,
      }} // REQUIRED
      /** General props */
      uiType="dark"
      /** className */
      className="apple-auth-btn"
      /** Removes default style tag */
      noDefaultStyle={false}
      /** Allows to change the button's children, eg: for changing the button text */
      buttonExtraChildren="Continue with Apple"
      /** Extra controlling props */
      /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
      onSuccess={onAppleResponse} // default = undefined
      /** Called upon signin error */

      /** Skips loading the apple script if true */
      skipScript={false} // default = undefined
      /** Apple image props */
      iconProp={{ style: { marginTop: '10px' } }} // default = undefined
      /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
      render={props => (
        <SocialButton type="button" {...props}>
          <Icon src="/static/icons/apple.svg" /> Apple
        </SocialButton>
      )}
    />
  );
};
