import * as React from 'react';

function SvgCalendarIcon(props) {
  return (
    <svg viewBox="0 0 13 14" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.5 14a4 4 0 110-8 4 4 0 010 8zm0-7a3 3 0 100 6 3 3 0 000-6zm.795 4.5L8 10.205V8h1v1.795l1 1-.705.705zM12 2a1 1 0 00-1-1H9V0H8v1H4V0H3v1H1a1 1 0 00-1 1v10a1 1 0 001 1h2v-1H1V2h2v1h1V2h4v1h1V2h2v3h1V2z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgCalendarIcon;
