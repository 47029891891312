import styled from 'styled-components';
import bar from './base';

type BaseProps = {
  width?: number | string;
  height?: number | string;
  borderRadius?: number | string;
  shape?: string;
  animation?: boolean;
  marginBottom?: number;
  marginTop?: number;
};

const DynamicBar = styled(bar)<BaseProps>`
  margin: 0;
  padding: 0;

  width: ${props => (props.width ? props.width + '%' : '100%')};
  height: ${props => (props.height ? props.height + 'px' : '0px')};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '0px')};
  ${props => (props.shape === 'circle' ? 'border-radius: 50%;' : '')};

  ${props =>
    props.marginBottom ? `margin-bottom: ${props.marginBottom}px;` : ''};

  ${props => (props.marginTop ? `margin-top: ${props.marginTop}px;` : '')};

  ${props =>
    props.animation === false &&
    `
      animation-duration: none;
      animation-fill-mode: none;
      animation-iteration-count: none;
      animation-name: none;
      animation-timing-function: none;
    `};
`;

type Props = {
  className?: string;
  white?: boolean;
  children?: React.ReactChild;
} & BaseProps;

const BaseSkeleton = (props: Props) => {
  return <DynamicBar {...props} />;
};

export default BaseSkeleton;
