import React, { useState, useEffect, useContext } from 'react';
import { t } from '@lingui/macro';
import { Header, Body } from '@organisms/modals/base';
import { UserContext } from '@lib/contexts/UserProvider';
import { snackbarVar } from '@lib/apollo/policies';
import { PaymentModal } from './Elements';
import PaymentConfirmationModal from '../paymentConfirmation/PaymentConfirmationModal';
import SubscriptionPayment, {
  PaymentPayloads,
} from './SubscriptionPaymentForm';

const PROVIDERS = {
  ios: 'Apple',
  android: 'Google',
};

declare type Props = {
  open?: boolean;
  redirectTo?: string;
  redirectToSuccess?: string;
  redirectToFailed?: string;
  paymentType: string;
  amount: number;
  months: number;
  currency: string;
  sourcePage: string;
  code?: string;
  children(
    onOpen: () => void,
    arg1: { isOpen: boolean; openModal(): void; closeModal(): void }
  ): React.ReactNode;
  enableOneTimeMethods?: boolean;
  onPaymentComplete?(arg0: PaymentPayloads): void;
  handlePaymentCompletion?(arg0: PaymentPayloads): void;
  onClose?(): void;
};

const SubscriptionPaymentModal = (props: Props) => {
  const { open, children } = props;
  const [isOpen, setIsOpen] = useState(open);
  const [currentUser] = useContext(UserContext);
  const [paymentData, setPaymentData] = useState(null);

  const { handlePaymentCompletion } = props;

  useEffect(() => {
    // we clean up payment data if there is any
    // to hide confirmation if opened again
    if (!isOpen && paymentData) {
      setPaymentData(null);
    }
  }, [isOpen]);

  const onClose = () => {
    setIsOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  const onOpen = () => {
    const isActiveMobileSubscription =
      // mobile platform
      ['android', 'ios'].includes(
        currentUser?.subscription?.currentSubscriptionPlan?.platform
      ) &&
      // not in trial
      !['free_plan', 'trial'].includes(
        currentUser?.subscription?.currentSubscriptionPlan?.planType
      ) &&
      // it's active subscription
      currentUser?.subscription?.status === 'active';

    if (!isActiveMobileSubscription) {
      setIsOpen(true);
    } else {
      snackbarVar({
        isSnackBarOpen: true,
        // these should be made optional
        goTo: 'FAQ',
        id: '',
        snackBarMessage: t({
          id: 'settings_subscriptions.cancel_mobile_subscription',
          values: {
            provider:
              PROVIDERS[
                currentUser?.subscription?.currentSubscriptionPlan?.platform
              ],
          },
        }),
      });
    }
  };

  const onPaymentComplete = (paymentData: PaymentPayloads) => {
    // if page wants to handle the payment completion actions..
    if (typeof handlePaymentCompletion === 'function') {
      handlePaymentCompletion(paymentData);
    } else {
      // this will show the confirmation message
      setPaymentData(paymentData);
      // notify parent about payment completion
      props.onPaymentComplete?.(paymentData);
    }
  };

  if (paymentData) {
    return (
      <>
        <PaymentConfirmationModal
          open={true}
          orderId={paymentData.orderId}
          onClose={onClose}
        />
        {children(onOpen, { isOpen, openModal: onOpen, closeModal: onClose })}
      </>
    );
  }

  const modal = isOpen ? (
    <PaymentModal open={isOpen} data-testid="SubscriptionPaymentModal">
      <Header handleClose={onClose} />
      <Body pb={40} px={[15, 15, 50]}>
        <SubscriptionPayment
          paymentType={props.paymentType}
          amount={props.amount}
          months={props.months}
          currency={props.currency}
          sourcePage={props.sourcePage}
          onPaymentComplete={onPaymentComplete}
        />
      </Body>
    </PaymentModal>
  ) : (
    <React.Fragment />
  );

  return (
    <>
      {modal}
      {children(onOpen, { isOpen, openModal: onOpen, closeModal: onClose })}
    </>
  );
};

export default SubscriptionPaymentModal;
