import { useEffect, useRef, useState } from 'react';

const Stickable = props => {
  const [showSticky, setSticky] = useState(false);
  const scrollPosition = useRef(props.scrollAfterY);

  useEffect(() => {
    scrollPosition.current = props.scrollAfterY;
  }, [props.scrollAfterY]);

  const handleScroll = () => {
    if (window.scrollY >= scrollPosition.current) {
      setSticky(true);
    } else if (window.scrollY < scrollPosition.current) {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return props.children(showSticky);
};

export default Stickable;
