import styled from 'styled-components';
import { Trans } from '@lingui/react';
import useFlags from '@lib/hooks/useFlags';
import { EpassiElement, Props as EpassiProps } from './Epassi';

const Title = styled.p`
  font-size: 14px;
  font-weight: 600;
`;

type Props = Pick<EpassiProps, 'getEpassiData'>;

export const WellnessMethods = (props: Props) => {
  const [flags] = useFlags();

  if (flags.disable_epassi) {
    return null;
  }

  return (
    <div>
      <Title>
        <Trans id="payment_modal.wellness_methods.title" />
      </Title>
      <EpassiElement getEpassiData={props.getEpassiData} />
    </div>
  );
};
