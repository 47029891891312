import { Trans } from '@lingui/react';
import React, { Component } from 'react';
import styled from 'styled-components';
import Icon from '@components/Icon';
import {
  Box,
  Container as GlobalContainer,
  Flex,
} from '@components/layout/Grid';
import { PBanner } from '@components/texts';
import CookieManager from '@lib/CookieManager';

const Container = styled.div`
  background-color: ${props => props.theme.colors.primary['blue-5']};
  width: 100%;
  margin: 0;
  z-index: 8;
  padding: 10px 20px;
`;

class Banner extends Component {
  state = {
    shown: false,
  };

  isiOS = () => navigator.userAgent.match(/iPhone|iPad|iPod/i);

  closeBanner = () => {
    CookieManager.set({ key: 'closed_ios_banner', value: true });
    this.setState({
      shown: false,
    });
  };

  componentDidMount() {
    this.setState({
      shown: this.isiOS() && !CookieManager.get({ key: 'closed_ios_banner' }),
    });
  }

  render() {
    const { shown } = this.state;
    return shown ? (
      <Container>
        <GlobalContainer flexDirection={'column'}>
          <Flex alignItems="center" justifyContent="space-between">
            <Box mx="10px">
              <PBanner>
                <Trans
                  id="banner.castBanner"
                  message={`We have just released a new cast feature. To watch the videos on your TV, use airplay or chromecast* (*Is not currently supported on iOS)`}
                />
              </PBanner>
            </Box>
            <Box mx="10px">
              <Icon
                name="ygb-icon-close"
                color={`rgba(0,0,0,0.54)`}
                onClick={this.closeBanner}
              />
            </Box>
          </Flex>
        </GlobalContainer>
      </Container>
    ) : null;
  }
}

export default Banner;
