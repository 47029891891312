import ServerCookieManager from 'cookie';
import BrowserCookieManager from 'js-cookie';

export default {
  get({ key, req = null }) {
    if (req && req.headers && typeof window === 'undefined') {
      // Server side
      const cookies = req.headers.cookie;
      if (typeof cookies === 'string') {
        const cookiesJSON = ServerCookieManager.parse(cookies);
        return cookiesJSON[key];
      }
    } else {
      // Client-side
      return BrowserCookieManager.get(key);
    }
    return undefined;
  },

  set({ key, value, path = '/' }, expires: number | Date = 7) {
    if (!value) return;
    const jsonValue = typeof value !== 'string' ? JSON.stringify(value) : value;

    BrowserCookieManager.set(key, jsonValue, { expires, path });
  },

  remove({ key, res = null }) {
    if (res && typeof window === 'undefined') {
      // Server side
      res.setHeader('Set-Cookie', ServerCookieManager.serialize(key, ''));
    } else {
      // Client-side
      BrowserCookieManager.remove(key);
      BrowserCookieManager.remove(key, { domain: '.yogobe.com' });
    }
  },
};
