import React, { useState, useEffect, useContext } from 'react';
import Styled, { createGlobalStyle } from 'styled-components';
import { useRouter } from 'next/router';
import { Flex } from '@components/layout/Grid';
import RegisterFormTemplate from '@ecosystems/registerForm.pageTemplate';
import Button from '@components/buttons';
import Step1 from '@ecosystems/register/welcome/step1';
import Step2 from '@ecosystems/register/welcome/step2';
import { Trans } from '@lingui/react';
import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { Box } from '@components/layout/Grid';
import { UserContext } from '@lib/contexts/UserProvider';
import { t } from '@lingui/macro';
import MetaTags from '@components/MetaTags';
import { Mutation, PlanTypeKind } from '@gql/generated';
import Spinner from '@components/Spinner';
import { snackbarVar } from '@lib/apollo/policies';
import AnalyticsManager from '@lib/analytics/manager';
import { ErrorMessage as errorMessage } from '@components/texts';

const ON_BOARDING_CHOICES_QUERY = gql`
  query onboardingChoices {
    onboardingChoices {
      topics {
        name
        imageUrl
        slug
      }
      interests {
        name
        slug
      }
    }
  }
`;

const SAVE_ON_BOARDING_CHOICES_MUTATION = gql`
  mutation saveOnboardingChoices(
    $topicSlugs: [String!]
    $interestSlugs: [String!]
  ) {
    saveOnboardingChoices(
      topicSlugs: $topicSlugs
      interestSlugs: $interestSlugs
    ) {
      success
      errors {
        message
      }
    }
  }
`;

const GlobalStyle = createGlobalStyle`
  #__next {
    overflow-x: unset;
  }
`;

export const ErrorMessage = Styled(errorMessage)`
  min-width: 100%;
  padding-left: 0;
  margin: 10px 0 0 -20px;
`;

const getResponseErrors = (dataErrors, errors) => {
  if (dataErrors?.length > 0) {
    return dataErrors.map(error => (
      <ErrorMessage key={error.message} data-testid="onboarding.errors.message">
        {error.message}
      </ErrorMessage>
    ));
  } else if (errors?.graphQLErrors) {
    return [
      <ErrorMessage
        key="unexpectedError"
        data-testid="onboarding.errors.message"
      >
        <Trans id="metatadata.errors.unexpectedError" />
      </ErrorMessage>,
    ];
  }
  return null;
};

const Wrapper = Styled(RegisterFormTemplate.Wrapper)``;

const LeftContainer = Styled(RegisterFormTemplate.LeftContainer)`
  display: none;

  @media(min-width: ${({ theme }) => theme.viewports.laptop}px){
    position: sticky;
    top: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    background-color: rgba(145, 215, 247, 0.3);
    background-image: url('/static/jason-leung-einR1jXesBY.jpg');
    background-position: center;
    background-size: cover;
    overflow: hidden;
  }
`;

const SkipButton = Styled(Button)`
  flex-shrink: 0;
`;

const LeftBox = Styled.div`
  max-width: 600px;
  margin: 60px;

  i[name=ygb-icon-yogobe-logo2019_1]:before {
    font-size: 30px;
    color: ${({ theme }) => theme.colors.neutrals.white};
  }

  @media(min-width: ${({ theme }) => theme.viewports.laptop}px){
    padding: 30px;
    margin-right: 10%;
  }
`;

const FormTitle = Styled.p`
  color: ${({ theme }) => theme.colors.neutrals.black};
  font-size: 28px;
  font-weight: 600;
  line-height: 26px;
  text-transform: capitalize;
  margin: 0 0 10px;
`;

const RightContainer = Styled(RegisterFormTemplate.RightContainer)`
  position: relative;
  background-color: ${({ theme }) => theme.colors.neutrals.white};

  @media(min-width: ${({ theme }) => theme.viewports.tablet}px){
    max-width: 80%;
    margin: 0 auto;
  }
`;

const RightBox = Styled.div`
  flex: 1;
  padding: 30px 20px 100px;
  margin: 40px auto;

  @media(min-width: ${({ theme }) => theme.viewports.laptop}px){
    max-width: 700px;
    padding: 20px 30px;
  }
`;

// const InviteBanner = Styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 8px 25px;
//   background-color: ${({ theme }) => theme.colors.primary['blue-4']};

//   p {
//     display: flex;
//     align-items: center;
//   }

//   i.ygb-icon-infob {
//     margin-right: 6px;
//     :before {
//       opacity: .4;
//       color: ${({ theme }) => theme.colors.neutrals.black};
//     }
//   }
// `;

// const DoneStepWrapper = Styled(RightBox)`
//   && {
//     max-width: 600px;
//     padding-bottom: 0;
//     margin-bottom: 0;
//   }
// `;

// const LanguageDisclamer = Styled.p`
//   padding: 20px;
//   background-color: ${({ theme }) => theme.colors.secondary['green-5']};
// `;

const Footer = Styled(RegisterFormTemplate.Footer)`
  position: static;
  width: 100%;
  margin-top: 40px;
`;

const HorizontalSeparator = Styled.div`
  height: 1px;
  margin: 20px 0;
  background-color: rgba(0, 0, 0, 0.1);
`;

// const DoneForm = () => {
//   const router = useRouter();
//   return (
//     <>
//       <InviteBanner>
//         <LanguageDisclamer>
//           <Icon className="ygb-icon-infob" color="#242424" fontSize="16px" />
//           <Trans id="onboarding.completed.invite_friends.text" />
//         </LanguageDisclamer>
//         <Link
//           as={`/${router.query.lang}/me/settings/referral-program`}
//           href="/[lang]/me/settings/[pageid]"
//         >
//           <Button
//             style={{ margin: '0 0 0 auto' }}
//             appearance="link"
//             href={`/${router.query.lang}/me/settings/referral-program`}
//           >
//             <Trans id="onboarding.completed.invite_friends.btn_text" />
//           </Button>
//         </Link>
//       </InviteBanner>
//       <DoneStepWrapper>
//         <FormTitle>
//           <Trans id="onboarding.completed.title" />
//         </FormTitle>
//         <RegisterFormTemplate.Paragraph
//           style={{ maxWidth: '600px' }}
//           size={20}
//           weight={400}
//         >
//           <Trans id="onboarding.completed.start_exploring" />
//         </RegisterFormTemplate.Paragraph>
//         <HorizontalSeparator />
//         <RegisterFormTemplate.Paragraph size={18}>
//           <Trans id="onboarding.completed.next_step" />
//         </RegisterFormTemplate.Paragraph>
//         <RegisterFormTemplate.Paragraph size={14} weight={400}>
//           <Trans id="Here are some shortcuts to get you started" />
//         </RegisterFormTemplate.Paragraph>
//         <Done />
//       </DoneStepWrapper>
//     </>
//   );
// };

const Choices = ({
  step,
  choicesData,
  selectedTopics,
  handleChange,
  completeOnBoardingProcess,
  savingLoading,
}) => {
  const router = useRouter();
  const { lang, to } = router.query;

  return (
    <form>
      {step === 'step1' ? (
        <Step1
          data={choicesData?.onboardingChoices?.topics}
          selectedTopics={selectedTopics.step1}
          onTopicClick={e => {
            handleChange('step1', e);
          }}
          onContinue={() => {
            const query = {};
            if (to) {
              query['to'] = to;
            }
            router.push({
              pathname: `/${lang}/welcome/step2`,
              query,
            });
          }}
          saving={savingLoading}
        />
      ) : null}
      {step === 'step2' ? (
        <Step2
          data={choicesData?.onboardingChoices?.['interests']}
          selectedInterests={selectedTopics.step2}
          onInterestClick={e => {
            handleChange('step2', e);
          }}
          onComplete={completeOnBoardingProcess}
          saving={savingLoading}
        />
      ) : null}
    </form>
  );
};

// step1 === topics
// step2 === interests
const OnBoardingPage = () => {
  const router = useRouter();
  const { lang, step, to } = router.query;
  const [responseErrors, setResponseErrors] = useState(null);
  const [currentUser, { refetch: refetchUser }] = useContext(UserContext);
  const [selectedTopics, setSelectedTopics] = useState({
    step1: [],
    step2: [],
  });

  useEffect(() => {
    // currentUser is set to null if not logged it
    // initially its undefined
    if (currentUser === null) {
      router.replace(
        '/[lang]/videos/genres/[id]',
        `/${lang}/videos/genres/all`
      );
    } else if (currentUser?.onboardingCompleted) {
      setSelectedTopics({
        step1: currentUser?.topics?.map?.(({ slug }) => slug) || [],
        step2: currentUser?.interests?.map?.(({ slug }) => slug) || [],
      });
    }
  }, [currentUser]);

  const [saveonboardingChoices, { loading: savingLoading }] = useMutation<{
    saveOnboardingChoices: Mutation['saveOnboardingChoices'];
  }>(SAVE_ON_BOARDING_CHOICES_MUTATION, {
    async onCompleted(data) {
      if (data?.saveOnboardingChoices?.success) {
        // if user hasnt finished reloading
        // user might be sent to dashboard and sent back again to on-boarding because onboardingCompleted is not true yet
        await refetchUser();
        if (to) {
          router.push(to as string);
        } else if (
          selectedTopics?.step1?.length === 0 &&
          selectedTopics?.step2?.length === 0
        ) {
          // redirect to done page
          router.push(`/${lang}/me/dashboard`);
        } else {
          // redirect to done page
          router.push(`/${lang}/me/recommendations`);
        }
      } else if (data.saveOnboardingChoices?.errors) {
        setResponseErrors(
          getResponseErrors(data.saveOnboardingChoices?.errors, null)
        );
      }
    },
    onError(errors) {
      setResponseErrors(getResponseErrors(null, errors));
    },
  });

  const { data: choicesData, loading: choicesLoading, refetch } = useQuery(
    ON_BOARDING_CHOICES_QUERY
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    setResponseErrors(null);
  }, [step]);

  useEffect(() => {
    refetch();
  }, [lang]);

  useEffect(() => {
    AnalyticsManager().sendRecommendationsSetupBeginEvent();
  }, []);

  const completeOnBoardingProcess = () => {
    saveonboardingChoices({
      variables: {
        topicSlugs: selectedTopics.step1 || [],
        interestSlugs: selectedTopics.step2 || [],
      },
    });
    //
    if (currentUser?.onboardingCompleted) {
      AnalyticsManager().sendRecommendationsEditedEvent();
    } else {
      AnalyticsManager().sendRecommendationsSetupCompleteEvent();
    }
  };

  const handleChange = (step, event) => {
    let _selectedTopics = selectedTopics[step];
    const element = event.target;
    if (element.checked && _selectedTopics?.length >= 3) {
      event.preventDefault();
      snackbarVar({
        isSnackBarOpen: true,
        snackBarMessage: t`registration.onboarding.max_selected`,
        origin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
      return null;
    } else if (element.checked) {
      _selectedTopics.push(element.value);
    } else {
      _selectedTopics = _selectedTopics.filter(
        topic => topic !== element.value
      );
    }
    setSelectedTopics({
      ...selectedTopics,
      [step]: _selectedTopics,
    });
  };

  if (choicesLoading) {
    return null;
  }

  return (
    <>
      <MetaTags title={t`onboarding.meta_title`} />
      <Wrapper>
        <GlobalStyle />
        <LeftContainer>
          <LeftBox>
            <RegisterFormTemplate.Logo />
          </LeftBox>
        </LeftContainer>
        <RightContainer>
          {['step1', 'step2'].includes(step as string) ? (
            <RightBox>
              <Flex justifyContent="space-between" alignItems="center">
                <FormTitle>
                  <Trans
                    id="onboarding.welcome.text"
                    values={{ name: currentUser?.firstName }}
                  />
                </FormTitle>
              </Flex>
              <Flex
                alignItems={['', 'center', 'center']}
                flexDirection={['column', 'row', 'row']}
              >
                <RegisterFormTemplate.Paragraph
                  style={{ maxWidth: '600px' }}
                  size={20}
                  weight={400}
                  mr={50}
                >
                  {currentUser?.onboardingCompleted ? (
                    <Trans id="onboarding.description.edit_text" />
                  ) : (
                    <Trans id="onboarding.description.text" />
                  )}
                </RegisterFormTemplate.Paragraph>
                {step === 'step1' ? (
                  <SkipButton
                    type="button"
                    appearance="outline"
                    onClick={completeOnBoardingProcess}
                    data-testid="welcome.step1.skip_btn"
                    loading={savingLoading}
                  >
                    <Trans id="onboarding.skip.btn_text" />
                  </SkipButton>
                ) : null}
              </Flex>
              <HorizontalSeparator />
              <RegisterFormTemplate.Paragraph size={18}>
                {step === 'step1' ? (
                  <Trans id="onboarding.select_topics.text" />
                ) : (
                  <Trans id="onboarding.select_interests.text" />
                )}
              </RegisterFormTemplate.Paragraph>
              <RegisterFormTemplate.Paragraph size={14} weight={400}>
                <Trans id="onboarding.select.choices" />
              </RegisterFormTemplate.Paragraph>
              {['fi', 'no'].includes(lang as string) ? (
                <p style={{ padding: '20px', backgroundColor: '#D9E8E7' }}>
                  {step === 'step1'
                    ? t`onboarding.missing_lang.topics`
                    : t`onboarding.missing_lang.interests`}
                </p>
              ) : null}
              {choicesLoading || !currentUser?.hasOwnProperty('topics') ? (
                <Flex justifyContent="center" alignItems="center" pt={100}>
                  <Spinner spinnerColor="#7e7e7e" size={24} />
                </Flex>
              ) : (
                <Choices
                  {...{
                    step,
                    choicesData,
                    selectedTopics,
                    handleChange,
                    completeOnBoardingProcess,
                    savingLoading,
                  }}
                />
              )}
              {responseErrors ? (
                <Box pl={20} data-testid="onboarding.errors">
                  {responseErrors}
                </Box>
              ) : null}
            </RightBox>
          ) : null}
          {/* {step === 'done' ? <DoneForm /> : null} */}
          <Footer />
        </RightContainer>
      </Wrapper>
    </>
  );
};

OnBoardingPage.getPageConfig = () => {
  return {
    layout: 'none',
  };
};

OnBoardingPage.forceAuth = true;

export default OnBoardingPage;
