import { normalizeImagePath } from '@lib/helpers';

const CoursePolicy = {
  typePolicies: {
    Course: {
      fields: {
        titleImageUrl: {
          merge(_, incoming) {
            return normalizeImagePath(incoming);
          },
        },
        externalImageUrl: {
          merge(_, incoming) {
            return normalizeImagePath(incoming);
          },
        },
      },
    },
  },
};

export default CoursePolicy;
