import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

export const icons = [
  'ygb-icon-edit',
  'ygb-icon-checkmark',
  // 'ygb-icon-flashlight-dashboard', //not working reason: stroke wait for nother icon with filled color
  // 'ygb-icon-heart-dashboard', //not working reason: stroke wait for nother icon with filled color
  // 'ygb-icon-restart', //not working reason: stroke wait for nother icon with filled color
  'ygb-icon-warning2',
  'ygb-icon-backward2',
  'ygb-icon-forward3',
  'ygb-icon-first',
  'ygb-icon-last',
  'ygb-icon-previous2',
  'ygb-icon-next2',
  'ygb-icon-cast_connected',
  'ygb-icon-cast',
  'ygb-icon-calendar-o',
  'ygb-icon-arrow-down',
  'ygb-icon-arrow-left',
  'ygb-icon-arrow-right',
  'ygb-icon-arrow-up',
  'ygb-icon-bloc',
  'ygb-icon-calendar',
  'ygb-icon-chevron-down',
  'ygb-icon-chevron-down-alt',
  'ygb-icon-chevron-left-alt',
  'ygb-icon-chevron-right-alt',
  'ygb-icon-chevron-up',
  'ygb-icon-chevron-up-alt',
  'ygb-icon-chevron-right',
  'ygb-icon-chevron-left',
  'ygb-icon-close',
  'ygb-icon-download',
  'ygb-icon-emoji1',
  'ygb-icon-emoji2',
  'ygb-icon-emoji3',
  'ygb-icon-emoji4',
  'ygb-icon-emoji5',
  'ygb-icon-fullscreen-alt',
  'ygb-icon-fullscreenb',
  'ygb-icon-hamburgermenub',
  'ygb-icon-heart-filled',
  'ygb-icon-heart-outline',
  'ygb-icon-infob',
  'ygb-icon-list',
  'ygb-icon-list-old',
  'ygb-icon-menu-hor',
  'ygb-icon-menu-verb',
  'ygb-icon-play',
  'ygb-icon-playlist',
  'ygb-icon-play-video-large',
  'ygb-icon-plus',
  'ygb-icon-plus-alt',
  'ygb-icon-questionb',
  'ygb-icon-searchb',
  'ygb-icon-settings',
  'ygb-icon-share',
  'ygb-icon-trash',
  'ygb-icon-volume',
  'ygb-icon-warningb',
  'ygb-icon-yogobe',
  'ygb-icon-dismiss',
  'ygb-icon-remove',
  'ygb-icon-close1',
  'ygb-icon-cancel',
  'ygb-icon-YBlogo-y',
  'ygb-icon-yb-icon-alt',
  'ygb-icon-facebook-ic',
  'ygb-icon-instagram-ic',
  'ygb-icon-linkedin-ic',
  'ygb-icon-twitter-ic',
  'ygb-icon-youtube-ic',
  'ygb-icon-checkmark',
  'ygb-icon-card-ic',
  'ygb-icon-banktransfer-ic',
  'ygb-icon-playlist-empty-ic',
  'ygb-icon-yogobe-logo2019_1',
  'ygb-icon-receiptsEmpty-ic',
  'ygb-icon-programlenght-ic',
  'ygb-icon-programlock-ic',
  'ygb-icon-quiz-ic',
  'ygb-icon-resume-ic',
  'ygb-icon-stat1-ic',
  'ygb-icon-stat2-ic',
  'ygb-icon-stat3-ic',
  'ygb-icon-stat4-ic',
  'ygb-icon-youhere-ic',
  'ygb-icon-Back-arrow-ic',
  'ygb-icon-program-ic',
] as const;

declare type ElementProps = {
  name?: typeof icons[number] | null | undefined;
  className?: string;
  fontSize?: string;
  color?: string;
  clickable?: boolean;
  customStyle?: any;
  mr?: string;
};

const I = styled.i<ElementProps>`
  font-size: ${props => (props.fontSize ? props.fontSize : '10px')};
  line-height: ${props => (props.fontSize ? props.fontSize : '10px')};
  :before {
    ${props => (props.clickable ? 'cursor: pointer;' : null)};
    font-size: ${props => (props.fontSize ? props.fontSize : '10px')};
    color: ${props => (props.color ? props.color : null)};
    margin-right: ${p => (p.mr ? p.mr : null)};
  }
  ${({ customStyle }) => customStyle}
`;

declare type Props = ElementProps & HTMLAttributes<HTMLElement>;

const Icon = React.forwardRef<HTMLLIElement, Props>((props, ref) => {
  return (
    <I ref={ref} {...props} className={`${props.name} ${props.className}`} />
  );
});

Icon.toString = I.toString;

export default Icon;
