const DocumentPolicy = {
  typePolicies: {
    Document: {
      fields: {
        name: {
          merge(_, incoming) {
            const name = String(incoming)
              .split('/')
              .reverse()[0];
            return name.replace(/[-_]/g, ' ');
          },
        },
      },
    },
  },
};

export default DocumentPolicy;
