import React, { useContext } from 'react';
import Styled from 'styled-components';
import { useRouter } from 'next/router';
import { Trans } from '@lingui/react';
import LanguageContext from '@lib/contexts/languageContext';
import Button from '@components/buttons';
import { FloatingButtons } from './step1';

const Topics = Styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px auto;
`;

const Topic = Styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-bottom: 20px;
`;

const TopicInput = Styled.input`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 0;
  appearance: initial;
  cursor: pointer;
`;

const Topic2Label = Styled.label`
  text-align: center;
  border: 1px solid ${({ theme }) => theme.colors.neutrals['black-2']};
  border-radius: 3px;
  padding: 20px;
  
  p {
    font-size: 18px;
    color: ${({ theme }) => theme.colors.neutrals.black};
    margin: 0;
    pointer-events: none;
  }
  
  ${TopicInput}:checked + & {
    border: 1px solid ${({ theme }) => theme.colors.channels.yoga};
    background-color: ${({ theme }) => theme.colors.neutrals['blue-4']};

    p {
      color: ${({ theme }) => theme.colors.channels.yoga};
    }
  }
`;

const DISABLED_INTERESTS = ['anatomy', 'pilates-and-barre', 'running'];

const Step2 = ({
  data,
  selectedInterests,
  onInterestClick,
  onComplete,
  saving = false,
}) => {
  const router = useRouter();
  const lang = useContext(LanguageContext);

  const onFinishClick = e => {
    e.preventDefault();
    onComplete();
  };

  return (
    <div data-testid="welcome.step2">
      <Topics data-testid="welcome.step2.items">
        {data?.map(topic => {
          if (
            ['en', 'fi'].includes(lang) &&
            DISABLED_INTERESTS.includes(topic.slug)
          ) {
            return null;
          }

          return (
            <Topic
              key={topic.slug}
              data-test-type="welcome.step2.item"
              {...(selectedInterests.includes(topic.slug)
                ? { 'data-testselected': true }
                : {})}
            >
              <TopicInput
                type="checkbox"
                name="topic[]"
                value={topic.slug}
                id={`input-${topic.slug}`}
                onClick={onInterestClick}
                {...(selectedInterests.includes(topic.slug)
                  ? { checked: true }
                  : {})}
              />
              <Topic2Label htmlFor={`input-${topic.slug}`}>
                <p>{topic.name}</p>
              </Topic2Label>
            </Topic>
          );
        })}
      </Topics>
      <FloatingButtons>
        <Button
          style={{ marginRight: '10px' }}
          appearance="outline"
          type="button"
          onClick={() => {
            router.push('/[lang]/welcome/[step]', `/${lang}/welcome/step1`);
          }}
          data-testid="welcome.step2.back_btn"
        >
          <Trans id="onboarding.back.btn_text" />
        </Button>
        <Button
          type="button"
          appearance="default-pink"
          onClick={onFinishClick}
          loading={saving}
          data-testid="welcome.step2.finish_btn"
        >
          <Trans id="onboarding.finish.btn_text" />
        </Button>
      </FloatingButtons>
    </div>
  );
};

export default Step2;
