import React from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/react';
import FormGroup from '@components/form/FormGroup';
import Input from '@components/form/NewInput';
import Button from '@components/buttons';
import { ClearSharp as ClearIcon } from '@material-ui/icons';
import { CodeStatusKind } from '@gql/generated';
import Icon from '@components/Icon';

const AccessCodeWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Label = styled.label`
  display: block;
  max-width: 513px;
  width: 100%;
  font-weight: 600;
  margin-top: 20px;

  input {
    height: 47px;
    margin-top: 8px;
  }
`;

const ApplyCodeButton = styled(Button)`
  flex-shrink: 0;
  height: 47px;
  min-width: 60px;
  font-size: 14px;
  color: #0000008a;
  text-transform: capitalize;
  padding: 0 14px;
  margin-left: 10px;

  i:before {
    color: rgba(0, 0, 0, 0.54);
  }

  svg {
    font-size: 24px;
    /* check mark is above the line */
    margin-bottom: -5px;
  }
`;

const HelperText = styled.span<{ _error?: boolean; _success?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 13px;
  color: ${({ theme, _error, _success }) => {
    if (_error) {
      return theme.colors.secondary['red-1'];
    } else if (_success) {
      return theme.colors.secondary['green-2'];
    } else {
      return theme.colors.neutrals.black;
    }
  }};
  margin-top: 10px;
`;

type Props = {
  inputLabel: React.ReactElement | string;
  inputName: string;
  error?: string;
  value: string;
  loading: boolean;
  //
  handleBlur?(event: React.FocusEvent<any, Element>): void;
  validatedAccessCode: boolean;
  validationState: CodeStatusKind;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
  clearAccessToken(): void;
  checkAccessCodeValidity(): void;
  validationText: string | React.ReactNode;
  buttonText?: React.ReactNode;
};

const AccessCodeInput = (props: Props) => {
  const {
    inputName,
    error,
    value,
    handleBlur,
    validatedAccessCode,
    validationState,
    clearAccessToken,
    checkAccessCodeValidity,
    onChange,
    inputLabel,
    validationText,
    loading,
    buttonText = <Icon name="ygb-icon-checkmark" />,
  } = props;

  return (
    <>
      <FormGroup
        errorMsg={error}
        marginBottom={0}
        data-testid="AccessCodeInput"
      >
        <AccessCodeWrapper>
          <Label data-testid="AccessCodeInput--Label">
            {React.isValidElement(inputLabel) ? (
              inputLabel
            ) : (
              <Trans id={inputLabel as string} />
            )}
            <Input
              name={inputName}
              value={value}
              onBlur={handleBlur}
              onChange={onChange}
              onKeyDown={e => {
                if (
                  e.key === 'Enter' &&
                  value &&
                  !loading &&
                  !validatedAccessCode
                ) {
                  checkAccessCodeValidity();
                }
              }}
              data-testid="AccessCodeInput--Input"
            />
          </Label>
          <ApplyCodeButton
            type="button"
            appearance="outline"
            loading={loading}
            onClick={() => {
              if (validatedAccessCode) {
                clearAccessToken();
              } else if (value) {
                checkAccessCodeValidity();
              }
            }}
            data-testid="AccessCodeInput--Button"
          >
            {validatedAccessCode ? (
              <ClearIcon fontSize="inherit" color="inherit" />
            ) : (
              buttonText
            )}
          </ApplyCodeButton>
        </AccessCodeWrapper>
      </FormGroup>
      {validationText ? (
        <HelperText
          data-testid="AccessCodeInput--ValidationText"
          data-teststate={
            validationState ? `AccessCodeInput--${validationState}` : null
          }
          _success={validationState === CodeStatusKind['Valid']}
          _error={validationState === CodeStatusKind['Invalid']}
        >
          {validationText}
        </HelperText>
      ) : null}
    </>
  );
};

export default AccessCodeInput;
