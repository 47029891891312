import React, { Component } from 'react';
import { Trans } from '@lingui/react';
import styled from 'styled-components';
import Button from './../../components/buttons';
import {
  Box,
  Container as GlobalContainer,
  Flex,
} from './../../components/layout/Grid';
import { PBanner } from './../../components/texts';

const Container = styled.div`
  background-color: ${props => props.theme.colors.primary['blue-5']};
  width: 100%;
  margin: 0;
  z-index: 8;
  padding: 20px;
`;

class Banner extends Component {
  state = {
    shown: false,
  };

  isSafari = () => {
    return /constructor/i.test(window.HTMLElement) ||
    (function(p) {
      return p.toString() === '[object SafariRemoteNotification]';
    })(
      !window['safari'] ||
        (typeof safari !== 'undefined' && safari.pushNotification)
    ) ||
    window.navigator.userAgent.match(/iPad/i) ||
    window.navigator.userAgent.match(/iPhone/i);
    // return /^((?!chrome|android|crios|fxios).)*safari/i.test(
    //   navigator.userAgent
    // );
  };

  componentDidMount() {
    this.setState({
      shown: this.isSafari(),
    });
  }

  render() {
    const { shown } = this.state;
    return shown ? (
      <Container>
        <GlobalContainer flexDirection={'column'}>
          <Flex alignItems="center" justifyContent="space-between">
            <Box mx="10px">
              <PBanner>
                <Trans
                  id="banner.download_chrome"
                  message={`When watching a video, you might experience some issues in Safari. We highly recommend using`}
                />
                {` `}
                <Button
                  appearance="link"
                  target={'_blank'}
                  href={'https://www.google.com/chrome/'}
                >
                  <Trans
                    id="banner.download_chrome_action"
                    message={`Google Chrome.`}
                  />
                </Button>
              </PBanner>
            </Box>
          </Flex>
        </GlobalContainer>
      </Container>
    ) : null;
  }
}

export default Banner;
