import * as React from 'react';

const SvgCalendarIconS = (props) => (
  <svg width={12} height={14} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11 1.015H9v-1H8v1H4v-1H3v1H1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-10a1 1 0 0 0-1-1Zm0 11H1v-7h10v7Zm0-8H1v-2h2v1h1v-1h4v1h1v-1h2v2Z"
      fill="#000"
      fillRule="evenodd"
      opacity={0.8}
    />
  </svg>
);

export default SvgCalendarIconS;
