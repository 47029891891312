import * as React from 'react';

function SvgVideoCameraIcon(props) {
  return (
    <svg viewBox="0 -87 472 472" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M467.102 26.527a10.009 10.009 0 00-9.899-.18L348.906 85.48V50c-.031-27.602-22.398-49.969-50-50H50C22.398.031.031 22.398 0 50v197.422c.031 27.601 22.398 49.969 50 50h248.906c27.602-.031 49.969-22.399 50-50v-34.836l108.301 59.133A10.001 10.001 0 00472 262.94V35.125a9.997 9.997 0 00-4.898-8.598zM328.898 247.426c-.015 16.562-13.437 29.98-30 30H50c-16.563-.02-29.98-13.438-30-30V50c.02-16.559 13.438-29.98 30-30h248.906c16.559.02 29.98 13.441 30 30zM452 246.09L348.906 189.8v-81.534L452 51.98zm0 0" />
    </svg>
  );
}

export default SvgVideoCameraIcon;
