import React, { useContext } from 'react';
import Styled from 'styled-components';
import { Trans } from '@lingui/react';
import { useRouter } from 'next/router';
import { Body, Footer } from '@organisms/modals/base';
import Icon from '@components/Icon';
import { Button as button } from '@components/ButtonV2';
import { WellnessPackagePaymentFieldsFragment } from '@gql/generated';
import { UserContext } from '@lib/contexts/UserProvider';
import { useFormatDuration } from '@lib/hooks/useFormatDuration';

const Button = Styled(button)`
  margin: 0 10px;
`;

const Wrapper = Styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const Title = Styled.p`
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`;

const Text = Styled.p`
  font-size: 16px;
  margin: 15px 0 0;
`;

declare type Props = {
  wellnessPackage: WellnessPackagePaymentFieldsFragment['wellnessPackage'];
  amountCents: number;
  currency: string;
  onClose(): void;
};

export const WellnessPackagePaymentConfirmation = (props: Props) => {
  const { amountCents, currency, wellnessPackage } = props;
  const formatDuration = useFormatDuration();

  const router = useRouter();
  const { lang } = router?.query;

  const [currentUser] = useContext(UserContext);

  let body = (
    <Trans
      id="paymentconfirmation_wellness.success_modal.desc"
      values={{
        amount: `${Math.floor(amountCents / 100).toFixed(2)} ${currency}`,
      }}
    />
  );

  if (
    wellnessPackage?.packageable?.__typename === 'WellnessAccess' &&
    currentUser?.paymentTypeRenews
  ) {
    const { planType, planCount, extraDays } = wellnessPackage?.priceBreakdown;

    let duration = {};
    if (planType === 'monthly') {
      duration['months'] = planCount;
    } else if (planType === 'quarterly') {
      duration['months'] = planCount * 3;
    } else if (planType === 'yearly') {
      duration['years'] = planCount;
    }

    if (extraDays) {
      duration['days'] = extraDays;
    }

    body = (
      <Trans
        id="paymentconfirmation_wellness.success_modal.active_subscription_desc"
        values={{
          amount: `${Math.floor(amountCents / 100).toFixed(2)} ${currency}`,
          access: formatDuration(duration, {
            delimiter: ', ',
          }),
        }}
      />
    );
  }

  return (
    <>
      <Body pb={40}>
        <Wrapper>
          <Icon
            name="ygb-icon-checkmark"
            fontSize="24px"
            color="white"
            style={{
              lineHeight: 1,
              backgroundColor: '#93BDBB',
              borderRadius: '50%',
              padding: '50px',
              margin: '0 auto 20px',
            }}
          />
          <Title>
            <Trans id="paymentconfirmation_wellness.success_modal.title" />
          </Title>
          <Text>{body}</Text>
        </Wrapper>
      </Body>
      <Footer>
        <Button
          appearance="default-blue"
          href={`/${lang}/me/settings/receipts`}
          data-testid="PaymentConfirmationMessageButton"
          outline
        >
          <Trans id="metadata.payment.success.receipt_button" />
        </Button>
        <Button appearance="default-blue" onClick={props.onClose}>
          <Trans id="metadata.payment.success.continue_button" />
        </Button>
      </Footer>
    </>
  );
};
