import { useState } from 'react';
import { Body, Modal } from '../base';

export const useTrustlyIframeModal = () => {
  let content = null;
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState(null);

  const onOpen = src => {
    setUrl(src);
    setOpen(true);
  };

  if (url && open) {
    content = (
      <Modal open>
        <Body className="h-[100vh]">
          <iframe
            onChange={console.log}
            src={url}
            className="size-full"
          ></iframe>
        </Body>
      </Modal>
    );
  }

  return [
    content,
    {
      open: onOpen,
      close: () => {
        setUrl(null);
        setOpen(false);
      },
    },
  ];
};
