import React, { useContext, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import { Trans } from '@lingui/react';
import debounce from 'lodash/debounce';
import { Box } from '@components/layout/Grid';
import MenuContent, { Title, Description, Items, Anchor } from './MenuContent';
import Subdropdown from './SubDropdown';
import { findParentElement } from '@lib/utils';
import LanguageContext from '@lib/contexts/languageContext';

const Menu = styled(MenuContent)`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    ${Items} {
      max-width: 1240px;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  @media (min-width: ${({ theme }) => theme.viewports.desktop}px) {
    width: 100vw;

    ${Items} {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
`;

const Row = styled.div``;

const BannerRow = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    display: initial;
  }
`;

const Column = styled.div`
  & + & {
    margin-top: 25px;
  }
`;

export const BannerBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 300px;
  border-radius: 3px;
  padding: 24px 20px;
  margin-bottom: 14px;
  background: linear-gradient(
      to left,
      rgba(17, 158, 221, 0.7),
      rgba(17, 158, 221, 0.7)
    ),
    no-repeat url(/static/Program3-Thumbnail.jpg);
  background-size: cover;
`;

const BannerText = styled.p`
  width: 193px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  margin: 0 0 14px;
`;

const BannerBtn = styled.a`
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  border: 1px solid currentColor;
  border-radius: 3px;
  padding: 8px 15px;
  cursor: pointer;
`;

const links = {
  en: [
    [
      {
        title: <Trans id="global_menu.more.blog.title" />,
        desc: <Trans id="global_menu.more.blog.desc" />,
        link: `${process.env.DOMAIN}/en/blog`,
      },

      {
        title: <Trans id="global_menu.more.store.title" />,
        desc: <Trans id="global_menu.more.store.desc" />,
        link: 'https://store.yogobe.com/',
      },

      {
        title: <Trans id="global_menu.more.team.title" />,
        desc: <Trans id="global_menu.more.team.desc" />,
        link: `${process.env.DOMAIN}/en/team`,
      },
    ],
    [
      {
        title: <Trans id="global_menu.more.about.title" />,
        desc: <Trans id="global_menu.more.about.desc" />,
        link: `${process.env.DOMAIN}/en/about`,
      },

      {
        title: <Trans id="global_menu.more.contact.title" />,
        desc: <Trans id="global_menu.more.contact.desc" />,
        link: `${process.env.DOMAIN}/en/contact`,
      },
      {
        title: <Trans id="global_menu.more.pricing.title" />,
        desc: <Trans id="global_menu.more.pricing.desc" />,
        link: `${process.env.DOMAIN}/se/pricing`,
      },
    ],
    [
      {
        title: <Trans id="global_menu.more.business.title" />,
        desc: <Trans id="global_menu.more.business.desc" />,
        subItems: [
          {
            text: <Trans id="global_menu.more.en_business.item1" />,
            link: `${process.env.DOMAIN}/en/business`,
          },
          {
            text: <Trans id="global_menu.more.en_business.item2" />,
            link: `${process.env.DOMAIN}/en/business/foretag-yoga-traning`,
          },
          {
            text: <Trans id="global_menu.more.en_business.item3" />,
            link: `${process.env.DOMAIN}/en/business/yogalarare-en`,
          },
        ],
      },
    ],
  ],
  se: [
    [
      {
        title: <Trans id="global_menu.more.blog.title" />,
        desc: <Trans id="global_menu.more.blog.desc" />,
        link: `${process.env.DOMAIN}/se/blog`,
      },

      {
        title: <Trans id="global_menu.more.store.title" />,
        desc: <Trans id="global_menu.more.store.desc" />,
        link: 'https://store.yogobe.com/',
      },

      {
        title: <Trans id="global_menu.more.team.title" />,
        desc: <Trans id="global_menu.more.team.desc" />,
        link: `${process.env.DOMAIN}/se/team`,
      },
      {
        title: <Trans id="global_menu.more.partners.title" />,
        desc: <Trans id="global_menu.more.partners.desc" />,
        link: `${process.env.DOMAIN}/se/foretag/partners`,
      },
    ],
    [
      {
        title: <Trans id="global_menu.more.about.title" />,
        desc: <Trans id="global_menu.more.about.desc" />,
        link: `${process.env.DOMAIN}/se/om-oss`,
      },

      {
        title: <Trans id="global_menu.more.non_profit.title" />,
        desc: <Trans id="global_menu.more.non_profit.desc" />,
        link: `${process.env.DOMAIN}/se/non-profit`,
      },
      {
        title: <Trans id="global_menu.more.contact.title" />,
        desc: <Trans id="global_menu.more.contact.desc" />,
        link: `${process.env.DOMAIN}/se/kontakt`,
      },
      {
        title: <Trans id="global_menu.more.pricing.title" />,
        desc: <Trans id="global_menu.more.pricing.desc" />,
        link: `${process.env.DOMAIN}/se/pricing`,
      },
    ],
    [
      {
        title: <Trans id="global_menu.more.friskvårdsbidrag.title" />,
        desc: <Trans id="global_menu.more.friskvårdsbidrag.desc" />,
        link: `/se/friskvardsbidrag`,
        openInpage: true,
      },
      {
        title: <Trans id="global_menu.more.business.title" />,
        desc: <Trans id="global_menu.more.business.desc" />,
        subItems: [
          {
            text: <Trans id="global_menu.more.se_business.item1" />,
            link: 'https://arbetsgivare.yogobe.com/',
          },
          {
            text: <Trans id="global_menu.more.se_business.item2" />,
            link: `${process.env.DOMAIN}/se/pausasmart`,
          },
          {
            text: <Trans id="global_menu.more.se_business.item3" />,
            link: `${process.env.DOMAIN}/se/foretag/yogalarare`,
          },
          {
            text: <Trans id="global_menu.more.se_business.item4" />,
            link: `${process.env.DOMAIN}/se/hotell`,
          },
        ],
      },
      {
        title: <Trans id="global_menu.more.healthcare.title" />,
        desc: <Trans id="global_menu.more.healthcare.desc" />,
        subItems: [
          {
            text: <Trans id="global_menu.more.se_healthcare.item1" />,
            link: `${process.env.DOMAIN}/se/c/far/b/far`,
          },
          {
            text: <Trans id="global_menu.more.se_healthcare.item2" />,
            link: `${process.env.DOMAIN}/se/healthcare`,
          },
        ],
      },
    ],
  ],
};

export const WellnessBanner = ({ className = '' }) => {
  return (
    <Box className={className}>
      <BannerBox>
        <BannerText>
          <Trans id="global_menu.more_menu.banner.title" />
        </BannerText>
        <BannerBtn href="/se/friskvardsbidrag">
          <Trans id="global_menu.more_menu.banner.button" />
        </BannerBtn>
      </BannerBox>
      {/* <TextLink href="#">Not finding what you’re looking for?</TextLink> */}
    </Box>
  );
};

const MoreMenu = ({ className = '', open = false }) => {
  const ref = React.createRef<HTMLDivElement>();
  const locale = useContext(LanguageContext);
  const theme = useTheme();

  const positionMenu = () => {
    const banner = ref.current;
    const li = findParentElement(banner, 'li');
    const { x } = li.getBoundingClientRect();
    banner.style.left = -x + 'px';
  };

  useEffect(() => {
    if (window.matchMedia(`(${theme.viewports.tablet}px)`)) {
      setTimeout(() => {
        positionMenu();
      }, 1000);

      const resizeHandler = debounce(
        () => {
          positionMenu();
        },
        400,
        { maxWait: 1000 }
      );

      window.addEventListener('resize', resizeHandler);
      return () => window.removeEventListener('resize', resizeHandler);
    }
  }, []);

  const lang = ['en', 'fi'].includes(locale) ? 'en' : 'se';
  return (
    <Menu
      ref={ref}
      className={className}
      open={open}
      items={[
        ...links[lang].map((row, i) => {
          return (
            <Row key={i}>
              {row.map((el, i) => {
                let item = null;
                if (el.subItems) {
                  item = (
                    <Subdropdown
                      header={
                        <>
                          <Title>{el.title}</Title>
                          <Description>{el.desc}</Description>
                        </>
                      }
                      items={el.subItems}
                    />
                  );
                } else {
                  item = (
                    <Anchor
                      href={el.link}
                      target={el.openInpage ? '_parent' : '_self'}
                      prefetch={false}
                    >
                      <Title>{el.title}</Title>
                      <Description>{el.desc}</Description>
                    </Anchor>
                  );
                }
                return <Column key={i}>{item}</Column>;
              })}
            </Row>
          );
        }),
        ...(lang === 'se'
          ? [
              <BannerRow key="banner">
                <WellnessBanner />
              </BannerRow>,
            ]
          : []),
      ]}
    />
  );
};

export default MoreMenu;
