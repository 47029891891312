const PagedVideos = {
  typePolicies: {
    PagedVideos: {
      merge(existing, incoming) {
        if (!existing) {
          return incoming;
        } else if (existing?.page >= incoming?.page) {
          return existing;
        } else {
          return {
            ...incoming,
            data: [...existing?.data, ...incoming?.data],
          };
        }
      },
    },
  },
};

export default PagedVideos;
