import { create } from 'zustand';

export enum Currency {
  EUR = 'EUR',
  SEK = 'SEK',
}

type State = {
  currency: Currency;
};

type Actions = {
  setCurrency(currency: Currency): void;
};

export const usePreference = create<State & Actions>(set => {
  let currency = Currency['SEK'];

  return {
    currency,
    setCurrency(currency) {
      set({ currency });
    },
  };
});
