import { Trans } from '@lingui/react';
import Banner from '@organisms/Banner';
import { CONTACT_EMAIL } from '@lib/constants';
import { useEffect, useRef } from 'react';

export const ScaneSwishQRCode = () => {
  const elRef = useRef<HTMLElement>(null);

  useEffect(() => {
    elRef.current.parentElement.parentElement.scrollIntoView({
      behavior: 'smooth',
    });
  }, []);

  return (
    <Banner className="mb-4" appearance="warning" disableIcon>
      <span ref={elRef}>
        <Trans id="payment.scan_swish_qrcode_banner" />
      </span>
    </Banner>
  );
};

export const PaymentFailedBanner = () => {
  const elRef = useRef<HTMLElement>(null);

  useEffect(() => {
    elRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <Banner
      data-testid="AdyenFormModal--Error"
      style={{
        marginBottom: '30px',
        color: '#C20015',
        backgroundColor: '#FFE7E7',
      }}
      disableIcon
    >
      <span ref={elRef}>
        <Trans
          id="metadata.payment.failed"
          message="We’re sorry, an error has occurred with your payment method. Verify that your credit card details are correct and please try again."
          components={[<a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>]}
        />
      </span>
    </Banner>
  );
};
