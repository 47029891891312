import React, { Component } from 'react';
import { Trans } from "@lingui/react";
import styled, { withTheme } from 'styled-components';
import Icon from './../../components/Icon';
import {
  Box,
  Container as GlobalContainer,
  Flex,
} from './../../components/layout/Grid';

const PBanner = styled.div`
  color: ${props => (props.dark ? props.theme.colors.neutrals.black : `white`)};
  font-size: 13px;
  margin: 0;
  padding: 0;
  line-height: 24px;
  font-weight: ${props => (props.bold ? 500 : 200)};
`;

const Container = styled.div`
  background-color: ${props => props.theme.colors.secondary['green-5']};
  width: 100%;
  margin: 0;
  z-index: 8;
  padding: 20px;
  border-radius: 3px;
`;

const GraySectionWrapper = styled.div`
  background-color: #f7f7f7;
`;

class Banner extends Component {
  state = {
    shown: false,
  };

  componentDidMount() {
    this.setState({
      shown: !localStorage.getItem('closed_company_favorite_banner'),
    });
  }

  closeBanner = () => {
    localStorage.setItem('closed_company_favorite_banner', true);
    this.setState({
      shown: false,
    });
  };

  render() {
    const { shown } = this.state;
    const { noBackground } = this.props;
    return shown ? (
      <GlobalContainer
        flexDirection={'column'}
        fullWidth
        pt="40px"
        mb="0"
        as={!noBackground ? GraySectionWrapper : 'section'}
      >
        <GlobalContainer px={[25, 25, 20]} flexDirection={'column'}>
          <Container>
            <Flex alignItems="center" justifyContent="space-between">
              <Box mx="10px">
                <PBanner dark bold>
                  <Flex>
                    <Box mr={15}>
                      <Icon
                        name="ygb-icon-infob"
                        fontSize={'24px'}
                        color={`rgba(0,0,0,0.54)`}
                      />
                    </Box>
                    <Box>
                      <Trans
                        id="banner.companyFavorites"
                        message={`Any content that you save to favorites will be displayed in the
                Overview as recommended content and will be visible to all
                visitors`}
                      />
                    </Box>
                  </Flex>
                </PBanner>
              </Box>
              <Box mx="10px">
                <Icon
                  name="ygb-icon-close"
                  color={`rgba(0,0,0,0.54)`}
                  onClick={this.closeBanner}
                />
              </Box>
            </Flex>
          </Container>
        </GlobalContainer>
      </GlobalContainer>
    ) : null;
  }
}

export default withTheme(Banner);
