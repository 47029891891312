import * as React from 'react';

function SvgPulsingCircle(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={8} cy={8} fill="none" r={5} stroke="#129edc">
        <animate
          attributeName="r"
          from={5}
          to={8}
          dur="1s"
          begin="0s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="opacity"
          from={1}
          to={0.3}
          dur="1s"
          begin="0s"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx={8} cy={8} fill="#129edc" r={5} />
    </svg>
  );
}

export default SvgPulsingCircle;
