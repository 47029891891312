import { ProgramPhase, ProgramWeek } from '@gql/generated';
import gql from 'graphql-tag';
import { genericFieldResolvers } from './GenericProgram';

// TODO: find a way to cache some of these calculations..
// NOTE: most of them dont re-execute often so there is not much impact on performace... unlike in component data enhancers..

export default {
  typePolicies: {
    PhasedProgram: {
      keyFields: ['slug'],
      fields: {
        ...genericFieldResolvers,
        //
        preSurvey(_, { readField, toReference }) {
          const genericProgramRef = toReference({
            __typename: 'GenericProgram',
            slug: readField('slug'),
          });
          return readField('preSurvey', genericProgramRef);
        },
        postSurvey(_, { readField, toReference }) {
          const genericProgramRef = toReference({
            __typename: 'GenericProgram',
            slug: readField('slug'),
          });
          return readField('postSurvey', genericProgramRef);
        },
        weeksCount(_, { readField }) {
          const phases = readField('phases');
          return phases.reduce(
            (total, phaseRef) => total + readField('weeksCount', phaseRef),
            0
          );
        },
        phasesCount(_, { readField }) {
          const phases = readField('phases');
          return phases?.phases?.length || null;
        },
        videosCount(_, { readField }) {
          const phases = readField('phases');
          return phases.reduce(
            (total, phaseRef) => total + readField('videosCount', phaseRef),
            0
          );
        },
        currentVideo(_, { readField }) {
          let currentVideo = null;
          (readField('phases') as ProgramPhase[]).forEach(phaseRef => {
            if (!readField('locked', phaseRef) && !currentVideo) {
              const weeks = readField('weeks', phaseRef);
              const phaseSlug = readField('slug', phaseRef);
              // we find video marked as current
              const videoWeek = weeks.find(week => {
                if (!currentVideo) {
                  const videos = readField('videos', week);
                  currentVideo = videos.find(({ progressIsCurrent }) => {
                    if (progressIsCurrent) {
                      return true;
                    }
                    return false;
                  });

                  // for not wasting anytime we use find so we can stopt it
                  // once the video is found
                  return currentVideo;
                }
                return false;
              });

              // if video is found then we enhance the object with the needed data through out the components
              if (currentVideo) {
                currentVideo = { ...currentVideo };
                const programSlug = readField('slug');
                // we generate url to video, to make the navigation from the header card easy
                currentVideo.path = `programs/${programSlug}/${phaseSlug}/${videoWeek.slug}/${currentVideo.slug}`;
              }
            }
          });

          return currentVideo;
        },
        phases: {
          merge(_, incoming, { readField, cache }) {
            const WeekIndexFragment = gql`
              fragment WeekProgramIndex on ProgramWeek {
                indexInProgram
              }
            `;
            let weekCount = 1;
            (incoming as ProgramPhase[]).forEach(phaseRef => {
              readField('weeks', phaseRef).forEach(week => {
                cache.writeFragment({
                  id: cache.identify(week),
                  fragment: WeekIndexFragment,
                  data: {
                    indexInProgram: weekCount++,
                  },
                });
              });
            });
            return incoming;
          },
        },
      },
    },
    ProgramPhase: {
      fields: {
        weeksCount(_, { readField }) {
          return (readField('weeks') as ProgramWeek[])?.length || null;
        },
        weeksRange(_, { readField, toReference, variables = {} }) {
          const phaseID = readField('id');
          const phases = readField(
            'phases',
            toReference({
              __typename: 'PhasedProgram',
              slug: variables['slug'],
            })
          );
          return (
            phases.reduce(
              (acc, phaseRef) => {
                const weeks = readField('weeks', phaseRef);
                const id = readField('id', phaseRef);
                const { offset } = acc;
                acc.ranges[id] = `${offset} - ${offset + weeks.length - 1}`;
                acc.offset += weeks.length;
                return acc;
              },
              { ranges: {}, offset: 1 }
            ).ranges[phaseID] || null
          );
        },
        videosCount(_, { readField }) {
          const weeks = readField('weeks') as ProgramWeek[];
          return (
            weeks.reduce((acc, week) => {
              const videos = readField('videos', week);
              return acc + videos.length;
            }, 0) || null
          );
        },
        videosDuration(_, { readField }) {
          const weeks = readField('weeks') as ProgramWeek[];
          const totalMinutes = weeks.reduce((acc, week) => {
            const videos = readField('videos', week);
            return (acc += videos.reduce(
              (totalMinutes, { video: videoRef }) => {
                const meta = readField('meta', videoRef);
                return totalMinutes + meta?.minutes;
              },
              0
            ));
          }, 0);

          const hours = Math.trunc(totalMinutes / 60);
          const minutes = totalMinutes % 60;
          return `${hours}H ${minutes}Min`;
        },
      },
    },
  },
};
