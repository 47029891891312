import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import Link from 'next/link';
import { Trans } from '@lingui/react';
import CourseMenu from './CourseMenu';
import PlayMenu from './PlayMenu';
import MoreMenu, { BannerBox, WellnessBanner } from './MoreMenu';
import LanguageContext from '@lib/contexts/languageContext';
import SvgExploreIcon from '@assets/ExploreIcon';
import SvgStoreIcon from '@assets/StoreIcon';
import SvgCameraIcon from '@assets/CameraIcon';
import SvgCalendarIcon from '@assets/CalendarIcon';
import SvgPlayIcon from '@assets/PlayIcon';
import { findParentElement } from '@lib/utils';
import SvgPulsingCircle from '@assets/PulsingCircle';
import SvgSearchIcon from '@assets/SearchIcon';

const PulsingCircle = styled(SvgPulsingCircle)`
  && {
    height: 20px;
    width: 20px;
    margin-right: 0;
  }
`;

const SearchLink = styled(Link)`
  display: none;
  align-items: center;
  padding-left: 8px;

  svg {
    width: 16px;
    margin-top: 2px;
  }

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    display: flex;
  }
`;

const Nav = styled.nav<{ isOpen?: boolean }>`
  flex: 1;
  position: fixed;
  left: 0;
  top: 0;
  height: 100dvh;
  width: 100vw;
  background-color: white;
  transform: ${({ isOpen }) =>
    isOpen ? 'translateX(0)' : 'translateX(-100%)'};
  transition: transform 400ms ease;
  overflow-y: scroll;

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    position: static;
    height: auto;
    width: auto;
    padding-top: 0;
    margin-left: 20px;
    overflow-y: unset;
    transform: none;
    margin-left: 20px;
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0 20px;
  margin: 76px 0 0;

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    width: 100%;
    max-width: 740px;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: unset;
    margin: 0;
  }
`;

const Topic = styled.li`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    flex-direction: row;
    height: 30px;
    padding: 15px 0;
  }
`;

const NavLink = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  color: #242424;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.4;
  text-transform: capitalize;
  border-bottom: 1px solid #e8e8e8;
  padding: 25px 0;
  text-decoration: none;
  cursor: pointer;
  transition: color 200ms ease-in;

  &:first-child:last-child {
    /* padding-right: 15px; */
  }

  .Header--MenuDropdown ~ &:after {
    content: '';
    display: block;
    height: 14px;
    width: 14px;
    margin: 2px 0 0 auto;
    background-image: url(/static/icons/chevron-down-thin.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotateX(0);
    transition: transform 500ms ease-out;
  }

  svg {
    height: 20px;
    width: 20px;
    margin-right: 8px;

    path {
      transition: fill 200ms ease-in;
    }
  }

  .Header--MenuDropdown ~ &.active {
    color: #56b9e5;

    svg path {
      fill: #56b9e5;
    }

    &:after {
      transform: rotateX(180deg);
    }
  }

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    width: auto;
    border: none;
    padding: 0 5px;

    .Header--MenuDropdown ~ &:after {
      height: 6px;
      width: 8px;
      margin-left: 4px;
    }

    svg {
      height: 14px;
      width: 14px;
    }
  }
`;

const WellnessWrapper = styled.div`
  margin-top: 20px;

  ${BannerBox} {
    width: 100%;
    aspect-ratio: 2;
    align-items: center;
    text-align: center;

    p {
      max-width: 320px;
      width: auto;
    }
  }

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    display: none;
  }
`;

const Menu = ({ isOpen, onLinkClick }) => {
  const ref = useRef<HTMLDivElement>(null);
  const lang = useContext(LanguageContext);
  const [open, setOpen] = useState(null);

  const handleMenuClicks = e => {
    const clickedInMenu = ref.current.contains(e.target);
    const clickedAnchor = (e.target instanceof HTMLAnchorElement
      ? e.target
      : findParentElement(e.target, 'a')) as HTMLAnchorElement;
    //
    if (
      !clickedInMenu ||
      (clickedAnchor && clickedAnchor?.dataset?.['link'] !== '#')
    ) {
      setOpen(null);
      onLinkClick();
    }
  };

  const handleClick = e => {
    const _open = open;
    const clickedInMenu = ref.current.contains(e.target);
    if (_open && !clickedInMenu) {
      setOpen(null);
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('click', handleClick);
    }
    //
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [open]);

  const items = useMemo(() => {
    return [
      {
        key: 'play',
        text: (
          <>
            <SvgPlayIcon /> <Trans id="global_menu.play.title" />
          </>
        ),
        link: '#',
        menu: PlayMenu,
      },
      {
        key: 'live',
        text: (
          <>
            <SvgCameraIcon /> <Trans id="global_menu.live.title" />
          </>
        ),
        link: `/${lang}/live`,
      },
      {
        key: 'courses',
        text: (
          <>
            <SvgCalendarIcon /> <Trans id="global_menu.course.title" />
          </>
        ),
        link: '#',
        menu: CourseMenu,
      },
      {
        key: 'store',
        text: (
          <>
            <SvgStoreIcon /> <Trans id="global_menu.store.title" />
          </>
        ),
        link: 'https://store.yogobe.com',
      },
      {
        key: 'more',
        text: (
          <>
            <SvgExploreIcon /> <Trans id="global_menu.more.title" />
          </>
        ),
        link: '#',
        menu: MoreMenu,
      },
      ...(lang === 'se'
        ? [
            {
              key: 'wellness',
              text: (
                <>
                  <PulsingCircle viewBox="0 -2 20 20" />{' '}
                  <Trans id="global_menu.more.friskvårdsbidrag.title" />
                </>
              ),
              link: `/se/friskvardsbidrag`,
            },
          ]
        : []),
    ];
  }, [lang]);

  return (
    <Nav ref={ref} isOpen={isOpen} onClick={handleMenuClicks}>
      <List>
        {items.map(({ menu: Menu, ...item }) => {
          const selected = open === item.key;
          return (
            <Topic key={item.key}>
              {Menu ? (
                <Menu
                  className={['Header--MenuDropdown'].join(' ')}
                  open={selected}
                />
              ) : null}
              <NavLink
                prefetch={false}
                href={item.link}
                {...(item.key === 'store' ? { target: '_blank' } : {})}
                className={selected ? 'active' : ''}
                onClick={e => {
                  if (Menu) {
                    e.preventDefault();
                    setOpen(!selected ? item.key : null);
                  }
                }}
                // need this in global click event to see if we should close menu
                data-link={item.link}
              >
                {item.text}
              </NavLink>
            </Topic>
          );
        })}
        <SearchLink href={`/${lang}/search`}>
          <SvgSearchIcon />
        </SearchLink>
        <WellnessWrapper>
          <WellnessBanner />
        </WellnessWrapper>
      </List>
    </Nav>
  );
};

Menu.toString = Nav.toString;

export default Menu;
