import * as React from 'react';

function SvgShareIcon(props) {
  return (
    <svg width={14} height={15} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.5 10a2.5 2.5 0 00-1.945.945L5.9 8.66a2.23 2.23 0 000-1.32l3.655-2.285A2.5 2.5 0 109 3.5c.002.224.036.446.1.66L5.445 6.445a2.5 2.5 0 100 3.11L9.1 11.84a2.395 2.395 0 00-.1.66 2.5 2.5 0 102.5-2.5zm0-8a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm-8 7.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm8 4.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"
        fill="#119EDD"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgShareIcon;
