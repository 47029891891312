import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { isPast } from 'date-fns/isPast';
import { isFuture } from 'date-fns/isFuture';
import { parseISO } from 'date-fns/parseISO';
import { isValid } from 'date-fns/isValid';
import Icon from '@components/Icon';
import { Box, Container, Flex } from '@components/layout/Grid';

const Wrapper = styled.div<{ bg: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 34px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0;
  background-color: ${props => props.bg || '#ea6ea3'};
  z-index: 12;
  overflow: hidden;
  /*  */
  will-change: height;
  transition: height 500ms ease-out;
`;

export const Banner = styled.p<{ _color: string }>`
  flex: 1;
  color: ${p => p._color};
  font-size: 13px;
  text-align: center;
  line-height: 16px;
  font-weight: 200;
  margin: 10px 0;

  a {
    color: ${p => p._color};
    text-decoration: none;
  }
`;

const Anchor = styled(Link)`
  font-weight: 600;
  cursor: pointer;
`;

const AnimatedBox = styled(Box)`
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transition: transform 200ms ease-in;
    transform: rotate(90deg);
    transform-origin: center;
  }
`;

declare type propsType = {
  className?: string;
  startDate?: string;
  endDate?: string;
  show?: boolean;
  color?: string;
  dark?: boolean;
  bg?: string;
  children: string | React.ReactNode;
  closable?: boolean;
};

export const Link_ = ({ href, target = null, children = '' }) => {
  return (
    <Anchor href={href} target={target}>
      {children}
    </Anchor>
  );
};

const MessageStripe = ({
  className,
  closable = true,
  show = true,
  ...props
}: propsType) => {
  const ref = useRef<HTMLDivElement>();
  const [hideBanner, setHideBanner] = useState(false);

  const handleClose = e => {
    e.preventDefault();
    if (ref.current) {
      ref.current.style.height = '0px';
      setTimeout(() => {
        setHideBanner(true);
      }, 500);
    }
  };

  let shouldShow = show;
  // if props.show is false then if try the start&end Date
  if (show !== false && (props.startDate || props.endDate)) {
    const startDate = parseISO(props.startDate);
    const endDate = parseISO(props.endDate);

    // start date must not be in the future
    shouldShow = !isFuture(startDate);

    if (shouldShow && isValid(endDate)) {
      shouldShow = !isPast(endDate);
    }
  }

  if (shouldShow) {
    return !hideBanner ? (
      <Wrapper
        ref={ref}
        className={`MessageStripe ` + (className ?? '')}
        bg={props.bg}
        data-testid={props['data-testid']}
        data-testtype={props['data-testtype']}
      >
        <Container flexDirection="column">
          <Flex alignItems="center" justifyContent="space-between">
            <Banner _color={props.color}>{props.children}</Banner>
            {closable ? (
              <AnimatedBox>
                <Icon
                  name="ygb-icon-close"
                  color={props.color}
                  onClick={handleClose}
                  clickable
                />
              </AnimatedBox>
            ) : null}
          </Flex>
        </Container>
      </Wrapper>
    ) : null;
  }

  return null;
};

export default MessageStripe;
