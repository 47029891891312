import * as React from 'react';

function SvgStarIcon(props) {
  return (
    <svg width={16} height={14} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 0L5.725 4.61.64 5.345l3.68 3.59L3.45 14 8 11.61 12.55 14l-.87-5.065 3.68-3.585-5.085-.74z"
        fill="#F1C21B"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgStarIcon;
