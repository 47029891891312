import * as React from 'react';

function SvgClockIcon(props) {
  return (
    <svg width={14} height={14} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7 14A7 7 0 117 0a7 7 0 010 14zM7 1a6 6 0 100 12A6 6 0 007 1zm2.295 9L6.5 7.205V2.5h1v4.29L10 9.295 9.295 10z"
        fill="#000"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgClockIcon;
