import React, { useContext } from 'react';
import Styled from 'styled-components';
import Button from '@components/buttons';
import { Trans } from '@lingui/react';
import LanguageContext from '@lib/contexts/languageContext';

const Topics = Styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 15px;
  margin: 20px auto;

  @media(min-width: ${({ theme }) => theme.viewports.laptop}px){
    grid-template-columns: repeat(3, minmax(180px, 1fr));
  }
`;

const Topic = Styled.div`
  flex: 1;
  flex-shrink: -1;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const TopicInput = Styled.input`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 0;
  appearance: initial;
  cursor: pointer;
`;

const TopicLabel = Styled.label`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 10px 20px;
  border: 1px solid transparent;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.neutrals['white-darker']};
  box-sizing: border-box;

  ${Topic}:hover & {
    border: 1px solid ${({ theme }) => theme.colors.primary['blue-4']};
    background-color: ${({ theme }) => theme.colors.neutrals.white};
  }

  img {
    width: 144px;
    height: 144px;
    pointer-events: none;
    object-fit: contain;
  }

  p {
    text-align: center;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.neutrals.black};
    margin: 10px 0 0;
    pointer-events: none;
  }

  p::first-letter {
    text-transform: uppercase;
  }

  ${TopicInput}:checked + & {
    background-color: ${({ theme }) => theme.colors.primary['blue-4']};

    p {
      color: ${({ theme }) => theme.colors.channels.yoga};
    }
  }
`;

export const FloatingButtons = Styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid ${({ theme }) => theme.colors.neutrals['light-gray']};
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.colors.neutrals.white};
  z-index: 1;

  :before {
    content: '';
    margin-left: -20px;
  }
`;

const DISABLED_TOPICS = [
  'manage-my-headache',
  'do-pregnancy-exercises',
  'do-post-natal-exercises',
];
const Step1 = ({ data, selectedTopics, onTopicClick, onContinue, saving }) => {
  const lang = useContext(LanguageContext);

  const handleContinueClick = e => {
    e.preventDefault();
    onContinue();
  };

  return (
    <div data-testid="welcome.step1">
      <Topics data-testid="welcome.step1.items">
        {data?.map(topic => {
          if (
            ['en', 'fi'].includes(lang) &&
            DISABLED_TOPICS.includes(topic.slug)
          ) {
            return null;
          }
          return (
            <Topic
              key={topic.slug}
              data-test-type="welcome.step1.item"
              {...(selectedTopics.includes(topic.slug)
                ? { 'data-testselected': true }
                : {})}
            >
              <TopicInput
                type="checkbox"
                name="topic[]"
                value={topic.slug}
                id={`input-${topic.slug}`}
                onClick={onTopicClick}
                {...(selectedTopics.includes(topic.slug)
                  ? { checked: true }
                  : {})}
              />
              <TopicLabel htmlFor={`input-${topic.slug}`}>
                <img src={topic.imageUrl} />
                <p>{topic.name}</p>
              </TopicLabel>
            </Topic>
          );
        })}
      </Topics>
      <FloatingButtons>
        <Button
          type="button"
          onClick={handleContinueClick}
          appearance="default-pink"
          data-testid="welcome.step1.next_btn"
          loading={saving}
        >
          <Trans id="onboarding.continue.btn_text" />
        </Button>
      </FloatingButtons>
    </div>
  );
};

export default Step1;
