import * as React from 'react';

const SvgCameraIcon = props => (
  <svg viewBox="0 0 14 11" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3 3.19h4.5v1H3v-1Zm0 2.5h2.5v1H3v-1Zm6.5 4.5H1c-.552 0-1-.448-1-1v-8c0-.552.448-1 1-1h8.5c.552 0 1 .448 1 1v2.029l2.71-1.936a.5.5 0 0 1 .79.407v7a.5.5 0 0 1-.79.407L10.5 7.162V9.19c0 .552-.448 1-1 1Zm-8.5-9v8h8.5v-3a.5.5 0 0 1 .79-.407L13 7.718V2.662l-2.71 1.935a.5.5 0 0 1-.79-.407v-3H1Z"
      fill="#000"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgCameraIcon;
