import React, { useState, useEffect, useContext } from 'react';
import styled, { useTheme } from 'styled-components';
import { t } from '@lingui/macro';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { Trans } from '@lingui/react';
import { useRouter } from 'next/router';
import Button from '@components/buttons';
import { snackbarVar } from '@lib/apollo/policies';
import CookieManager from '@lib/CookieManager';
import { VERIFICATION_EMAIL_SENT } from '@lib/constants';
import messageStripe, { Banner } from '@components/MessageStripe';
import { UserContext } from '@lib/contexts/UserProvider';

const MessageStripe = styled(messageStripe)`
  ${Banner} {
    width: 100%;
    padding: 10px 0;
    margin: 0;
    z-index: 8;

    .resendEmail {
      color: white;
      font-weight: 500;

      &[disabled] {
        pointer-events: initial;
      }
    }
  }
`;

const RESEND_CONFIRMATION_EMAIL = gql`
  mutation resendConfirmationEmail($email: String!) {
    resendConfirmationEmail(email: $email) {
      success
    }
  }
`;

const ENABLED_PATHS = [
  { path: '/[lang]/playlists', exact: false },
  { path: '/[lang]/videos', exact: false },
  { path: '/[lang]/me', exact: false },
  { path: '/[lang]/programs/[programId]', exact: false },
  { path: '/[lang]/c/[client]/b/[slug]', exact: false },
];

let timeoutID;

export const EmailValidationEmail = () => {
  const theme = useTheme();
  const [currentUser] = useContext(UserContext);

  let disabled = false;
  if (typeof window !== 'undefined') {
    disabled = !!CookieManager.get({ key: VERIFICATION_EMAIL_SENT });
  }
  const [resendDisabled, setResendDisabled] = useState(disabled);
  const router = useRouter();
  const [resendConfirmationEmail, { loading }] = useMutation(
    RESEND_CONFIRMATION_EMAIL
  );

  useEffect(() => {
    return () => {
      clearTimeout(timeoutID);
    };
  }, []);

  const allowed_on_page = ENABLED_PATHS.some(item => {
    if (item.exact && item.path === router.pathname) {
      return true;
    } else {
      return router.pathname.startsWith(item.path);
    }
  });

  if (!allowed_on_page || !currentUser?.id) {
    return null;
  }

  const email = currentUser.email;
  const handleClick = async () => {
    await resendConfirmationEmail({
      variables: {
        email,
      },
    });
    snackbarVar({
      isSnackBarOpen: true,
      goTo: 'NONE',
      id: 'resend_confirmation_email',
      snackBarMessage: t({
        id: 'account.verification_email_resent',
        values: { email },
      }),
    });
    //
    setResendDisabled(true);
    CookieManager.set(
      { key: VERIFICATION_EMAIL_SENT, value: '1' },
      // 1min
      1 / (24 * 60)
    );
    timeoutID = setTimeout(() => {
      setResendDisabled(false);
      timeoutID = null;
    }, 60000);
  };

  let text = null;

  if (currentUser?.previousEmail && !currentUser?.isEmailActivated) {
    text = (
      <Trans
        id="banner.email_verify_change_settings"
        components={[
          <Button
            key="0"
            className="resendEmail"
            appearance="link"
            onClick={handleClick}
            disabled={resendDisabled}
            loading={loading}
          />,
        ]}
      />
    );
  } else if (!currentUser?.isEmailActivated) {
    text = (
      <Trans
        id="banner.email_verify"
        values={{ email }}
        components={[
          <Button
            key="0"
            className="resendEmail"
            appearance="link"
            onClick={handleClick}
            disabled={resendDisabled}
            loading={loading}
          />,
        ]}
      />
    );
  }

  return (
    <MessageStripe
      color={theme.colors.neutrals.white}
      bg={theme.colors.primary['blue-5']}
      closable={false}
    >
      {text}
    </MessageStripe>
  );
};
