import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { timeZonedFormat } from '@lib/utils';

const Wrapper = styled.div<{ error?: boolean }>`
  width: 100%;
  height: 48px;
  font-size: 16px;
  border: 1px solid
    ${(props) =>
      props.error
        ? props.theme.colors.secondary['red-1']
        : props.theme.colors.interactive['color-18']};
  border-radius: 5px;
  padding: 5px 11px;
  margin-top: 10px;
  background-color: ${(props) => props.theme.colors.neutrals.white};

  span {
    color: #969696;
    margin: 0 5px;
  }

  input:not([value='']) + span {
    color: #363636;
  }
`;

const Input = styled.input<{ isValid?: boolean }>`
  width: 25px;
  height: 100%;
  font-size: 16px;
  color: ${({ isValid, theme }) =>
    isValid === false ? theme.colors.secondary['red-2'] : '#363636'};
  text-align: center;
  border: none;
  padding: 0;
  outline: 0;

  ::placeholder {
    font-size: 14px;
    color: #969696;
  }

  &[name='year'] {
    width: 43px;
    text-align: left;
  }
`;

const MAX_VALUES = {
  1: 31,
  2: 29,
  3: 31,
  4: 30,
  5: 31,
  6: 30,
  7: 31,
  8: 31,
  9: 30,
  10: 31,
  11: 30,
  12: 31,
};

function extractDateParts(date: Date) {
  if (date instanceof Date) {
    const [year, month, day] = timeZonedFormat(date, 'yyyy-MM-dd').split('-');

    return {
      year,
      month,
      day,
    };
  } else {
    return {
      year: null,
      month: null,
      day: null,
    };
  }
}

type Props = {
  className?: string;
  defaultValue?: {
    day: string;
    month: string;
    year: string;
  };
  onChange(value: Date | null): void;
};

export const GuidedDateInput = (props: Props) => {
  const { className, defaultValue, onChange } = props;
  const [values, setValue] = useState({
    year: defaultValue?.year || '',
    month: defaultValue?.month || '',
    day: defaultValue?.day || '',
  });

  useEffect(() => {
    const year = parseInt(values.year),
      month = parseInt(values.month),
      day = parseInt(values.day);

    const date = new Date(year, month - 1, day);

    if (isNaN(date.getTime())) {
      onChange(null);
    } else if (
      date instanceof Date &&
      date.getDate() === day &&
      date.getMonth() + 1 === month &&
      date.getFullYear() === year
    ) {
      onChange(date);
    } else {
      onChange(null);
    }
  }, [values.year, values.month, values.day]);

  const onDayChange = (e) => {
    let value = e.target.value;

    // because we append 0 to 1-9 number we need to allow 3 numbers
    if (!value.match(/^\d{0,3}$/)) {
      e.preventDefault();
      return null;
    }

    if (value) {
      if (value.length >= 2) {
        value = Math.min(value, 31);
      }

      if (e.nativeEvent.inputType !== 'deleteContentBackward') {
        value = String(parseInt(value)).padStart(2, '0');
      }

      if (value === '00') {
        value = '0';
      } else if (parseInt(value) > MAX_VALUES[parseInt(values.month)]) {
        value = MAX_VALUES[parseInt(values.month)];
      }
    }

    setValue({
      ...values,
      day: value,
    });
  };

  const onMonthChange = (e) => {
    let value = e.target.value;

    // because we append 0 to 1-9 number we need to allow 3 numbers
    if (!value.match(/^\d{0,3}$/)) {
      e.preventDefault();
      return null;
    }

    if (value) {
      if (value === '00') {
        value = '0';
      }

      if (value.length >= 2) {
        value = Math.min(value, 12);
        e.target.nextSibling.nextSibling.focus();
      }

      if (
        value !== '0' &&
        e.nativeEvent.inputType !== 'deleteContentBackward'
      ) {
        value = String(parseInt(value)).padStart(2, '0');
      }
    }

    setValue({
      ...values,
      month: value,
    });
  };

  const onYearChange = (e) => {
    let value = e.target.value;

    if (!value.match(/^\d{0,4}$/)) {
      e.preventDefault();
      return null;
    }

    if (value.length === 4) {
      e.target.nextSibling.nextSibling.focus();
    }

    setValue({
      ...values,
      year: value,
    });
  };

  const onKeyDown = (e) => {
    if (e.key === 'Backspace' && e.target.value === '') {
      requestAnimationFrame(() => {
        const input = e.target?.previousSibling?.previousSibling;
        if (input) {
          input.focus();
          input.setSelectionRange(input.value.length, input.value.length);
        }
      });
    }

    if (e.key === 'ArrowRight' && e.target.nextSibling?.nextSibling) {
      e.target.blur();
      requestAnimationFrame(() => {
        e.target.nextSibling.nextSibling.focus();
        const input = e.target.nextSibling.nextSibling;
        input.setSelectionRange(input.value.length, input.value.length);
      });
    }

    if (e.key === 'ArrowLeft' && e.target.previousSibling?.previousSibling) {
      e.target.blur();
      requestAnimationFrame(() => {
        e.target.previousSibling.previousSibling.focus();
        const input = e.target.previousSibling.previousSibling;
        input.setSelectionRange(input.value.length, input.value.length);
      });
    }
  };

  const isDayValid = useMemo(() => {
    const { day, month } = values;
    if (month && day && parseInt(day) > MAX_VALUES[parseInt(month)]) {
      return false;
    }

    return true;
  }, [values.day, values.month]);

  return (
    <Wrapper className={className} data-testid="GuidedDateInput">
      <Input
        placeholder="YYYY"
        name="year"
        onChange={onYearChange}
        value={values.year}
        onKeyDown={onKeyDown}
        data-testid="GuidedDateInput--Year"
      />
      <span>/</span>
      <Input
        placeholder="MM"
        name="month"
        onChange={onMonthChange}
        value={values.month}
        onKeyDown={onKeyDown}
        data-testid="GuidedDateInput--Month"
      />
      <span>/</span>
      <Input
        placeholder="DD"
        name="day"
        onChange={onDayChange}
        value={values.day}
        onKeyDown={onKeyDown}
        isValid={isDayValid}
        data-testid="GuidedDateInput--Day"
      />
    </Wrapper>
  );
};
