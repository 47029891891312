import { normalizeImagePath } from '@lib/helpers';

const PageAssetsPolicy = {
  typePolicies: {
    PageAssets: {
      fields: {
        titleImage: {
          merge(_, incoming) {
            return normalizeImagePath(incoming);
          },
        },
        externalImage: {
          merge(_, incoming) {
            return normalizeImagePath(incoming);
          },
        },
        internalImage: {
          merge(_, incoming) {
            return normalizeImagePath(incoming);
          },
        },
      },
    },
  },
};

export default PageAssetsPolicy;
