import * as React from 'react';

function SvgDocumentIcon(props) {
  return (
    <svg viewBox="0 0 48 56" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M44 0H8a4 4 0 00-4 4v8H0v4h4v10H0v4h4v10H0v4h4v8a4 4 0 004 4h36a4 4 0 004-4V4a4 4 0 00-4-4zm0 52H8v-8h4v-4H8V30h4v-4H8V16h4v-4H8V4h36zM20 12h16v4H20zm0 14h16v4H20zm0 14h16v4H20z"
        fill="#667c91"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgDocumentIcon;
