import React from 'react';
import styled from 'styled-components';

const InputDesign = styled.input`
  width: 100%;
  height: 47px;
  font-size: 16px;
  border: solid 1px #dddddd;
  border-radius: 5px;
  padding: 11px;
  background-color: #ffffff;
  outline: 0;

  &:disabled {
    background-color: #f7f7f7;
  }

  &:focus {
    border: 1px solid #129edc;
  }
`;

type Props = React.InputHTMLAttributes<HTMLInputElement>;

const NewInput = (props: Props) => <InputDesign {...props} />;

export default NewInput;
