import * as React from 'react';

function SvgArrowRightIcon(props) {
  return (
    <svg
      height={20}
      width={20}
      viewBox="0 0 15 12"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 0l-.825.825 4.5 4.575H0v1.2h12.675l-4.5 4.575L9 12l6-6z"
        fill="#243240"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgArrowRightIcon;
