import * as React from 'react';

const SvgFeedbackIcon = props => (
  <svg viewBox="0 0 28 26" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.74 26 14 25l4-7h6a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h9v2H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h20a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4h-4.84l-3.42 6ZM15 5h-2v4H9v2h4v4h2v-4h4V9h-4V5Z"
      fill="#000"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgFeedbackIcon;
