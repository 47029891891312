import * as React from 'react';

const SvgLocationIcon = (props) => (
  <svg width={16} height={16} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm0-1a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm0-2a4.44 4.44 0 0 1 4.5 4.355c0 .944-.315 1.86-.895 2.605L8 14.23 4.425 9A4.235 4.235 0 0 1 3.5 6.355 4.44 4.44 0 0 1 8 2Zm0-1a5.43 5.43 0 0 0-5.5 5.355A5.265 5.265 0 0 0 3.6 9.57L8 16l4.4-6.43a5.265 5.265 0 0 0 1.1-3.215A5.43 5.43 0 0 0 8 1Z"
      fill="#000"
      fillRule="evenodd"
      opacity={0.8}
    />
  </svg>
);

export default SvgLocationIcon;
