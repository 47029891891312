import React from 'react';
import styled from 'styled-components';
import { Box, Flex, ShowInMediumUp } from './../components/layout/Grid';
import BaseSkeleton from './BaseSkeleton';

const Avatar = styled(BaseSkeleton)`
  border-radius: 50%;
  width: 40px;
  height: 40px;
`;

const FirstName = styled(BaseSkeleton)`
  height: 13px;
  width: ${props => (props.width ? `${props.width}px` : '50px')};
  display: inline-block;
  margin-right: 10px;
`;

const UserLoadingSkeleton = () => (
  <Flex>
    <Box as={ShowInMediumUp}>
      <FirstName /> <FirstName width={30} />
    </Box>
    <Box>
      <Avatar />
    </Box>
  </Flex>
);

export default UserLoadingSkeleton;
