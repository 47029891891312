let ReactPixel;
class FBPixel {
  static load() {
    const domain =
      typeof window !== 'undefined' ? window.location.hostname : null;
    // eslint-disable-next-line
    ReactPixel = require('react-facebook-pixel').default;
    const options = {
      autoConfig: true, // set pixel's autoConfig
      debug: domain !== 'yogobe.com', // enable logs
    };
    ReactPixel.init(process.env.FB_PIXEL_ID, {}, options);
  }

  static trackPage(url) {
    FBPixel.track('PageView', false, { url });
  }

  static track(event, custom, data) {
    const domain =
      typeof window !== 'undefined' ? window.location.hostname : null;
    if (domain !== 'yogobe.com') {
      custom
        ? console.log('🕵 trackCustom', event)
        : console.log('🕵 track', event, data);
    } else {
      if (custom) {
        ReactPixel.trackCustom(event);
      } else {
        ReactPixel.track(event, data);
      }
    }
  }
}

export default FBPixel;
