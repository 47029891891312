import * as React from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';

import { cn } from 'lib/tw';
import { Props as ButtonProps } from '@components/ButtonV2';
import Link from 'next/link';

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = ({ className = '', children, ...props }) => (
  <PopoverPrimitive.Trigger
    className={cn(
      'bg-transparent border-0 border-solid text-dark text-base cursor-pointer',
      className
    )}
    {...props}
  >
    {children}
  </PopoverPrimitive.Trigger>
);

const PopoverContent = (
  props: React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> & {
    closeOnFocus?: boolean;
  }
) => {
  const {
    children,
    className,
    align = 'center',
    sideOffset = 4,
    closeOnFocus = true,
    ...otherProps
  } = props;
  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <PopoverPrimitive.Portal>
      <PopoverPrimitive.Content
        ref={ref}
        align={align}
        sideOffset={sideOffset}
        className={cn(
          'flex flex-col z-50 py-2 rounded-md bg-popover border border-neutral-100 px-0 text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
          className
        )}
        onClick={() => {
          if (closeOnFocus && ref.current) {
            ref.current.classList.add('hidden');
          }
        }}
        {...otherProps}
      >
        {children}
      </PopoverPrimitive.Content>
    </PopoverPrimitive.Portal>
  );
};

PopoverContent.displayName = PopoverPrimitive.Content.displayName;

const PopoverItem = (props: ButtonProps) => {
  const { children, className, ...otherProps } = props;

  const attributes = {
    className: cn(
      'w-full px-4 py-2 border-none bg-transparent hover:bg-neutral-100 cursor-pointer text-black text-base text-left',
      className
    ),
    ...otherProps,
  };

  if (typeof otherProps?.href === 'string') {
    return (
      <Link href={otherProps.href} {...attributes}>
        {children}
      </Link>
    );
  }
  return <button {...attributes}>{children}</button>;
};

export { Popover, PopoverTrigger, PopoverContent, PopoverItem };
