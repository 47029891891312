import React from 'react';
import Styled from 'styled-components';
import CheckmarkSVG from '@assets/Checkmark';

const List = Styled.ul`
  font-family: "Libre Franklin";
  color: ${({ theme }) => theme.colors.neutrals.black};
  font-size: 13px;
  font-weight: 500;
  line-height: 32px;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListItem = Styled.li`
  display: flex;
  align-items: center;
  line-height: 1.4;
  padding-left: 8px;
  margin-bottom: 10px;

  svg.checkmark--icon {
    width: 16px;
    height: 16px;
    margin-top: 5px;
    margin-right: 10px;
    fill: #129EDC;
  }
`;

// to extend this component's style simply use the className
const SimpleList = ({ className = '', items = [] }) => {
  return (
    <List className={className} data-testid="simpleList">
      {items.map((item, i) => {
        return (
          <ListItem key={i} data-testid="simpleList__item">
            <CheckmarkSVG className="checkmark--icon" />
            <span>{item}</span>
          </ListItem>
        );
      })}
    </List>
  );
};

export default SimpleList;
