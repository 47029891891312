import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import Icon from '@components/Icon';

const Container = styled.span`
  display: inline-flex;
  align-items: center;
  margin: 0 5px;
`;

const TooltipContent = styled.div<{ bgColor?: string; arrowPosition?: number }>`
  --bg-color: ${({ bgColor }) => bgColor || '#119edd'};
  position: absolute;
  right: 0;
  top: 100%;
  width: 90vw;
  border-radius: 3px;
  padding: 20px;
  background-color: var(--bg-color);
  z-index: 9999;

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    width: 60vw;
  }

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    width: 500px;
  }

  ${props =>
    props.arrowPosition >= 0 &&
    `
      &:after,
      &:before {
        content: '';
        bottom: 100%;
        left: ${props.arrowPosition + 10}px;
        border: solid transparent;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      &:after {
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: var(--bg-color);
        border-width: 5px;
        margin-left: -5px;
      }
      &:before {
        border-color: rgba(194, 225, 245, 0);
        border-bottom-color: var(--bg-color);
        border-width: 6px;
        margin-left: -6px;
      }
  `}
`;

const CloseButon = styled.button`
  position: absolute;
  top: 15px;
  right: 20px;
  border: none;
  background: none;
  cursor: pointer;
`;

const TooltipText = styled.p`
  color: ${({ theme }) => theme.colors.neutrals.white};
`;

const getBestScreenPosition = (tooltipPosition, popupWidth) => {
  const SAFE_OFFSET = 20;

  // we center the popup relative to the icon
  let left = tooltipPosition.left - popupWidth / 2;

  // we calculate offscreen chunk & reduce it from left position
  if (window.innerWidth < left + popupWidth) {
    left -= left + popupWidth - window.innerWidth;
    left -= SAFE_OFFSET;
  }

  // if popup is offscreen on the left
  if (left < 0) {
    left = 50;
  }

  const top = window.pageYOffset + tooltipPosition.top + 25;

  return { left, top };
};

type ChildrenFn = () => React.ReactNode | React.ReactPortal;
declare type Props = {
  className?: string;
  children?: ChildrenFn | React.ReactNode | React.ReactPortal;
  translationId?: string;
  showTooltip?: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  tooltipAnchor?: React.RefObject<HTMLElement>;
  bgColor?: string;
  // anchorSelector: string;
};

const LargeTooltipPopup = ({
  className,
  children,
  showTooltip,
  tooltipAnchor,
  onClose,
  bgColor,
}: Props) => {
  const wrapperRef = useRef<HTMLDivElement>();
  const textRef = useRef<HTMLDivElement>();
  const [open, setOpen] = useState(showTooltip);
  const [arrowPosition, setArrowPosition] = useState(null);

  const closeModal = useCallback(
    e => {
      if (open) {
        onClose(e);
        window.removeEventListener('click', closeModal);
      }
    },
    [open]
  );

  useEffect(() => {
    if (open) {
      window.addEventListener('click', closeModal);
    }

    return () => {
      window.removeEventListener('click', closeModal);
    };
  }, [open]);

  useEffect(() => {
    setOpen(showTooltip);
  }, [showTooltip]);

  const updateTooltipPosition = useCallback(() => {
    if (tooltipAnchor?.current && wrapperRef?.current) {
      const {
        top: anchorTop,
        left: anchorLeft,
      } = tooltipAnchor?.current?.getBoundingClientRect();
      const { width } = wrapperRef?.current?.getBoundingClientRect();

      const tooltipPosition = getBestScreenPosition(
        { left: anchorLeft, top: anchorTop },
        width
      );
      wrapperRef.current.style.top = `${tooltipPosition.top}px`;
      wrapperRef.current.style.left = `${tooltipPosition.left}px`;

      setArrowPosition(Math.abs(tooltipPosition.left - anchorLeft));
    }
  }, [tooltipAnchor?.current, wrapperRef?.current]);

  useEffect(() => {
    if (open) {
      updateTooltipPosition();
    }
  }, [open]);

  if (typeof window === 'undefined') {
    return null;
  }

  const content = typeof children === 'function' ? children() : children;

  return open
    ? ReactDOM.createPortal(
        <TooltipContent
          ref={wrapperRef}
          className={className}
          bgColor={bgColor}
          arrowPosition={arrowPosition}
          onClick={e => e.stopPropagation()}
          data-testid="largeTooltip"
        >
          <CloseButon data-testid="largeTooltip__closeBtn" onClick={onClose}>
            <Icon name="ygb-icon-close" fontSize="12px" color="white" />
          </CloseButon>
          <TooltipText
            ref={textRef}
            data-testid="largeTooltip__text"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </TooltipContent>,
        document.getElementById('__next')
      )
    : null;
};

const LargeTooltip = ({ className = '', icon = null, children }) => {
  const iconRef = useRef();
  const [showTooltip, setShowTooltip] = useState(false);

  let _icon = (
    <Icon
      ref={iconRef}
      name="ygb-icon-infob"
      fontSize="16px"
      color="gray"
      onMouseOver={() => setShowTooltip(true)}
    />
  );

  if (icon) {
    _icon = React.cloneElement(<span>{icon}</span>, {
      ref: iconRef,
      name: 'ygb-icon-infob',
      fontSize: '16px',
      color: 'gray',
      onMouseOver: () => setShowTooltip(true),
    });
  }

  return (
    <Container className={className}>
      {_icon}
      <LargeTooltipPopup
        tooltipAnchor={iconRef}
        showTooltip={showTooltip}
        onClose={() => {
          setShowTooltip(false);
        }}
      >
        {children}
      </LargeTooltipPopup>
    </Container>
  );
};

export default LargeTooltip;
