import FacebookLogin, {
  FacebookLoginClient,
} from '@greatsumini/react-facebook-login';
import { AuthCallback } from '@ecosystems/socialAuth';
import { Icon, SocialButton } from './Element';

type UserInfo = Promise<{
  firstName: string;
  lastName: string;
  picture: string;
}>;

const getFacebookProfile = (): UserInfo => {
  return new Promise((resolve, reject) => {
    const ID = setTimeout(reject, 5000);
    FacebookLoginClient.getProfile(
      profile => {
        clearTimeout(ID);
        const [firstName, lastName] = profile['name']?.split?.(' ');
        const picture = profile['picture']?.data?.url;
        resolve({ firstName, lastName, picture });
      },
      { fields: 'name,email,picture' }
    );
  });
};

type Props = {
  onAuth: AuthCallback;
  tokenOnly?: Boolean;
};
export const FacebookAuthButton = (props: Props) => {
  const onFacebookResponse = async resp => {
    let info = null;
    if (props.tokenOnly) {
      info = await getFacebookProfile();
    }

    props?.onAuth(resp['accessToken'], info);
  };

  return (
    <FacebookLogin
      appId={process.env.FACEBOOK_CLIENT_ID}
      scope="public_profile,email"
      dialogParams={{
        redirect_uri: process.env.DOMAIN,
        state: '',
      }}
      loginOptions={{
        return_scopes: true,
      }}
      onSuccess={onFacebookResponse}
      render={({ onClick }) => (
        <SocialButton onClick={onClick}>
          <Icon src="/static/icons/facebook.svg" /> Facebook
        </SocialButton>
      )}
    />
  );
};
