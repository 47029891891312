import Styled from 'styled-components';
import { Flex } from '@components/layout/Grid';
import {
  CurrencyButton,
  LanguageDropdown,
} from '@organisms/modals/CurrencyLanguageModal';
import { cn } from '@lib/tw';

const FooterWrapper = Styled(Flex)`
  flex-wrap: wrap;
  color: ${({ theme }) => theme.colors.neutrals.gray};
  padding: 20px;
`;

const Text = Styled.p`
  font-size: 13px;
  font-weight: 300;
  line-height: 16px;
  opacity: 0.8;
`;

const RegisterFooter = ({ className = '', ...props }) => {
  return (
    <FooterWrapper
      className={cn(
        'w-full max-w-[540px] gap-2 flex-col sm:flex-row justify-center items-center',
        className
      )}
      {...props}
    >
      <div className="flex-1 flex gap-2 justify-center sm:justify-start">
        <LanguageDropdown shortVersion />
        <CurrencyButton shortVersion />
      </div>
      <div className="text-center">
        <Text>©YOGOBE {new Date().getFullYear()}. All rights reserved.</Text>
      </div>
    </FooterWrapper>
  );
};

export default RegisterFooter;
