import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Flex } from '@components/layout/Grid';
import { Dialog, DialogContent } from 'src/ui/dialog';
export { default as Footer } from './footer';
export { default as Header } from './header';

export const ModalWrapper = styled.div<{ customWidth?: string }>`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  @media (min-width: ${props => `${props.theme.viewports.tablet + 1}px`}) {
    width: ${props => (props.customWidth ? props.customWidth : '600px')};
    margin: 0 auto;
  }
`;

// IMPLEMENTATION HISTORY
// + ISSUE: BODY SCROLL UNDER THE MODAL ON IOS
// - WE TRIED MAKING THE BODY POSITION:FIXED BUT THAT REQUIRES css.top CHANGING
//   WHICH CREATED AN ISSUE WHERE THE PAGE JUMPS AFTER MODAL IS CLOSED
// - LATEST IMPL: WE JUST RESET SCROLL TO POSITION WHEN MODAL WAS OPENED WHEN EVER WE DETECT A SCROLL ON THE BODY
const preventScroll = (scrollX, scrollY) => () => {
  window.scrollTo(scrollX, scrollY);
};

type Props = {
  open: boolean;
  fullScreen?: boolean;
  className?: string;
  handleClose?(): void;
  customWidth?: string;
  children: React.ReactNode;
  zIndex?: number;
};

export const Modal = (props: Props) => {
  useEffect(() => {
    if (props.open) {
      const scrollY = window.scrollY;
      const scrollX = window.scrollX;
      const scrollHandler = preventScroll(scrollX, scrollY);
      document.addEventListener('scroll', scrollHandler);

      return () => {
        document.removeEventListener('scroll', scrollHandler);
      };
    }
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      onOpenChange={open => (!open ? props?.handleClose?.() : null)}
    >
      <DialogContent>
        <ModalWrapper {...props} className="ModalWrapper">
          {props.children}
        </ModalWrapper>
      </DialogContent>
    </Dialog>
  );
};

const StyledBody = styled(Flex)`
  max-height: 80vh;
  overflow-y: scroll;

  @media (max-width: ${props => `${props.theme.viewports.tablet + 1}px`}) {
    flex: 1;
    max-height: 100vh;
  }
`;

export const Body = props => {
  return (
    <StyledBody
      flexDirection="column"
      alignItems="normal"
      px={[15, 25, 50]}
      py={[20, 20, 30]}
      {...props}
    >
      {props.children}
    </StyledBody>
  );
};
