import React, { useContext, useMemo } from 'react';
import { I18nProvider } from '@lingui/react';
import { i18n } from '@lingui/core';
import { en, se, fi, no } from 'make-plural/plurals';
import LanguageContext from './contexts/languageContext';
import { messages as ENmessages } from '../locale/en/messages';
import { messages as SEmessages } from '../locale/se/messages';
import { messages as FImessages } from '../locale/fi/messages';
import { messages as NOmessages } from '../locale/no/messages';

i18n.loadLocaleData({
  en: { plurals: en },
  se: { plurals: se },
  no: { plurals: no },
  fi: { plurals: fi },
});

i18n.load({
  en: ENmessages,
  se: SEmessages,
  fi: FImessages,
  no: NOmessages,
});

// MANAGE loaded locales in state
const I18n = ({ children = null }) => {
  const lang = useContext(LanguageContext);

  useMemo(() => {
    i18n.activate(lang);
  }, [lang]);

  // @ts-ignore
  return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
};

export default I18n;
