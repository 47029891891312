import { normalizeImagePath } from '@lib/helpers';

const Video = {
  typePolicies: {
    Video: {
      merge(existing, incoming, { mergeObjects }) {
        return mergeObjects(existing, incoming);
      },
      fields: {
        thumbnailUrl: {
          merge(_, incoming) {
            return normalizeImagePath(incoming);
          },
        },
        thumbnailUrlMedium: {
          merge(_, incoming) {
            return normalizeImagePath(incoming);
          },
        },
        // IDK if apollo merges nested fields
        // but they do call merge on all fields when the whole parent object is merged with merge function
        assets: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
        cursorAt() {
          // disable cursorAt field
          return 0;
        },
        meta: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
        content: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
      },
    },
  },
};

export default Video;
