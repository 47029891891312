import React, { useContext, useEffect, useState } from 'react';
import Styled from 'styled-components';
import { Flex } from '@components/layout/Grid';
import Input from '@components/form/Input2';
import autocomplete from '@components/form/autocomplete';
import LanguageContext from '@lib/contexts/languageContext';

export const countryToFlag = (isoCode: string): string => {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, char =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
};

const Wrapper = Styled(Flex)`
  position: relative;
  padding-top: 26px;
`;

const CountryAutocomplete = Styled(autocomplete)`
  flex: 1;
  min-width: 100px;
  font-size: 16px;
  border: 1px solid #ddd;
  margin: 0;

  /*
    NOT SURE WHY CASCADING IS NOT WORKING
    STYLE FROM AUTOCOMPLETE IS OVERWRITING THE RULES BELOW
  */
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  
  /* we have country & phone inputs overlap a border */
  margin-right: -1px;

  /* we bump this up the stack so the active state/border is visible */
  &.Mui-focused {
    z-index: 2;
  }
  /* END  */

  .MuiAutocomplete-inputRoot {
    padding-right: 20px !important;
  }

  .MuiAutocomplete-clearIndicatorDirty {
    display: none;
  }
`;

const NumberInput = Styled(Input)`
  height: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  :focus {
    border: 1px solid ${({ theme }) => theme.colors.primary['blue-1']};
  }
`;

const Label = Styled.label`
  flex: 3;
`;

const LabelText = Styled.span`
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 600;
  line-height: 16px;
`;

declare type Props = {
  className?: string;
  required?: boolean;
  countries?: null | Array<Country>;
  codeInputName?: string;
  numberInputName?: string;
  labelText?: React.ReactNode | string;
  onCountryCodeChange?(value: Country): void;
  initialValues?: {
    countryPhoneCode?: flexibleObject;
  };
};

const PhoneInput = ({
  className,
  countries,
  codeInputName,
  numberInputName,
  labelText,
  initialValues = {},
  required = false,
  onCountryCodeChange,
  ...otherProps
}: Props) => {
  const lang = useContext(LanguageContext);
  const [_countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState<Country>(
    initialValues.countryPhoneCode
  );

  useEffect(() => {
    if (countries && _countries !== countries) {
      setCountries(countries);
    }
  }, [countries]);

  useEffect(() => {
    if (!countries) {
      // eslint-disable-next-line
      const countries = require(`@lib/countries/${lang}.json`);
      setCountries(countries);
    }
  }, []);

  useEffect(() => {
    if (countries?.length > 0) {
      setCountryCode(initialValues.countryPhoneCode);
    }
  }, [initialValues.countryPhoneCode]);

  return (
    <Wrapper className={className} data-testid="phoneInput" {...otherProps}>
      <CountryAutocomplete
        data-testid="PhoneInput__PhoneCode"
        name={codeInputName}
        options={_countries}
        value={countryCode || ''}
        getOptionDisabled={(option: Country) => !option.code}
        getOptionLabel={(option: Country) =>
          option.code ? `${countryToFlag(option.code)} +${option.phone}` : ''
        }
        renderOption={(option: Country) =>
          option.code ? (
            <React.Fragment>
              <span>{countryToFlag(option.code)}</span> +{option.phone}
            </React.Fragment>
          ) : (
            option.label
          )
        }
        onChange={(value, e) => {
          if (!value) {
            e.preventDefault();
          } else {
            if (typeof onCountryCodeChange === 'function') {
              onCountryCodeChange(value);
            }
            setCountryCode(value);
          }
        }}
      />
      <Label>
        {/* Using the span to keep the text below functional on click */}
        <LabelText>{labelText}</LabelText>
        <NumberInput
          name={numberInputName}
          required={required}
          data-testid="PhoneInput__PhoneNumber"
        />
      </Label>
    </Wrapper>
  );
};

export default PhoneInput;
