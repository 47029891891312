import React, { useContext } from 'react';
import Styled from 'styled-components';
import { Trans } from '@lingui/react';
import { Body, Footer } from '../base';
import LanguageContext from '@lib/contexts/languageContext';
import Button from '@components/buttons';
import { Flex } from '@components/layout/Grid';
import Icon from '@components/Icon';
import { CONTACT_EMAIL } from '@lib/constants';

const Wrapper = Styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const Title = Styled.p`
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`;

const Description = Styled.p`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-bottom: 50px;

  span:nth-of-type(2){
    margin-top: 20px;
  }
`;

export const SubscriptionPaymentFailedMessage = () => {
  const lang = useContext(LanguageContext);

  return (
    <>
      <Body>
        <Wrapper data-testid="SubscriptionPaymentFailedMessage">
          <Icon
            name="ygb-icon-cancel"
            fontSize="40px"
            color="#FB5A4F"
            style={{
              lineHeight: 1,
              border: '5px solid #FB5A4F',
              borderRadius: '50%',
              padding: '25px',
              margin: '0 auto 20px',
            }}
          />
          <Title>
            <Trans id="metadata.paymentfail.title" />
          </Title>
          <Description>
            <Trans
              id="metadata.payment.failed"
              components={[
                <a key="0" href={`mailto:${CONTACT_EMAIL}`}>
                  {CONTACT_EMAIL}
                </a>,
              ]}
            />
          </Description>
        </Wrapper>
      </Body>
      <Footer>
        <Flex flex={1} my={-10} justifyContent="flex-end">
          <Button
            appearance="default-blue"
            href={`/${lang}/subscription/select`}
          >
            <Trans id="metadata.paymentfail.btn" />
          </Button>
        </Flex>
      </Footer>
    </>
  );
};
