import React from 'react';
import styled from 'styled-components';

const Paragraph = styled.p`
  font-size: ${props => (props.fontSize ? props.fontSize : `13px`)};
  color: ${({ color, theme }) => (color ? color : theme.colors.neutrals.gray)};
  line-height: ${props => (props.lineHeight ? props.lineHeight : null)};
  font-weight: ${({ fontWeight, bold }) => {
    if (bold) {
      return '600';
    } else {
      return fontWeight ? fontWeight : 'normal';
    }
  }};
`;

export const P = props => {
  return <Paragraph {...props} />;
};
