import { normalizeImagePath } from '@lib/helpers';

const ProgramPolicy = {
  typePolicies: {
    ProgramAccess: {
      fields: {
        accessUntil(value) {
          if (!isNaN(parseInt(value))) {
            const date = new Date(parseInt(value) * 1000);
            return date.toISOString();
          }

          return null;
        },
      },
    },
    Program: {
      fields: {
        titleImageUrl: {
          merge(_, incoming) {
            return normalizeImagePath(incoming);
          },
        },
        lede(value, { readField }) {
          return value || readField('shortDescription') || '';
        },
      },
    },
  },
};

export default ProgramPolicy;
