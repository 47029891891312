import Icon from '@components/Icon';
import styled from 'styled-components';

export const Wrapper = styled.div<{ appearance?: Theme }>`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding: 20px;
  background-color: ${({ appearance }) => {
    if (appearance === 'error') {
      return '#F5C6CB';
    } else if (appearance === 'warning') {
      return '#FFE9CB';
    } else if (appearance === 'info') {
      return '#d6d8db';
    } else if (appearance === 'success') {
      return '#DCE7E7';
    } else {
      return '#E5F4FE';
    }
  }};
  cursor: ${p => (typeof p.onClick === 'function' ? 'pointer' : 'auto')};

  @media (max-width: ${props => `${props.theme.viewports.tablet}px`}) {
    flex-direction: column;
  }
`;

export const Text = styled.p<{ noMargin?: boolean }>`
  display: flex;
  align-items: baseline;
  font-size: 14px;
  color: #3c3c3c;
  margin: 0;
  ${props => props.noMargin && 'margin: 0;'};
  ${props =>
    !props.noMargin &&
    `
      @media (max-width: ${props => `${props.theme.viewports.tablet}px`}) {
        margin-bottom: 20px;
      }
    `};
`;

type Theme = 'info' | 'warning' | 'error' | 'success' | 'default';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  disableIcon?: boolean;
  iconColor?: string;
  appearance?: Theme;
  onClick?(): void;
  children?: React.ReactNode;
  noMargin?: boolean;
};

const Banner = (props: Props) => {
  return (
    <Wrapper
      className={props.className}
      style={props.style}
      onClick={props.onClick}
      appearance={props.appearance}
      data-testid={props['data-testid']}
    >
      <Text style={{ color: props?.style?.color }} noMargin={props.noMargin}>
        {!props.disableIcon ? (
          <Icon
            name="ygb-icon-infob"
            fontSize="14px"
            color={props.iconColor || '#96a0a4'}
            mr={'10px'}
          />
        ) : null}
        {props.children}
      </Text>
    </Wrapper>
  );
};

export default Banner;
