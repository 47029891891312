import * as React from 'react';

function SvgCopyIcon(props) {
  return (
    <svg width={22} height={22} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20 6.5V20H6.5V6.5H20zM20 5H6.5A1.5 1.5 0 005 6.5V20a1.5 1.5 0 001.5 1.5H20a1.5 1.5 0 001.5-1.5V6.5A1.5 1.5 0 0020 5zM2 12.5H.5V2A1.5 1.5 0 012 .5h10.5V2H2v10.5z"
        fill="#000"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgCopyIcon;
