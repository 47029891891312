import React, { useContext } from 'react';
import { useTheme } from 'styled-components';
import Router from 'next/router';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import LanguageContext from '@lib/contexts/languageContext';
import { Trans } from '@lingui/react';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core';
import Button from './buttons';
import { Box, Flex } from './layout/Grid';
import { snackbarVar, SnackbarType } from '@lib/apollo/policies';

const GET_SNACKBAR = gql`
  query snackbar {
    snackbar @client
  }
`;

export const CLOSE_SNACKBAR = gql`
  mutation closeSnackbar {
    closeSnackbar @client
  }
`;

const styles = theme => ({
  close: {
    padding: theme.spacing(1 / 2),
  },
});

type Props = {
  classes?: any;
};

const SnackBar = (props: Props) => {
  const theme = useTheme();
  const locale = useContext(LanguageContext);
  const { data } = useQuery<{ snackbar: SnackbarType }>(GET_SNACKBAR);

  const snackbar = data?.snackbar;
  if (!snackbar?.isSnackBarOpen) {
    return null;
  }

  const { vertical = 'bottom', horizontal = 'left' } = snackbar.origin || {};

  const styles = {};
  if (snackbar.goTo !== 'NONE') {
    styles['cursor'] = 'pointer';
  }
  // this is not working properly
  if (snackbar.theme === 'error') {
    styles['backgroundColor'] = theme.colors.secondary['red-1'];
  }

  const handleClose = () => {
    snackbarVar({ isSnackBarOpen: false });
  };

  const duration = snackbar.hasOwnProperty('duration')
    ? snackbar.duration
    : 7000;

  return (
    <Snackbar
      style={styles}
      anchorOrigin={{
        vertical,
        horizontal,
      }}
      open={snackbar.isSnackBarOpen}
      autoHideDuration={duration}
      onClose={handleClose}
      message={
        <Flex
          flexWrap="nowrap"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box mr={15}>{snackbar.snackBarMessage}</Box>
          {snackbar.goTo ? (
            <Box>
              <Button
                appearance="link"
                onClick={() => {
                  if (snackbar.goTo === 'FAVORITES') {
                    Router.push(
                      `/[lang]/me/favorites?id=videos&lang=${locale}`,
                      `/${locale}/me/favorites/videos`
                    );
                  } else if (snackbar.goTo === 'PLAYLISTS') {
                    Router.replace(
                      `/[lang]/me/playlists`,
                      `/${locale}/me/playlists`
                    );
                  } else if (snackbar.goTo === 'PLAYLIST') {
                    Router.replace(
                      `/[lang]/playlists/[id]`,
                      `/${locale}/playlists/${snackbar.id}`
                    );
                  } else if (snackbar.goTo === 'FAQ') {
                    window.open(`/${locale}/faq`, '_blank');
                  }
                }}
              >
                {snackbar.goTo === 'FAQ' && (
                  <Trans id="snackbar.faq" message={`FAQ page`} />
                )}
                {snackbar.goTo === 'FAVORITES' && (
                  <Trans id="snackbar.favorites" message={`Favorites`} />
                )}
                {snackbar.goTo === 'PLAYLISTS' && (
                  <Trans id="snackbar.playlists" message={`Playlists`} />
                )}
                {snackbar.goTo === 'PLAYLIST' && (
                  <Trans id="snackbar.playlist" message={`View Playlist`} />
                )}
              </Button>
            </Box>
          ) : null}
        </Flex>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={props.classes.close}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>,
      ]}
    />
  );
};

export default withStyles(styles)(SnackBar);
