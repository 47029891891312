import styled from 'styled-components';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { Flex } from '@components/layout/Grid';
import { PaymentMethod as PaymentMethodType } from '@gql/generated';

const Item = styled.div<{ expired: boolean }>`
  padding: 15px 20px;
  ${({ expired }) => (expired ? 'opacity: 0.5;' : '')}
`;

const Logo = styled.img`
  height: 10px;
  margin-right: 8px;
`;

const PaymentMethodMask = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
`;

const PaymentMethodName = styled.p`
  font-size: 14px;
  line-height: 22px;
  margin: 0;
`;

const CARDS = {
  maestro: '/static/icons/cards/maestro.svg',
  mc: '/static/icons/cards/mc.svg',
  nocard: '/static/icons/cards/nocard.svg',
  trustly: '/static/icons/cards/trustly.svg',
  visa: '/static/icons/cards/visa.svg',
};

type Props = {
  className?: string;
  item: PaymentMethodType;
  onClick?(): void;
  selected?: boolean;
};

export const PaymentMethod = (props: Props) => {
  const { className = '', item, onClick, selected } = props;
  const {
    name,
    lastFour,
    expiryMonth,
    expiryYear,
    variant,
    provider,
    status,
  } = item;

  const expiration = `${expiryMonth}/${parseInt(expiryYear) - 2000}`;

  let methodMask = null,
    methodExpiration = null;
  if (provider === 'trustly') {
    methodMask = t`payment_method_card.trustly`;
  } else if (provider === 'adyen') {
    methodMask = t`payment_method_card.card_number`;
    const expiration_status =
      status === 'expired' ? (
        <Trans id="payment_method_card.expired" />
      ) : (
        <Trans id="payment_method_card.expires" />
      );
    methodExpiration = (
      <PaymentMethodName>
        {name} - {expiration_status} {expiration}
      </PaymentMethodName>
    );
  }

  return (
    <Item
      className={className}
      onClick={onClick}
      expired={item.status === 'expired'}
      aria-disabled={item.status === 'expired'}
      data-testid="StoredPaymentMethod"
      data-testtype={provider}
      data-testselected={selected}
    >
      <Flex alignItems="center">
        <Logo src={CARDS[String(variant).toLowerCase()]} />
        <PaymentMethodMask>
          <Trans id={methodMask} /> {lastFour}
        </PaymentMethodMask>
      </Flex>
      {methodExpiration}
    </Item>
  );
};
