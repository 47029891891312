import React from 'react';
import { useRouter } from 'next/router';
import SubscriptionPaymentModal from './SubscriptionPaymentModal';
import { StoreAdyenDropinPayload } from '@gql/generated';

declare type Props = {
  // second param can be used to show loading state on loading...
  children(fetchPaymentLink, QueryResult): any;
  amount: number;
  months: number;
  currency: string;
  paymentType: string;
  code: string;
  sourcePage: string;
  onPaymentComplete?(data: StoreAdyenDropinPayload): void;
  handlePaymentCompletion?(data: StoreAdyenDropinPayload): void;
  onClose?(): void;
  // needed only on adyen
  redirectTo?: string;
  redirectToSuccess?: string;
  redirectToFailed?: string;
  //
  enableOneTimeMethods?: boolean;
};

const PaymentHandler = ({
  children,
  redirectTo,
  redirectToSuccess,
  redirectToFailed,
  ...otherProps
}: Props) => {
  const router = useRouter();
  const path = router.asPath;

  const currentPage = process.env.DOMAIN + path;
  return (
    <SubscriptionPaymentModal
      {...otherProps}
      redirectTo={
        redirectTo || redirectToSuccess || redirectToFailed || currentPage
      }
      onPaymentComplete={otherProps.onPaymentComplete}
      handlePaymentCompletion={otherProps.handlePaymentCompletion}
    >
      {(proceedToPayment, response) => children(proceedToPayment, response)}
    </SubscriptionPaymentModal>
  );
};

export default PaymentHandler;
