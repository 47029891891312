import Router from 'next/router';

// TODO: clean up all occurances of 2nd arg of redirect
// next now support only asPath routing
export default function redirect(context, asPath) {
  if (context?.res) {
    // server
    // 303: "See other"
    context.res.writeHead(303, { Location: asPath });
    context.res.end();
  } else {
    // In the browser, we just pretend like this never even happened ;)
    Router.replace(asPath);
  }
}
