import * as React from 'react';

const SvgSearchIconThin = (props) => (
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15 14.295 11.225 10.5a5.5 5.5 0 1 0-.725.725L14.295 15l.705-.705ZM2.5 7a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0Z"
      fill="#000"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgSearchIconThin;
