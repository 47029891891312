import { useContext, useEffect } from 'react';
import { t } from '@lingui/macro';
import AnalyticsManager from './analytics/manager';
import glowCookies from './analytics/GlowCookies';
import { UserContext } from './contexts/UserProvider';

const policyLinkObject = {
  en: '/en/terms-and-conditions',
  se: '/se/villkor',
  fi: '/fi/ehdot',
  no: '/no/vilkar',
};

function initAnalytics(userId) {
  AnalyticsManager().initLibraries({});
  if (window['initializeClarity']) {
    window['initializeClarity'](userId);
  }
}

export const CookieBot = () => {
  const [currentUser] = useContext(UserContext);

  useEffect(() => {
    if (currentUser?.id) {
      initAnalytics(currentUser?.id);
    } else {
      window.addEventListener('CookiebotOnAccept', initAnalytics, false);
    }
  }, []);

  return null;
};

export const GlowCookies = ({ locale }) => {
  const [currentUser] = useContext(UserContext);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window['_ygb_content_granted'] = () => {
        window.gtag('consent', 'update', {
          ad_user_data: 'granted',
          ad_personalization: 'granted',
          ad_storage: 'granted',
          analytics_storage: 'granted',
        });
        initAnalytics(currentUser?.id);
      };
    }

    if (!currentUser?.id) {
      glowCookies.start(locale === 'fi' ? 'en' : locale, {
        style: 1,
        policyLink: policyLinkObject[locale],
        position: 'right',
        hideAfterClick: true,
        bannerHeading: t({ id: 'consent.cookies.title' }),
        bannerDescription: t({ id: 'consent.cookies.desc' }),
        acceptBtnText: t({ id: 'consent.cookies.accept' }),
        rejectBtnText: t({ id: 'consent.cookies.reject' }),
        bannerLinkText: t({ id: 'consent.cookies.read_more' }),
        acceptBtnBackground: '#56b9e5',
        customScript: [
          {
            type: 'custom',
            position: 'body',
            content: 'window._ygb_content_granted()',
          },
          {
            type: 'custom',
            position: 'body',
            content: "document.cookie = 'GlowCookies=1';",
          },
        ],
      });
      glowCookies.render();
    } else {
      initAnalytics(currentUser?.id);
    }
  }, []);

  return null;
};
