import React, { useContext } from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/react';
import LanguageContext from '@lib/contexts/languageContext';
import MenuContent, { Title, Description, Anchor } from './MenuContent';

const Menu = styled(MenuContent)`
  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    && {
      transform: translateX(-25%);
    }
  }
`;

const text = {
  onlinekurser: {
    title: <Trans id="global_menu.course.onlinekurser.title" />,
    desc: <Trans id="global_menu.course.onlinekurser.desc" />,
  },

  courses_trainings: {
    title: <Trans id="global_menu.course.courses_trainings.title" />,
    desc: <Trans id="global_menu.course.courses_trainings.desc" />,
  },

  travel_retreats: {
    title: <Trans id="global_menu.course.travel_retreats.title" />,
    desc: <Trans id="global_menu.course.travel_retreats.desc" />,
  },

  events: {
    title: <Trans id="global_menu.course.events.title" />,
    desc: <Trans id="global_menu.course.events.desc" />,
  },
};

const CourseMenu = ({ className = '', open = false }) => {
  const ref = React.createRef<HTMLDivElement>();
  const lang = useContext(LanguageContext);

  return (
    <Menu
      ref={ref}
      className={className}
      open={open}
      items={[
        <Anchor key="0" href={`/${lang}/courses`} prefetch={false}>
          <Title>{text.onlinekurser.title}</Title>
          <Description>{text.onlinekurser.desc}</Description>
        </Anchor>,
        <Anchor
          key="1"
          href={`${process.env.DOMAIN}/${lang}/marketplace`}
          prefetch={false}
        >
          <Title>{text.courses_trainings.title}</Title>
          <Description>{text.courses_trainings.desc}</Description>
        </Anchor>,
        <Anchor
          key="2"
          href={`${process.env.DOMAIN}/${lang}/marketplace/travel`}
          prefetch={false}
        >
          <Title>{text.travel_retreats.title}</Title>
          <Description>{text.travel_retreats.desc}</Description>
        </Anchor>,
        <Anchor
          key="3"
          href={`${process.env.DOMAIN}/${lang}/marketplace/event`}
          prefetch={false}
        >
          <Title>{text.events.title}</Title>
          <Description>{text.events.desc}</Description>
        </Anchor>,
      ]}
    />
  );
};

export default CourseMenu;
