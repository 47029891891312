import React, { useState } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import Button from '@components/buttons';
import LinksModal from '@organisms/modals/LinksModal';
import Icon from '@components/Icon';
import { Container as container } from '@components/layout/Grid';
import { Placeholder } from './components';
import { hexToRgbA } from '@lib/utils';

export const NavSticky = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  margin: 0;
  background: #ebedf0;
  overflow: hidden;
  box-shadow: 0px 5px 15px ${(props) => props.theme.shadows.light};
  z-index: 9;
`;

const Element = styled.nav`
  border-bottom: 1px solid ${(props) => props.theme.shadows.light};
  background-color: #ebedf0;
  box-shadow: 0 1px 1px 0 ${(props) => props.theme.shadows.light};
`;

const Container = styled(container)`
  position: relative;
`;

const List = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const ListItem = styled.li`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.neutrals['black']};
  padding: 0 14px;

  &.active {
    font-weight: 700;
  }

  svg {
    margin-right: 6px;
  }

  /* add right border to overview item */
  :first-of-type {
    color: ${({ theme }) => theme.colors.primary['blue-1']};
    font-weight: 600;
    padding: 0 15px 0 0;
  }

  :last-of-type {
    padding-right: 0;
  }

  @media (max-width: ${(props) => `${props.theme.viewports.tablet}px`}) {
    :first-of-type {
      border-right: none;
    }

    :nth-last-of-type(2) {
      margin-right: unset;
      margin-left: unset;
    }
  }
`;

const Anchor = styled(Link)`
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: inherit;
  text-transform: capitalize;
  text-decoration: none;
  padding: 15px 0;
  cursor: pointer;

  ${ListItem}:not(:first-of-type) & {
    padding-left: 2px;
    padding-right: 2px;
  }

  ${ListItem}.active & {
    ${(props) => `
        font-weight: 600;
        border-bottom: 2px solid
          ${props.theme.colors.primary['blue-2']};
      `};
  }
`;

const ExpandButton = styled(Button)`
  position: absolute;
  right: 0;
  height: 100%;
  border: none;
  padding: 0 20px;
  background: linear-gradient(
    to right,
    rgba(235, 237, 240, 0.3),
    rgba(235, 237, 240, 0.6) 20%,
    rgba(235, 237, 240, 1) 40%
  );

  @media (min-width: ${(props) => props.theme.viewports.tablet}px) {
    display: none;
  }
`;

declare type LinksVideosType = {
  className?: string;
  showSticky: boolean;
  loading?: boolean;
  terms?: Array<{
    id: string;
    link: string;
    text: React.ReactNode;
    active: boolean;
  }>;
  height?: number;
};

const LinksVideos = (props: LinksVideosType) => {
  const [showMenuModal, setShowMenuModal] = useState(false);
  const ListWrapper = props.showSticky ? NavSticky : Element;

  return (
    <React.Fragment>
      {props.showSticky ? <Placeholder height={50} /> : null}
      <ListWrapper className={props.className}>
        <Container fullWidth px={[15, 20, 25]}>
          <Container
            flexDirection={'column'}
            sx={{
              overflow: 'hidden',
            }}
          >
            <List>
              {props?.terms?.map((item) => {
                return (
                  <ListItem
                    key={item.id}
                    className={item.active ? 'active' : ''}
                  >
                    <Anchor href={item.link}>{item.text}</Anchor>
                  </ListItem>
                );
              })}
            </List>
            <ExpandButton
              appearance="outline"
              onClick={() => setShowMenuModal(true)}
            >
              <Icon name="ygb-icon-chevron-right" />
            </ExpandButton>
          </Container>
        </Container>
      </ListWrapper>
      <LinksModal
        open={showMenuModal}
        onClose={() => setShowMenuModal(false)}
        links={props?.terms?.map((item) => ({
          href: item.link,
          text: item.text,
        }))}
      />
    </React.Fragment>
  );
};

export default LinksVideos;
