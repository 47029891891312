import * as React from 'react';

function SvgHeartOutlineIcon(props) {
  return (
    <svg viewBox="0 0 14 13" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.225 1c.735 0 1.44.295 1.955.82a2.85 2.85 0 010 4L7 11.065 1.82 5.82a2.85 2.85 0 010-4 2.74 2.74 0 013.91 0L7 3.12l1.265-1.29a2.72 2.72 0 011.96-.83zm0-1a3.735 3.735 0 00-2.67 1.12L7 1.68l-.555-.56a3.745 3.745 0 00-5.34 0 3.86 3.86 0 000 5.41L7 12.5l5.895-5.97a3.86 3.86 0 000-5.41A3.745 3.745 0 0010.225 0z"
        fill="#000"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgHeartOutlineIcon;
