import styled from 'styled-components';
import { Flex as F } from 'reflexbox/styled-components';
export { Box, Flex } from 'reflexbox/styled-components';

export const ShowInLargeDown = styled.div`
  &&& {
    display: none;
    @media (max-width: ${props => `${props.theme.viewports.laptop}px`}) {
      display: block;
    }
  }
`;

export const ShowInMediumDown = styled.div`
  &&& {
    display: none;
    @media (max-width: ${props => `${props.theme.viewports.tablet}px`}) {
      display: block;
    }
  }
`;

export const ShowInMediumOnly = styled.div`
  &&& {
    display: none;
    @media (min-width: ${props =>
        `${props.theme.viewports.mobile}px`}) and (max-width: ${props =>
        `${props.theme.viewports.tablet}px`}) {
      display: block;
    }
  }
`;

export const ShowInSmallOnly = styled.div`
  &&& {
    display: none;
    @media (max-width: ${props => `${props.theme.viewports.mobile}px`}) {
      display: block;
    }
  }
`;

export const ShowInXSmallOnly = styled.div`
  &&& {
    display: none;
    @media (max-width: ${props => `${props.theme.viewports['x-small']}px`}) {
      display: block;
    }
  }
`;

export const ShowInXMediumUp = styled.div`
  &&& {
    display: none;
    @media (min-width: ${props =>
        `${props.theme.viewports['x-small'] + 1}px`}) {
      display: block;
    }
  }
`;

export const ShowInMediumUp = styled.div`
  &&& {
    display: none;
    @media (min-width: ${props => `${props.theme.viewports.mobile + 1}px`}) {
      display: block;
    }
  }
`;

export const ShowInLargeUp = styled.div<{ display?: string }>`
  &&& {
    display: none;
    @media (min-width: ${props => `${props.theme.viewports.tablet + 1}px`}) {
      display: ${props => (props.display ? props.display : 'block')};
      ${props => (props.display === 'flex' ? `flex-direction: row;` : null)};
    }
  }
`;

export const ShowInXLargeDown = styled.div`
  &&& {
    display: none;
    @media (max-width: ${props => `${props.theme.viewports.laptop}px`}) {
      display: block;
    }
  }
`;
export const ShowInXLargeUp = styled.div`
  &&& {
    display: none;
    @media (min-width: ${props => `${props.theme.viewports.laptop + 1}px`}) {
      display: block;
    }
  }
`;

export const Container = styled(F)<{ fullWidth?: boolean }>`
  width: 100%;
  max-width: ${props => (!props.fullWidth ? '1280px' : null)};
  margin-left: auto;
  margin-right: auto;
`;
