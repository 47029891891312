import * as React from 'react';

const SvgThinCheckmark = (props) => (
  <svg viewBox="0 0 18 12" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7 9.9 1.7 4.6l-1.1 1 5.3 5.3L7 12 17.6 1.4 16.5.3z"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgThinCheckmark;
