import * as React from 'react';

const SvgPlayCircleFilled = props => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <path
        d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0ZM8 14.5v-9l6 4.5-6 4.5Z"
        id="play_circle_filled_svg__a"
      />
    </defs>
    <use
      fill="#243240"
      fillRule="nonzero"
      xlinkHref="#play_circle_filled_svg__a"
    />
  </svg>
);

export default SvgPlayCircleFilled;
