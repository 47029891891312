import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html {
    scroll-behavior: smooth
  }
  
  body * {
    font-family: 'Libre Franklin', Helvetica, Arial, sans-serif;
    box-sizing: border-box;
  }

  html body {
    margin: 0;
    padding: 0;
    /* need the specificity here */
    background-color: white;
  }

  input[type='text'],
  input,
  button,
  textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  
  a {
    color: #56b9e5;
    text-decoration: none;
  }

  #__next {
    max-width: 100vw;
  }

  .MuiSvgIcon-root {
    display: none;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #d1d9df;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .phase-videos-indicator {
    position: absolute;
    top: 0;
    left: 6px;
    height: 100%;
    width: 5px;
    z-index: 0;
  }

  /*  */

  .embeddedText.embeddedText {
    font-size: 16px;
    
    h1, h2, h3, h4, h5, h6 {
      color: ${({ theme }) => theme.colors.neutrals['black']};
      font-weight: 600;
      padding: 0;
    }

    h1 {
      font-size: 24px;
      margin: 30px 0 20px 0;
    }

    h2 {
      font-size: 20px;
      margin: 15px 0 10px 0;
    }

    h3 {
      font-size: 18px;
    }

    h4 {
      font-size: 16px;
    }

    h5 {
      font-size: 14px;
    }

    h6 {
      font-size: 12px;
    }

    ul, ol {
      font-size: inherit;
      padding-left: 20px;

      li {
        font-size: inherit;
      }
    }
  }
  
  //  utilities
  .line-through {
    text-decoration: line-through;
  }

  .color-inherit {
    color: inherit;
  }
`;
