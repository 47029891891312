import React, { useContext } from 'react';
import Styled from 'styled-components';
import Link from 'next/link';
import { Trans } from '@lingui/react';
import messageStripe from '@components/MessageStripe';
import LanguageContext from '@lib/contexts/languageContext';
import { UserContext } from '@lib/contexts/UserProvider';
import Icon from '@components/Icon';
import useFlags from '@lib/hooks/useFlags';

const MessageStripe = Styled(messageStripe)`
  min-height: auto;
  z-index: 10;

  
  p a {
    display: flex;
    align-items: center;
  }
  
  i {
    margin-right: 4px;
    opacity: .6;
  }
`;

const TrialAvailableBanner = () => {
  const lang = useContext(LanguageContext);
  const [flags] = useFlags();
  const [currentUser] = useContext(UserContext);

  if (
    !(
      currentUser?.trial &&
      !currentUser?.trial?.subscriptionPlanType &&
      flags?.enable_manual_trial
    ) ||
    currentUser?.isPremium
  ) {
    return null;
  }

  return (
    <MessageStripe bg="#D3E3EB" color="#242424" closable={false}>
      <Link
        href={`/${lang}/get-started/trial`}
        style={{ color: 'inherit' }}
        target="_blank"
        rel="noreferrer"
      >
        <Icon name="ygb-icon-infob" fontSize="14px" />
        <Trans
          id="trial_2023.trial_available_banner"
          values={{ trialDays: currentUser?.trial?.periodDays }}
        />
      </Link>
    </MessageStripe>
  );
};

export default TrialAvailableBanner;
