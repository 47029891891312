import React, { useContext } from 'react';
import Styled from 'styled-components';
import { sv, nb, fi, enGB } from 'date-fns/locale';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { format } from 'date-fns/format';
import Icon from '@components/Icon';
import { Body, Footer } from '../base';
import LanguageContext from '@lib/contexts/languageContext';
import { Button } from '@components/ButtonV2';

const localeMap = {
  en: enGB,
  se: sv,
  no: nb,
  fi,
};

const Wrapper = Styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const Title = Styled.p`
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`;

const Text = Styled.p`
  font-size: 16px;
  margin: 15px 0 0;
`;

export const SUBSCRIPTIONS_LABELS = {
  1: t`settings_subscriptions.monthly`,
  3: t`settings_subscriptions.quarterly`,
  12: t`settings_subscriptions.annually`,
};

/*
  THIS IS AN ABSTRACTION THAT IS USED BY TWO COMPONENTS (PaymentConfirmationModal & AdyenDropinModalHook)
*/

const PaymentConfirmationMessage = ({
  currency,
  months,
  paymentAmount,
  recurringPaymentAmount,
  recurringDate,
  onClose,
}) => {
  const lang = useContext(LanguageContext);

  let nextPaymentDate;
  try {
    nextPaymentDate = format(new Date(recurringDate), 'd MMMM yyyy', {
      locale: localeMap[lang],
    });
  } catch (ex) {
    // prevent crashing
    return null;
  }

  return (
    <>
      <Body pb={40}>
        <Wrapper data-testid="PaymentConfirmationMessage">
          <Icon
            name="ygb-icon-checkmark"
            fontSize="24px"
            color="white"
            style={{
              lineHeight: 1,
              backgroundColor: '#93BDBB',
              borderRadius: '50%',
              padding: '50px',
              margin: '0 auto 20px',
            }}
          />
          <Title>
            <Trans id="metadata.payment.success.title" />
          </Title>
          <Text>
            <Trans
              id="paymentconfirmation_subscription.modal.body"
              values={{
                paymentAmount: `${paymentAmount} ${currency}`,
                subscriptionType: <Trans id={SUBSCRIPTIONS_LABELS[months]} />,
                recurringPaymentAmount: `${recurringPaymentAmount} ${currency}`,
                recurringDate: nextPaymentDate,
              }}
            />
          </Text>
        </Wrapper>
      </Body>
      <Footer>
        <Button
          appearance="default-blue"
          href={`/${lang}/me/settings/receipts`}
          data-testid="PaymentConfirmationMessageButton"
          link
        >
          <Trans id="metadata.payment.success.receipt_button" />
        </Button>
        <Button appearance="default-blue" onClick={onClose}>
          <Trans id="metadata.payment.success.continue_button" />
        </Button>
      </Footer>
    </>
  );
};

export default PaymentConfirmationMessage;
