import * as React from 'react';

const SvgBrokenFileIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    style={{
      enableBackground: 'new 0 0 512 512',
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path d="M444.875 109.792 338.208 3.125c-2-2-4.708-3.125-7.542-3.125h-224C83.146 0 64 19.135 64 42.667v202.667a10.66 10.66 0 0 0 3.125 7.542l42.667 42.667c4.167 4.167 10.917 4.167 15.083 0l56.458-56.458 77.792 77.792a10.634 10.634 0 0 0 7.542 3.125c2.729 0 5.458-1.042 7.542-3.125l57.813-57.813 56.729 37.813a10.637 10.637 0 0 0 13.458-1.333l42.667-42.667c2-2 3.125-4.708 3.125-7.542v-128a10.664 10.664 0 0 0-3.126-7.543zM341.333 36.417l70.25 70.25h-48.917c-11.771 0-21.333-9.573-21.333-21.333V36.417zm85.334 204.5-33.354 33.354-56.729-37.813c-4.25-2.833-9.854-2.25-13.458 1.333l-56.458 56.458-77.792-77.792a10.634 10.634 0 0 0-7.542-3.125 10.636 10.636 0 0 0-7.542 3.125l-56.458 56.458-32-32V42.667c0-11.76 9.563-21.333 21.333-21.333H320v64C320 108.865 339.146 128 362.667 128h64v112.917z" />
    <path d="M441.417 299.479c-3.958-1.677-8.583-.74-11.625 2.313l-36.479 36.479-56.729-37.813a10.65 10.65 0 0 0-13.458 1.333l-56.458 56.458-77.792-77.792c-4.167-4.167-10.917-4.167-15.083 0l-56.458 56.458L82.21 301.79c-3.063-3.052-7.646-3.979-11.625-2.313a10.672 10.672 0 0 0-6.583 9.854v160C64 492.865 83.146 512 106.667 512h298.667c23.52 0 42.666-19.135 42.666-42.667v-160c0-4.312-2.604-8.208-6.583-9.854zm-14.75 169.854c0 11.76-9.563 21.333-21.333 21.333H106.667c-11.771 0-21.333-9.573-21.333-21.333v-134.25l24.458 24.458c4.167 4.167 10.917 4.167 15.083 0l56.458-56.458 77.792 77.792c4.167 4.167 10.917 4.167 15.083 0l57.813-57.813 56.729 37.813a10.637 10.637 0 0 0 13.458-1.333l24.458-24.458v134.249z" />
  </svg>
);

export default SvgBrokenFileIcon;
