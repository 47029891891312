import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/react';
import Icon from '@components/Icon';
import Button from '@components/buttons';
import Spinner from '@components/Spinner';
import { Body as body, Footer } from '../base';

const Body = styled(body)``;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const Wait = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* to hide modal close button - no easier/faster way  */
  padding-top: 80px;
  margin-top: -80px;
  background-color: white;
  /*  */

  z-index: 9;

  p {
    max-width: 480px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.4;
    margin: 50px auto;
  }
`;

const Title = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`;

const Text = styled.p`
  font-size: 16px;
  margin: 15px 0 0;
`;

type Props = {
  title: React.ReactNode;
  body: React.ReactNode;
  onContinue(): void;
} & (
  | {
      enableWait: boolean;
      onRefreshOrder(): Promise<any>;
      refreshRate: number;
      maxWait: number;
    }
  | {
      enableWait?: never;
      onRefreshOrder?(): never;
      refreshRate?: never;
      maxWait?: never;
    }
);

const PaymentPendingMessage = (props: Props) => {
  const { title, body, onContinue, enableWait, refreshRate, maxWait } = props;
  const [showLoader, setShowLoader] = useState<boolean>(enableWait);

  useEffect(() => {
    if (enableWait) {
      let id = setInterval(() => {
        props?.onRefreshOrder?.();
      }, refreshRate);

      setTimeout(() => {
        if (id) {
          setShowLoader(false);
          clearInterval(id);
          id = null;
        }
      }, maxWait);

      return () => {
        if (id) {
          clearInterval(id);
          id = null;
        }
      };
    }
  }, []);

  return (
    <>
      <Body pb={40}>
        {showLoader ? (
          <Wait>
            <Spinner size={34} spinnerColor="#bbb" />
            <p>
              <Trans id="paymentconfirmation.pending_modal.wait_text" />
            </p>
          </Wait>
        ) : (
          <Wrapper data-testid="PaymentPendingMessage">
            <Icon
              name="ygb-icon-checkmark"
              fontSize="24px"
              color="white"
              style={{
                lineHeight: 1,
                backgroundColor: '#FFA351',
                borderRadius: '50%',
                padding: '50px',
                margin: '0 auto 20px',
              }}
            />
            <Title>{title}</Title>
            <Text>{body}</Text>
          </Wrapper>
        )}
      </Body>
      {showLoader ? null : (
        <Footer>
          <Button onClick={onContinue}>
            <Trans id="paymentconfirmation.pending_modal.close_btn" />
          </Button>
        </Footer>
      )}
    </>
  );
};

export default PaymentPendingMessage;
