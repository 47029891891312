import * as React from 'react';

function SvgLightingIcon(props) {
  return (
    <svg
      width={14}
      height={16}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="M13.26 5.733c-.132-.266-.332-.4-.599-.4H7.4l.6-4.6c.066-.266-.133-.6-.4-.666a.635.635 0 00-.8.2l-6.66 8c-.133.2-.2.466-.066.733a.7.7 0 00.6.333h5.261l-.6 4.6c-.066.267.134.6.4.667.067.067.2.067.267.067.2 0 .4-.067.532-.267l6.661-8c.133-.2.2-.467.067-.667zM6.934 11.8l.4-3.067c0-.2-.067-.4-.133-.533a.756.756 0 00-.533-.2H2.07l4.263-5.133L6 5.933c0 .2.066.4.133.534a.72.72 0 00.466.2h4.596L6.933 11.8z"
          id="lighting-icon_svg__a"
        />
      </defs>
      <use
        fill="#213E3D"
        fillRule="nonzero"
        xlinkHref="#lighting-icon_svg__a"
        transform="translate(.333 .667)"
      />
    </svg>
  );
}

export default SvgLightingIcon;
