import React from 'react';
import styled from 'styled-components';
import theme from '@lib/theme';

export const TypographyCheckbox = styled.span`
  font-size: 13px;
  color: ${props => (props.labelColor ? props.labelColor : 'black')};

  & img {
    width: 15px;
    margin-right: 5px;
  }
`;

const Element = styled.label`
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  color: ${props => (props.color ? props.color : theme.colors.neutrals.black)};
`;

const Label = ({ className = '', children, ...otherProps }) => {
  return (
    <Element className={className} {...otherProps}>
      {children}
    </Element>
  );
};

Label.toString = Element.toString;
export default Label;
