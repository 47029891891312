import * as React from 'react';

const SvgSurveyIcon = (props) => (
  <svg viewBox="0 0 44 56" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10 32h16v4H10v-4Zm0-10h24v4H10v-4Zm0 20h10v4H10v-4ZM40 6h-6V4a4 4 0 0 0-4-4H14a4 4 0 0 0-4 4v2H4a4 4 0 0 0-4 4v42a4 4 0 0 0 4 4h36a4 4 0 0 0 4-4V10a4 4 0 0 0-4-4ZM14 4h16v8H14V4Zm26 48H4V10h6v6h24v-6h6v42Z"
      fill="#667C91"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgSurveyIcon;
