import * as React from 'react';
import styled from 'styled-components';
import Button from '@components/buttons';
import { Modal, Body, Footer, Header } from './base';
import { Trans } from '@lingui/react';
import { ArrowRight } from 'lucide-react';

const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
`;

const StyledBody = styled(Body)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 3px;
  padding: 30px 24px;
  margin: 0 auto;
  background-color: white;
  overflow: hidden;
`;

const StyledFooter = styled(Footer)`
  /* min-width: 345px; */
  border-top: none;
  margin: 0 auto;
  padding: 0 30px 24px;
`;

const MenuList = styled.ul`
  list-style: none;
  padding: 14px 4px;
  padding-bottom: 24px !important; /* overwrite footer dynamic offset padding */
  margin: 0;
`;

const Item = styled.li`
  display: block;
  font-size: 11px;
  color: ${(props) => props.theme.colors.neutrals.gray};
  font-weight: 600;
  border-bottom: 1px solid lightgrey;

  & svg:first-child:not(:last-of-type) {
    display: none;
  }

  & + & {
    margin-top: 2em;
  }
`;

const Anchor = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  color: ${(props) => props.theme.colors.neutrals.black};
  font-weight: normal;
  text-align: left;
`;

const CancelButton = styled(Button)`
  width: 100%;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: normal;
`;

declare type LinkType = {
  href: string;
  as?: string;
  text: string | React.ReactNode;
};

declare type LinksModalProps = {
  open?: boolean;
  links: Array<LinkType>;
  onClose(): void;
};

const LinksModal = ({ open, links, onClose }: LinksModalProps) => {
  return (
    <StyledModal fullScreen={false} open={open} handleClose={onClose}>
      <Header handleClose={onClose} />
      <StyledBody>
        <MenuList>
          {links.map((item, i) => {
            return (
              <Item key={i}>
                <Anchor
                  className="relative"
                  href={item.href}
                  appearance="appearance"
                  style={{ paddingBottom: '9px' }}
                  onClick={onClose}
                >
                  {item.text}
                  <ArrowRight className="w-5 h-full absolute right-0 stroke-neutral-500" />
                </Anchor>
              </Item>
            );
          })}
        </MenuList>
      </StyledBody>
      <StyledFooter>
        <CancelButton onClick={onClose}>
          <Trans id="metadata.close" />
        </CancelButton>
      </StyledFooter>
    </StyledModal>
  );
};

export default LinksModal;
