import * as React from 'react';

const SvgGiftIcon = (props) => (
  <svg
    width={24}
    height={24}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <path
        d="M3 5v6h5L7 7l4 1V3H5c-1.1 0-2 .9-2 2Zm5 8H3v6c0 1.1.9 2 2 2h6v-5l-4 1 1-4Zm9 4-4-1v5h6c1.1 0 2-.9 2-2v-6h-5l1 4Zm2-14h-6v5l4-1-1 4h5V5c0-1.1-.9-2-2-2Z"
        id="gift-icon_svg__a"
      />
    </defs>
    <g fill="none" fillRule="evenodd" opacity={0.6}>
      <path d="M0 0h24v24H0z" />
      <mask id="gift-icon_svg__b" fill="#fff">
        <use xlinkHref="#gift-icon_svg__a" />
      </mask>
      <use fill="#000" xlinkHref="#gift-icon_svg__a" />
      <g mask="url(#gift-icon_svg__b)" fill="#000">
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);

export default SvgGiftIcon;
