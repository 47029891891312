import React, { useState, useEffect, useRef, useContext } from 'react';
import { useRouter } from 'next/router';
import Styled from 'styled-components';
import once from 'lodash/once';
import RegisterFormTemplate from '@ecosystems/registerForm.pageTemplate';
import SubscriptionStep from '@ecosystems/register/subscription/SubscriptionStep';
import RegisterPaymentStep from '@ecosystems/register/subscription/PaymentStep';
import Spinner from '@components/Spinner';
import MetaTags from '@components/MetaTags';
import { UserContext } from '@lib/contexts/UserProvider';
import {
  PricingSubscriptionFieldsFragment,
  StoreAdyenDropinPayload,
  useActivateFullAccessCodeMutation,
  useActivateOneTimeCodeMutation,
  useFullAvailableSubscriptionPlansQuery,
} from '@gql/generated';
import AnalyticsManager from '@lib/analytics/manager';
import LanguageContext from '@lib/contexts/languageContext';

const Wrapper = Styled(RegisterFormTemplate.Wrapper)``;

const RightContainer = Styled(RegisterFormTemplate.RightContainer)`
  background-color: ${({ theme }) => theme.colors.neutrals.white};
  overflow-x: hidden;
`;

const RightBox = Styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 20px;
  margin: 0 auto;

  @media(max-width: ${({ theme }) => theme.viewports.laptop}px){
    max-width: 600px;
  }
  
  @media(min-width: ${({ theme }) => theme.viewports.laptop}px){
    padding: 20px 30px;
  }
`;

const LeftContainer = Styled(RegisterFormTemplate.LeftContainer)`
  width: 100%;
  max-width: 500px;
  padding: 10vh 20px 0;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.neutrals.white};
  /* must be kept for the line in subscription form */
  z-index: 1;

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    position: sticky;
    top: 0;
    min-height: 100vh;
    max-width: 100vh;
    background-image: url('/static/photo-1562088287-bde35a1ea917-2.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
`;

const LeftBox = Styled.div`
  max-width: 600px;
  width: 100%;
  box-sizing: content-box;

  @media(min-width: ${({ theme }) => theme.viewports.laptop}px){
    text-align: center;
    justify-self: unset;
    margin-right: 10%;
  }
`;

export const getSubscriptionAmountToPay = (
  selectedSubscription,
  next = false
) => {
  if (next && !selectedSubscription?.reccurringPromotion) {
    return selectedSubscription?.amount;
  }
  return selectedSubscription?.discountedAmount
    ? selectedSubscription.discountedAmount
    : selectedSubscription?.amount;
};

type SubscriptionOptonName = 'subscriptionPlan' | 'fullAccess' | 'oneTimeCode';
const selectSubscriptionOptionBox = (
  optionName: SubscriptionOptonName,
  value
) => {
  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      const input = document.querySelector(
        `input[name=${optionName}][value='${value}']`
      ) as HTMLInputElement;
      // we click to trigger the chain of actions (event, setting state...)
      input?.click();
      input?.scrollIntoView();
    });
  });
};

const RegisterSubscription = () => {
  const _rightContainer = useRef<HTMLDivElement>();
  const router = useRouter();
  const lang = useContext(LanguageContext);
  const [loadingSubscriptions, setLoadingSubscriptions] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<
    PricingSubscriptionFieldsFragment
  >(null);
  const [selectedAccess, setSelectedAccess] = useState(null);
  const [selectedOneTimeCode, setSelectedOneTimeCode] = useState(null);
  const [currentUser, { error: userError, loading: userLoading }] = useContext(
    UserContext
  );

  // const [sendTrialStartedEvent] = useEventProxyMutation();
  const subscriptionsResponse = useFullAvailableSubscriptionPlansQuery({
    variables: {
      code:
        (router?.query?.code as string) ||
        (router?.query?.campaignCode as string) ||
        null,
    },
  });

  const redirectToOnBoarding = () => {
    if (router.query.to) {
      return router.push(router.query.to as string);
    } else {
      if (!currentUser.onboardingCompleted) {
        return router.push(`/${lang}/welcome/step1`);
      } else {
        return router.push(`/${lang}/me/dashboard`);
      }
    }
  };

  const [
    activateAccessCode,
    { loading: accessActivationLoading, data: accessActivationData },
  ] = useActivateFullAccessCodeMutation({
    onCompleted(data) {
      if (data?.activateFullAccessCode?.success) {
        // redirect to step1 of on-boarding
        redirectToOnBoarding();
      }
    },
  });

  const [
    activateOneTimeCode,
    { loading: oneTimeCodeActivationLoading, data: oneTimeCodeActivationData },
  ] = useActivateOneTimeCodeMutation({
    onCompleted(data) {
      if (data?.activateOneTimeCode?.success) {
        // redirect to step1 of on-boarding
        redirectToOnBoarding();
      }
    },
  });

  const { step } = router.query;

  const autoSelectSubscriptions = useRef(
    // we need this to only run first time to preselect paymentType in the url
    // otherwise it runs many times and causes many boxes to be selected..
    once(subscriptions => {
      /*
        initially i auto assigned router.query.paymentType to selectedPlan
        but that creates issues where some ui reflect selected plan even if router.query.paymentType is not valid...
        so solution is to check and make sure router.query.paymentType is valid then select the subscription manually..
      */
      const selectedPlanObject = subscriptions?.find?.(subscription => {
        const globalOffer = ['global', 'extra_global'].includes(
          subscription.promotionKind
        );
        if (
          subscription.subscriptionName === router.query.paymentType ||
          (globalOffer &&
            String(router.query.paymentType).toLowerCase() ===
              String(subscription.promotionName).toLowerCase())
        ) {
          return true;
        } else if (
          !globalOffer &&
          subscription.promotionName === router.query.paymentType
        ) {
          return true;
        }
        return false;
      });
      if (selectedPlanObject) {
        const plan =
          selectedPlanObject?.promotionName ??
          selectedPlanObject?.subscriptionName;

        // we need this on payment page
        setSelectedPlan(plan);
        // this on select page
        selectSubscriptionOptionBox('subscriptionPlan', plan);
      } else if (step === 'payment') {
        router.replace({
          pathname: `/${lang}/subscription/select`,
        });
      }
    })
  );

  // we use checked to only run this once when data is available..
  useEffect(() => {
    if (
      subscriptionsResponse?.data?.availableSubscriptionPlans?.subscriptions
    ) {
      autoSelectSubscriptions.current(
        subscriptionsResponse?.data?.availableSubscriptionPlans?.subscriptions
      );
    }
  }, [subscriptionsResponse?.data?.availableSubscriptionPlans]);

  const { data, loading } = subscriptionsResponse;

  const activateAppliedCode = () => {
    if (selectedAccess) {
      activateAccessCode({
        variables: {
          code: subscriptionsResponse?.data?.availableSubscriptionPlans?.code,
          promotionName: selectedAccess,
        },
      });
    } else if (selectedOneTimeCode) {
      activateOneTimeCode({
        variables: {
          code: subscriptionsResponse?.data?.availableSubscriptionPlans?.code,
          promotionName: selectedOneTimeCode,
        },
      });
    }
  };

  const validateCode = async value => {
    // will need to track value
    setSelectedPlan(null);
    setSelectedOneTimeCode(null);
    setSelectedAccess(null);
    setLoadingSubscriptions(true);
    await subscriptionsResponse.refetch({
      code: value,
    });
    setLoadingSubscriptions(false);
  };

  useEffect(() => {
    if (
      subscriptionsResponse?.data?.availableSubscriptionPlans?.fullAccesses
        ?.length > 0
    ) {
      // we manually select fullAccess element, scroll to it and click on it
      const subscription =
        subscriptionsResponse?.data?.availableSubscriptionPlans
          ?.fullAccesses[0];
      setSelectedAccess(subscription.promotionName);
      selectSubscriptionOptionBox('fullAccess', subscription.promotionName);
    } else if (
      subscriptionsResponse?.data?.availableSubscriptionPlans?.oneTimeCodes
        ?.length > 0
    ) {
      // we manually select oneTimeCode element, scroll to it and click on it
      const subscription =
        subscriptionsResponse?.data?.availableSubscriptionPlans
          ?.oneTimeCodes[0];
      setSelectedOneTimeCode(subscription.promotionName);
      selectSubscriptionOptionBox('oneTimeCode', subscription.promotionName);
    } else {
      // we manually select subscriptionPlan element, scroll to it and click on it
      let subscription = subscriptionsResponse?.data?.availableSubscriptionPlans?.subscriptions?.find(
        subscription => {
          if (
            subscription.promotionName &&
            !['global', 'extra_global'].includes(subscription.promotionKind)
          ) {
            setSelectedPlan(subscription);
            selectSubscriptionOptionBox(
              'subscriptionPlan',
              subscription.subscriptionName
            );
          }
        }
      );

      // if (!subscription) {
      //   subscriptionsResponse?.data?.availableSubscriptionPlans?.subscriptions?.find(
      //     subscription => {
      //       if (subscription.promotionKind === 'extra_global') {
      //         setSelectedPlan(subscription);
      //         selectSubscriptionOptionBox(
      //           'subscriptionPlan',
      //           subscription.promotionName
      //         );
      //       }
      //     }
      //   );
      // }
    }
    // using subscriptionsResponse?.data?.availableSubscriptionPlans?.code because subscriptionsResponse ref changes...
  }, [subscriptionsResponse?.data?.availableSubscriptionPlans?.code]);

  // currentUser is undefined initially
  // currentUser is set to null by UserProvider when currentUser query is empty..
  if (
    typeof window !== 'undefined' &&
    !userLoading &&
    (userError || currentUser === null)
  ) {
    router.replace('/[lang]/register/account', `/${lang}/register/account`);
    return null;
  }

  const selectedPlanObject = data?.availableSubscriptionPlans?.subscriptions?.find?.(
    subscription => {
      const key = ['global', 'extra_global'].includes(
        subscription.promotionKind
      )
        ? 'promotionName'
        : 'subscriptionName';

      return (
        subscription.promotionKind === selectedPlan?.promotionKind &&
        subscription[key] === selectedPlan?.[key]
      );
    }
  );

  const handleNext = () => {
    if (
      subscriptionsResponse?.data?.availableSubscriptionPlans?.code &&
      (selectedAccess || selectedOneTimeCode)
    ) {
      activateAppliedCode();
    } else if (selectedPlan) {
      const globalPromo = ['global', 'extra_global'].includes(
        selectedPlanObject.promotionKind
      );
      // eslint-disable-next-line
      const { step, lang, ...otherParams } = router.query;

      const query = {
        ...otherParams,
        paymentType: globalPromo
          ? selectedPlan.promotionName
          : selectedPlan.subscriptionName,
      };

      if (selectedPlanObject.promotionName && !globalPromo) {
        // only keep track of voucher-code if the selected subscription is relavent to the code
        query['code'] = data?.availableSubscriptionPlans?.code;
      }

      // its better if we keep the component ignorant of the next page and handle navigation here
      router.push(
        {
          pathname: `/${lang}/subscription/payment`,
          query,
        },
        {
          pathname: `/${lang}/subscription/payment`,
          query,
        },
        { shallow: true }
      );
    }
  };

  const handlePaymentCompletion = (resp: StoreAdyenDropinPayload) => {
    if (router.query.hasOwnProperty('to')) {
      const comingFromProgram = String(router.query.to).match(
        /\/(en|se|no|fi)\/programs\/(?<slug>[\w-]+)\/get-started/
      );
      if (comingFromProgram) {
        AnalyticsManager().sendUpgradedFromProgramPageEvent({
          title: comingFromProgram.groups?.slug,
          subscription: router.query.paymentType as string,
        });
      }
      setTimeout(() => {
        router.push({
          pathname: router.query.to as string,
        });
      }, 5000);
    } else {
      router.push(`/${lang}/me/dashboard?order_id=${resp?.orderId}`);
    }
  };

  return (
    <>
      <MetaTags
        title="Yoga och träning online för hållbar hälsa"
        description="Yoga & träna på nätet med några av Sveriges främsta instruktörer. Yogavideos online vart du vill, när du vill."
      />
      <Wrapper>
        <LeftContainer>
          <LeftBox>
            <RegisterFormTemplate.Logo whiteOnDesktop />
          </LeftBox>
        </LeftContainer>
        <RightContainer>
          <RightBox ref={_rightContainer}>
            {currentUser ? (
              <>
                {step === 'select' ? (
                  <SubscriptionStep
                    loading={loading || loadingSubscriptions}
                    data={data?.availableSubscriptionPlans}
                    selectedPlan={selectedPlan}
                    setSelectedPlan={setSelectedPlan}
                    selectedAccess={selectedAccess}
                    setSelectedAccess={setSelectedAccess}
                    selectedOneTimeCode={selectedOneTimeCode}
                    setSelectedOneTimeCode={setSelectedOneTimeCode}
                    validateCode={validateCode}
                    onNext={handleNext}
                    nextLoading={
                      accessActivationLoading || oneTimeCodeActivationLoading
                    }
                    accessActivationErrors={
                      accessActivationData?.activateFullAccessCode?.errors
                    }
                    oneTimeCodeActivationErrors={
                      oneTimeCodeActivationData?.activateOneTimeCode?.errors
                    }
                    showUnexpectedError={
                      (accessActivationData?.activateFullAccessCode?.success ===
                        false &&
                        accessActivationData?.activateFullAccessCode?.errors
                          ?.length === 0) ||
                      (oneTimeCodeActivationData?.activateOneTimeCode
                        ?.success === false &&
                        oneTimeCodeActivationData?.activateOneTimeCode?.errors
                          ?.length === 0)
                    }
                  />
                ) : null}
                {step === 'payment' ? (
                  <RegisterPaymentStep
                    loading={loading}
                    code={
                      // => component will display this code
                      // only send code if selected plan is a special promotion and not global promotion
                      // if user selects a global or basic subscription no need to send this
                      selectedPlanObject?.promotionName &&
                      !['global', 'extra_global'].includes(
                        selectedPlanObject.promotionKind
                      )
                        ? data?.availableSubscriptionPlans?.code
                        : null
                    }
                    selectedSubscription={selectedPlanObject}
                    onPaymentCompleted={handlePaymentCompletion}
                  />
                ) : null}
              </>
            ) : (
              <Spinner />
            )}
          </RightBox>
          <RegisterFormTemplate.Footer />
        </RightContainer>
      </Wrapper>
    </>
  );
};

RegisterSubscription.forceAuth = true;

RegisterSubscription.getPageConfig = () => {
  return {
    layout: 'none',
  };
};

export default RegisterSubscription;
