import { useState } from 'react';
import { AddPaymentMethodModal } from '@organisms/modals/payment/AddPaymentMethodModal';

type Props = {
  redirectTo: string;
  zIndex?: number;
  onPaymentMethodAdded(): void;
};

export const useAddPaymentMethodModal = (
  props: Props
): [
  React.ReactNode,
  {
    openModal(): void;
    closeModal(): void;
  }
] => {
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const modal = open ? (
    <AddPaymentMethodModal
      onPaymentMethodAdded={props.onPaymentMethodAdded}
      onClose={onClose}
      zIndex={props.zIndex}
    />
  ) : null;

  return [modal, { openModal, closeModal }];
};
