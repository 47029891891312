import * as React from 'react';

function SvgArrowDownCurved(props) {
  return (
    <svg width={90} height={108} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M1 0c.03 22.374 12.227 36.384 36.59 42.03C61.954 47.676 75.403 63.7 77.94 90.1"
          stroke="#979797"
          strokeDasharray={5}
        />
        <path
          d="M77.939 99.734l-7.5-7.5 1.05-1.05 6.45 6.45 6.45-6.45 1.05 1.05z"
          fill="#000"
        />
      </g>
    </svg>
  );
}

export default SvgArrowDownCurved;
