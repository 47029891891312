import { makeVar, InMemoryCache } from '@apollo/client';
import { parse } from 'date-fns/parse';
import WeeklyProgramPolicy from '@lib/apollo/policies/programs/WeeklyProgram';
import PhaseProgramPolicy from '@lib/apollo/policies/programs/PhaseProgram';
import GenericProgramPolicy from '@lib/apollo/policies/programs/GenericProgram';
import CurrentUserPolicy from '@lib/apollo/policies/CurrentUser';
import LiveEventAccessPolicy from '@lib/apollo/policies/LiveEventAccess';
import {
  liveEventPolicy,
  searchliveEventPolicy,
} from '@lib/apollo/policies/LiveEvent';
import PlaylistPolicy from './Playlist';
import PlaylistAssetsPolicy from './PlaylistAssets';
import TermPolicy from './Term';
import PagedVideosPolicy from './PagedVideos';
import ProgramPolicy from './programs/Program';
import PageAssetsPolicy from './PageAssets';
import BlogPostPolicy from './BlogPost';
import InstructorAssetsPolicy from './InstructorAssets';
import VideoPolicy from './Video';
import ProgramVideoPolicy from './ProgramVideo';
import CoursePolicy from './Course';
import AvailableSubscriptionPlan from './AvailableSubscriptionPlan';
import VideoAssetsPolicy from './VideoAssets';
import DocumentPolicy from './Document';
import BundlePolicy from './Bundle';
import PaymentMethodPolicy from './PaymentMethod';
import OrderPolicy from './Order';
import InstructorPolicy from './Instructor';
import PagedWellnessPackage from './PagedWellnessPackage';
import WellnessPackage from './WellnessPackage';
import PaymentPolicy from './Payment';

type SnackBarOrigin = {
  vertical: 'top' | 'bottom';
  horizontal: 'right' | 'left';
};

export type SnackbarType = {
  isSnackBarOpen: boolean;
  id?: string;
  snackBarMessage?: string;
  goTo?: 'NONE' | 'PLAYLIST' | 'PLAYLISTS' | 'FAQ' | 'FAVORITES';
  theme?: string;
  origin?: SnackBarOrigin;
  duration?: number | null;
};

export const snackbarVar = makeVar<SnackbarType>({
  isSnackBarOpen: false,
});

export const videoSettingsModalOpenVar = makeVar(false);

export const videoSettingsVar = makeVar({
  excludeTerms: [],
  contentLanguages: ['en', 'se', 'fi', 'no'],
  videoLevels: [],
});

export const resetReactiveVars = (): void => {
  // reset video settings
  videoSettingsVar({
    excludeTerms: [],
    contentLanguages: ['en', 'se', 'fi', 'no'],
    videoLevels: [],
  });

  // reset video setting modal
  videoSettingsModalOpenVar(false);

  // reset snackbar
  snackbarVar({
    isSnackBarOpen: false,
  });
};

/*
  ****** BIG NOTE ******
  if @client field resolves to undefined or resolver doesn't exist in field-policies
  --> THE WHOLE QUERY WILL FAIL
  *********************
*/
let cache: InMemoryCache;
export const getMemoryCache = (
  { initialState = {} } = {},
  ssr = false
): InMemoryCache => {
  if (cache && !ssr) {
    return cache;
  }

  cache = new InMemoryCache({
    typePolicies: {
      Taxonomy: { keyFields: false },
      TaxonomyItem: {
        fields: {
          terms: {
            merge(_, incoming) {
              return incoming;
            },
          },
        },
      },
      Trial: { keyFields: false },
      MoodTracker: { keyFields: false },
      ProgramSurvey: { keyFields: false },
      PhasedProgram: { keyFields: ['slug'] },
      LiveEvent: { keyFields: ['slug'] },
      User: {
        fields: {
          accessUntil: {
            merge(_, accessUntil) {
              if (accessUntil) {
                return parse(accessUntil, 'yyyy-MM-dd', new Date());
              }
              return null;
            },
          },
        },
      },
      PromotionCode: {
        fields: {
          usageLimit(value) {
            return value === null ? 1 : value;
          },
        },
      },
      ...InstructorAssetsPolicy.typePolicies,
      ...PagedVideosPolicy.typePolicies,
      ...TermPolicy.typePolicies,
      ...PageAssetsPolicy.typePolicies,
      ...VideoAssetsPolicy.typePolicies,
      ...ProgramPolicy.typePolicies,
      ...GenericProgramPolicy.typePolicies,
      ...CoursePolicy.typePolicies,
      ...PhaseProgramPolicy.typePolicies,
      ...WeeklyProgramPolicy.typePolicies,
      ...ProgramVideoPolicy.typePolicies,
      ...CurrentUserPolicy.typePolicies,
      ...VideoPolicy.typePolicies,
      ...liveEventPolicy.typePolicies,
      ...searchliveEventPolicy.typePolicies,
      ...LiveEventAccessPolicy.typePolicies,
      ...PlaylistPolicy.typePolicies,
      ...PlaylistAssetsPolicy.typePolicies,
      ...BlogPostPolicy.typePolicies,
      ...AvailableSubscriptionPlan.typePolicies,
      ...PaymentMethodPolicy.typePolicies,
      ...DocumentPolicy.typePolicies,
      ...OrderPolicy.typePolicies,
      ...BundlePolicy.typePolicies,
      ...InstructorPolicy.typePolicies,
      ...PagedWellnessPackage.typePolicies,
      ...PaymentPolicy.typePolicies,
      ...WellnessPackage.typePolicies,
      Query: {
        fields: {
          myBundles: {
            keyArgs: [],
            merge(existing, coming) {
              return {
                ...coming,
                page: coming.page,
                data: [...(existing?.data || []), ...coming.data],
              };
            },
          },
          videos: {
            keyArgs: [
              'taxonomies',
              'perPage',
              'genre',
              'query',
              'useLocale',
              'contentLanguages',
              'excludeTerms',
              'videoLevels',
              'orderBy',
            ],
          },
          latestViewedVideos: {
            keyArgs: [],
          },
          getFavoriteVideos: {
            keyArgs: ['id'],
          },
          getFavoritePlaylists: {
            keyArgs: ['id'],
          },
          snackbar() {
            return snackbarVar();
          },
          isVideoSettingsModalOpen() {
            return videoSettingsModalOpenVar();
          },
          videoSettings() {
            return videoSettingsVar();
          },
          liveEvents: {
            keyArgs: ['search', 'languages', 'instructor', 'sortBy', 'kind'],
            merge(existing, coming) {
              return {
                ...coming,
                page: coming.page,
                data: [...(existing?.data || []), ...coming.data],
              };
            },
          },
          bundles: {
            keyArgs: ['query', 'category'],
            merge(existing, coming) {
              return {
                ...coming,
                page: coming.page,
                data: [...(existing?.data || []), ...coming.data],
              };
            },
          },
          specificSearch: {
            keyArgs: ['query', 'languages', 'modelType'],
            merge(existing, coming) {
              return Object.keys(coming).reduce((merged, key) => {
                if (
                  coming[key]?.__typename === 'SearchResults' &&
                  coming?.[key]?.page > existing?.[key]?.page
                ) {
                  merged[key] = {
                    ...coming[key],
                    results: [
                      ...(existing?.[key]?.results ?? []),
                      ...(coming?.[key]?.results ?? []),
                    ],
                  };
                } else {
                  merged[key] = coming[key];
                }
                return merged;
              }, {});
            },
          },
          genericPrograms: {
            keyArgs: [
              'query',
              'languages',
              'instructor',
              'sort',
              'theme',
              'category',
              'tags',
            ],
            merge(existing, coming) {
              const exitingPrograms = existing?.data || [];
              const newPrograms = coming?.data || [];
              return {
                ...(coming || {}),
                data: [...exitingPrograms, ...newPrograms],
              };
            },
          },
        },
      },
    },
  }).restore(initialState);

  return cache;
};
