import { normalizeImagePath } from '@lib/helpers';

const BlogPostPolicy = {
  typePolicies: {
    BlogPost: {
      fields: {
        featuredImage: {
          merge(_, incoming) {
            return normalizeImagePath(incoming);
          },
        },
      },
    },
  },
};

export default BlogPostPolicy;
