const ProgramVideo = {
  typePolicies: {
    ProgramVideo: {
      // for some reason TS doesnt like just false
      keyFields: false as const,
      fields: {
        title(title, { readField }) {
          const customTitle = readField('customTitle');
          return customTitle || title;
        },
      },
    },
  },
};

export default ProgramVideo;
