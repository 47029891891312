import { PaymentStatusKind } from '@gql/generated';

const fields = {
  status: {
    read(_, { readField }) {
      const state = readField('state');

      const completed = [
        PaymentStatusKind['Processed'],
        PaymentStatusKind['Authorized'],
        PaymentStatusKind['Done'],
      ].includes(state);

      if (completed) {
        return 'completed';
      } else if (
        [PaymentStatusKind['Pending'], PaymentStatusKind['Draft']].includes(
          state
        )
      ) {
        return 'pending';
      } else if (
        [PaymentStatusKind['Rejected'], PaymentStatusKind['Timeout']].includes(
          state
        )
      ) {
        return 'failed';
      }
    },
  },
};

const OrderPolicy = {
  typePolicies: {
    BundlePayment: {
      fields,
    },
    GenericProgramPayment: {
      fields,
    },
    LiveEventPayment: {
      fields,
    },
    SubscriptionPayment: {
      fields,
    },
    AddPayment: {
      fields,
    },
    WellnessPackagePayment: {
      fields,
    },
  },
};

export default OrderPolicy;
