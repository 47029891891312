import * as React from 'react';

function SvgCheckmark(props) {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M13.599 0L5.6 7.999 2.4 4.801 0 7.2l5.6 5.6L16 2.399 13.601 0z" />
    </svg>
  );
}

export default SvgCheckmark;
