import styled from 'styled-components';
import { useRouter } from 'next/router';
import { Trans } from '@lingui/react';
import { t } from '@lingui/macro';
import capitalize from 'lodash/capitalize';
import { Box } from '@components/layout/Grid';
import { Button } from '@components/ButtonV2';
import Icon from '@components/Icon';
import { OrderQuery } from '@gql/generated';
import { Modal } from '../base';

const Head = styled.div`
  position: relative;
  min-height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16% 20px;
  background-image: linear-gradient(
      to right,
      rgba(204, 32, 106, 0.7),
      rgba(204, 32, 106, 0.7)
    ),
    url('/static/courses_listing_page.jpg');
  background-size: cover;
  background-position: center center;
`;

const Title = styled.p`
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 20px;
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 23px;
  p {
    margin: 0 0 20px;
  }
`;

const List = styled.ul`
  list-style: none;
  padding: 0;

  li {
    margin-bottom: 10px;

    :before {
      content: '';
      display: inline-block;
      height: 5px;
      width: 5px;
      border-radius: 50%;
      border: 4px solid rgb(223, 245, 255);
      margin-right: 5px;
      margin-top: 4px;
      background-color: rgb(86, 185, 229);
      box-sizing: content-box;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  button {
    justify-content: center;
  }
`;

type Props = {
  order: OrderQuery['order'];
  loading: boolean;
  onClose(): void;
};

export const EpassiFailedPaymentModal = (props: Props) => {
  const router = useRouter();
  const { lang } = router.query;
  const { order } = props;

  if (
    order?.__typename === 'BundlePayment' ||
    order?.__typename === 'GenericProgramPayment' ||
    order?.__typename === 'LiveEventPayment' ||
    order?.__typename === 'SubscriptionPayment' ||
    order?.__typename === 'WellnessPackagePayment'
  ) {
    const provider = capitalize(order.paymentProvider);
    const LIST = [
      <Trans
        id="epassi.payment_failed_modal.bullet_1"
        values={{ provider }}
        components={[<b key="0"></b>]}
      />,
      <Trans id="epassi.payment_failed_modal.bullet_2" />,
      <Trans id="epassi.payment_failed_modal.bullet_3" />,
      <Trans
        id="epassi.payment_failed_modal.bullet_4"
        components={[
          <a
            key="0"
            href="https://services.epassi.se/ui/merchant-search?searchParams=yogobe&siteType=SPORT"
            target="_blank"
          ></a>,
        ]}
      />,
    ];

    const onClose = () => {
      if (order.__typename === 'LiveEventPayment') {
        router.replace(`/${lang}/live/${order.live.slug}`);
      } else if (order.__typename === 'GenericProgramPayment') {
        router.replace(
          `/${lang}/${order.program.category}/${order.program.slug}`
        );
      }
      //
      props.onClose();
    };

    return (
      <Modal open={true} handleClose={onClose}>
        <Head>
          <Icon
            name="ygb-icon-yogobe-logo2019_1"
            fontSize="26px"
            color="#fff"
          />
        </Head>
        <Box px={50} py={30}>
          <Title>
            <Trans id="epassi.payment_failed_modal.title" />
          </Title>
          <Description
            dangerouslySetInnerHTML={{
              __html: t({
                id: 'epassi.payment_failed_modal.desc',
                values: {
                  amount: order?.amountCents / 100 + ' ' + order.amountCurrency,
                  provider,
                },
              }),
            }}
          />
          <List>
            {LIST?.map((option, i) => {
              return <li key={i}>{option}</li>;
            })}
          </List>
          <Actions>
            <Button type="button" appearance="blue" onClick={props.onClose}>
              <Trans id="epassi.payment_failed_modal.back_to_page_btn" />
            </Button>
          </Actions>
        </Box>
      </Modal>
    );
  }

  return null;
};
