const merge = (existing, incoming) => {
  const mergedList = [...(existing?.data ?? []), ...(incoming?.data ?? [])];

  // better than doing multiple iterations with filter & some...
  const data = Object.values(
    mergedList.reduce((acc, item) => {
      acc[item.__ref] = item;
      return acc;
    }, {})
  );

  return {
    ...(incoming ?? {}),
    data,
  };
};

const InstructorPolicy = {
  typePolicies: {
    InstructorPage: {
      fields: {
        favoritedVideos: {
          keyFields: false as const,
          keyArgs: [],
          merge,
        },
        videos: {
          keyFields: false as const,
          keyArgs: [],
          merge,
        },
        playlists: {
          keyFields: false as const,
          keyArgs: [],
          merge,
        },
        liveEvents: {
          keyFields: false as const,
          keyArgs: [],
          merge,
        },
        programs: {
          keyFields: false as const,
          keyArgs: [],
          merge,
        },
        courses: {
          keyFields: false as const,
          keyArgs: [],
          merge,
        },
        blogPosts: {
          keyFields: false as const,
          keyArgs: [],
          merge,
        },
      },
    },
    Instructor: {
      keyFields: false as const,
      keyArgs: [],
      merge(existing, incoming) {
        return { ...(existing || {}), ...(incoming || {}) };
      },
      fields: {
        webpage(value) {
          try {
            // if not valid it raises
            new URL(value);
            return value;
          } catch (ex) {
            return null;
          }
        },
      },
    },
  },
};

export default InstructorPolicy;
