export const sendTrialStartedTrigger = ({ trialDays = 14 }) => {
  window.dataLayer.push({ event: 'trial-started', trialDays });
};

type SubscriptionEventParams = {
  currency: string;
  amount: number;
  payment_type?: string;
  content_type?: string;
} & UserData;

type UserData = {
  user_id: string;
};

// SUBSCRIPTION TRIGGERS
const monthlySubscription = (data: SubscriptionEventParams) => {
  window.dataLayer.push({
    event: 'monthly-subscription',
    ...data,
  });
};

const quarterlySubscription = (data: SubscriptionEventParams) => {
  window.dataLayer.push({
    event: 'quarterly-subscription',
    ...data,
  });
};

const yearlySubscription = (data: SubscriptionEventParams) => {
  window.dataLayer.push({ event: 'yearly-subscription', ...data });
};
// SUBSCRIPTION TRIGGERS END

//
export const sendSubscriptionTrigger = params => {
  const events = {
    1: monthlySubscription,
    3: quarterlySubscription,
    12: yearlySubscription,
  };

  const { months } = params;
  const fn = events[months];

  if (fn) {
    fn(params);
  }
};

//
type LiveEventPurchaseData = {
  order_id?: string;
  event_name: string;
  event_theme: string;
  amount: number;
  currency: string;
  transactionId?: string;
} & UserData;
export const sendLiveEventPurchaseTrigger = (data: LiveEventPurchaseData) => {
  window.dataLayer.push({ event: 'live-event-purchase', ...data });
};

type LiveEventFreeActivationData = {
  event_name: string;
  event_theme: string;
  value: number;
  currency: string;
} & UserData;
export const sendLiveEventFreeActivateTrigger = (
  data: LiveEventFreeActivationData
) => {
  window.dataLayer.push({ event: 'live-event-free-activation', ...data });
};

//
type ProgramActivationEventData = {
  category: string;
  program_name: string;
  program_slug: string;
  amount: number;
  currency: string;
} & UserData;

export const SendProgramActivationEvent = (
  params: ProgramActivationEventData
) => {
  const { program_name, program_slug, category, ...other } = params;
  window.dataLayer.push({
    event: `${category}-free-activation`,
    [`${category}_name`]: program_name,
    [`${category}_slug`]: program_slug,
    ...other,
  });
};

//
type ProgramPurchaseEventData = {
  category: string;
  program_name: string;
  program_slug: string;
  amount: number;
  currency: string;
} & UserData;

export const SendProgramPurchaseEvent = (params: ProgramPurchaseEventData) => {
  const { program_name, program_slug, category, ...other } = params;
  window.dataLayer.push({
    event: `${params.category}-purchase`,
    [`${category}_name`]: program_name,
    [`${category}_slug`]: program_slug,
    ...other,
  });
};

//
type ProgramCodeActivationEventData = {
  category: string;
  program_name: string;
  program_slug: string;
  access_code: string;
  amount: number;
  currency: string;
} & UserData;

export const SendProgramCodeActivationEvent = (
  params: ProgramCodeActivationEventData
) => {
  const { program_name, program_slug, category, ...other } = params;
  window.dataLayer.push({
    event: `${params.category}-purchase`,
    [`${category}_name`]: program_name,
    [`${category}_slug`]: program_slug,
    ...other,
  });
};

//
type ProgramGiftCodePurchaseEventData = {
  category: string;
  program_name: string;
  program_slug: string;
  value: number;
  currency: string;
} & UserData;

export const SendProgramGiftCodePurchaseEvent = (
  params: ProgramGiftCodePurchaseEventData
) => {
  const { program_name, program_slug, category, ...other } = params;
  window.dataLayer.push({
    event: `${params.category}-gift-code-purchase`,
    [`${category}_name`]: program_name,
    [`${category}_slug`]: program_slug,
    ...other,
  });
};
