import * as React from 'react';

function SvgShevronRightIcon(props) {
  return (
    <svg viewBox="0 0 9 15" {...props}>
      <path
        d="M1 13.107l6-6.04L1 1"
        strokeWidth={2}
        stroke="#EA6EA3"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SvgShevronRightIcon;
