const LiveEventAccessPolicy = {
  typePolicies: {
    LiveEventAccess: {
      fields: {
        zoomLink: {
          merge(_, incoming) {
            let path = incoming;
            if (!String(path).startsWith('/')) {
              path = `/${path}`;
            }

            return 'https://zoom.us' + path;
          },
        },
      },
    },
  },
};

export default LiveEventAccessPolicy;
