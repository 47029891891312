import { useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { Body, Modal } from '@organisms/modals/base';
import { RegisterV2CompleteProfile } from './RegisterV2CompleteProfile';
import { Button } from '@components/ButtonV2';
import { Trans } from '@lingui/react';

const DISABLED_PATHS = [
  { path: '/[lang]/courses/[programId]/get-started', exact: true },
  { path: '/[lang]/live/[slug]/[pageId]', exact: false },
  { path: '/[lang]/get-started', exact: false },
  { path: '/[lang]/register', exact: false },
];

export const RegisterV2CompleteProfileModal = () => {
  const [open, setOpen] = useState(true);
  const router = useRouter();

  const disabledOnPage = useMemo(() => {
    return !!DISABLED_PATHS.find(item => {
      return item.exact
        ? item.path === router.pathname
        : router.pathname.startsWith(item.path);
    });
  }, []);

  if (disabledOnPage) {
    return null;
  }

  return (
    <Modal open={open}>
      <Body py={0} data-testid="CompleteProfileModal">
        <RegisterV2CompleteProfile
          className="pt-8"
          onComplete={() => {
            setOpen(false);
          }}
          logout
        />
      </Body>
    </Modal>
  );
};
