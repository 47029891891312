import * as React from 'react';

const SvgCircleCheckmark = props => (
  <svg
    height="16px"
    width="16px"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1Zm0 13A6 6 0 1 1 8 2a6 6 0 0 1 0 12Zm-1-3.25L4.5 8.27l.795-.785L7 9.175 10.705 5.5l.795.79L7 10.75Z"
      fill="#000"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgCircleCheckmark;
