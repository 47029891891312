import * as React from 'react';

const SvgBundleClientIcon = props => (
  <svg viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.633 3v10h-12V3h12Zm0-1h-12a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1Zm-11 3h3.5v1h-3.5V5Zm0 2h2v1h-2V7Zm8.5 2h-3a1.5 1.5 0 0 0-1.5 1.5v1h1v-1a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v1h1v-1a1.5 1.5 0 0 0-1.5-1.5Zm-1.5-.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2Z"
      fill="#000"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgBundleClientIcon;
