import styled from 'styled-components';

export const SocialButton = styled.button`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6d6d6d;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.colors.neutrals['gray-3']};
  border-radius: 3px;
  padding: 8px 12px;
  margin: 8px 5px;
  background: none;
  cursor: pointer;
  transition: border-color 200ms ease;

  :hover {
    border-color: ${({ theme }) => theme.colors.neutrals['gray']};
    box-shadow: 0px 1px 2px 0px
      ${({ theme }) => theme.colors.neutrals['gray-3']};
  }
`;

export const Icon = styled.img`
  height: 24px;
  margin-right: 8px;
`;
