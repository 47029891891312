import { useGoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { AuthCallback } from '@ecosystems/socialAuth';
import { SocialButton, Icon } from './Element';

const GoogleAuth = (props: Props) => {
  const responseGoogle = async access_token => {
    let userInfo = null;
    if (props.tokenOnly) {
      // load user data
      const data = await fetch(
        `https://www.googleapis.com/oauth2/v3/userinfo`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      ).then(resp => resp.json());

      //
      userInfo = {
        firstName: data?.given_name,
        lastName: data?.family_name,
        picture: data?.picture,
      };
    }

    props?.onAuth(access_token, userInfo);
  };

  const googleLogin = useGoogleLogin({
    async onSuccess(resp) {
      responseGoogle(resp.access_token);
    },
    onError(error) {
      console.log({ error });
    },
    scope:
      'openid email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
  });

  return (
    <SocialButton
      type="button"
      onClick={() => {
        //
        googleLogin();
      }}
    >
      <Icon src="/static/icons/google.svg" />
      Google
    </SocialButton>
  );
};

type Props = {
  onAuth: AuthCallback;
  tokenOnly?: boolean;
};

export const GoogleAuthButton = (props: Props) => {
  return (
    <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID}>
      <GoogleAuth {...props} />
    </GoogleOAuthProvider>
  );
};
