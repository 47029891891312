import { parseString, splitCookiesString } from 'set-cookie-parser';
import { CurrentUser } from '@gql/generated';
import { AUTH_TOKEN, AUTH_REFRESH_TOKEN } from '@lib/constants';

const GET_CURRENT_USER_SIMPLE = `
  query {
    currentUser {
      id
      preferredCurrency
      completedAt
    }
  }
`;

export async function loadCurrentUser({
  token,
  refresh_token,
}): Promise<{ cookies: string[]; currentUser: CurrentUser }> {
  try {
    const resp = await fetch(process.env.APOLLO_BACKEND_RAILS, {
      method: 'POST',
      headers: {
        'ORIGIN-CLIENT': 'Vercel',
        'Content-Type': 'application/json',
        ...(refresh_token
          ? {
              Cookie: `${AUTH_TOKEN}=${token}; ${AUTH_REFRESH_TOKEN}=${refresh_token};`,
            }
          : {}),
      },
      body: JSON.stringify({
        query: GET_CURRENT_USER_SIMPLE,
      }),
    });

    const cookies = splitCookiesString(resp.headers.get('set-cookie'), {
      decodeValues: true, // default: true
      map: true, // default: false
    });

    if (resp.ok) {
      const body = await resp.text();
      const response = JSON.parse(body);
      if (response.data?.currentUser?.id) {
        return { cookies, currentUser: response.data?.currentUser };
      }
    }
  } catch (error) {
    console.error('loadCurrentUser', error);
  }
  return null;
}

interface Cookies {
  token?: string;
  refresh_token?: string;
}

type Payload = { cookies: Record<string, string>; currentUser: CurrentUser };

export async function refreshAuthCookies(
  cookies: Cookies,
  response
): Promise<Payload> {
  const token = cookies[AUTH_TOKEN];
  const refresh_token = cookies[AUTH_REFRESH_TOKEN];

  const refreshData = await loadCurrentUser({
    token,
    refresh_token,
  });

  const wantedCookies = [AUTH_TOKEN, AUTH_REFRESH_TOKEN];
  // will be used by apollo requests during SSR
  const parsedCookies = {};

  //
  if (response) {
    if (refreshData?.cookies) {
      // set cookies in response, to update browser cookies
      response.setHeader('Set-Cookie', refreshData.cookies);
      //
      refreshData.cookies.forEach(cookie => {
        //
        const parsedCookie = parseString(cookie, {
          decodeValues: true,
          map: true,
        });
        if (wantedCookies.includes(parsedCookie.name)) {
          parsedCookies[parsedCookie.name] = parsedCookie.value;
        }
      });
      //
      return { cookies: parsedCookies, currentUser: refreshData?.currentUser };
    }
  }

  return null;
}
