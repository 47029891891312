import React from 'react';
import Styled from 'styled-components';
import { Trans } from '@lingui/react';
import { useRouter } from 'next/router';
import { Body } from '@organisms/modals/base';
import { PAYMENT_CONFIRMATION_ACTION } from '@lib/constants';
import { hideURLParams } from '@lib/helpers';
import Icon from '@components/Icon';
import buttons from '@components/buttons';
import { Flex } from '@components/layout/Grid';

const Buttons = Styled(buttons)`
  margin: 0 10px;
`;

const Wrapper = Styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const Title = Styled.p`
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`;

const Text = Styled.p`
  font-size: 16px;
  margin: 15px 0 0;
`;

declare type Props = {
  amountCents: number;
  currency: string;
  onClose(): void;
};

export const BundlePaymentConfirmationMessage = (props: Props) => {
  const router = useRouter();
  const { lang } = router?.query;
  const { amountCents, currency } = props;

  const onClose = () => {
    hideURLParams(PAYMENT_CONFIRMATION_ACTION);
    props.onClose();
  };

  return (
    <>
      <Body pb={40}>
        <Wrapper data-testid="BundlePaymentConfirmationMessage">
          <Icon
            name="ygb-icon-checkmark"
            fontSize="24px"
            color="white"
            style={{
              lineHeight: 1,
              backgroundColor: '#93BDBB',
              borderRadius: '50%',
              padding: '50px',
              margin: '0 auto 20px',
            }}
          />
          <Title>
            <Trans id="metadata.payment.success.title" />
          </Title>
          <Text>
            <Trans
              id="payment_bundle_success.modal_text"
              values={{
                paymentAmount: `${(amountCents / 100).toFixed(2)} ${currency}`,
              }}
            />
          </Text>
        </Wrapper>
        <Flex justifyContent="center" pt={40}>
          <Buttons href="#" appearance="default-blue" onClick={onClose}>
            <Trans id="payment_bundle_success.get_started" />
          </Buttons>
          <Buttons
            href={`/${lang}/me/dashboard`}
            appearance="outline"
            data-testid="BundlePaymentConfirmationMessage-Button"
          >
            <Trans id="payment_bundle_success.go_dashboard_btn" />
          </Buttons>
        </Flex>
      </Body>
    </>
  );
};
