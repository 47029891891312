import { normalizeImagePath } from '@lib/helpers';

export const genericFieldResolvers = {
  title(_, { readField, toReference }) {
    const genericProgramRef = toReference({
      __typename: 'GenericProgram',
      slug: readField('slug'),
    });
    return readField('title', genericProgramRef);
  },
  trailer(_, { readField, toReference }) {
    const genericProgramRef = toReference({
      __typename: 'GenericProgram',
      slug: readField('slug'),
    });
    return readField('trailer', genericProgramRef);
  },
  language(_, { readField, toReference }) {
    const genericProgramRef = toReference({
      __typename: 'GenericProgram',
      slug: readField('slug'),
    });
    return readField('language', genericProgramRef);
  },
  progress(_, { readField, toReference }) {
    const genericProgramRef = toReference({
      __typename: 'GenericProgram',
      slug: readField('slug'),
    });
    return readField('progress', genericProgramRef);
  },
  instructors(_, { readField, toReference }) {
    const genericProgramRef = toReference({
      __typename: 'GenericProgram',
      slug: readField('slug'),
    });
    return readField('instructors', genericProgramRef);
  },
};

export default {
  typePolicies: {
    GenericProgram: {
      keyFields: ['slug'],
      fields: {
        titleImageUrl: {
          merge(_, incoming) {
            return normalizeImagePath(incoming);
          },
        },
      },
    },
  },
};
