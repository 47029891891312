import * as React from 'react';
import { ChevronsUpDown } from 'lucide-react';
import { Button } from 'src/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from 'src/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from 'src/ui/popover';
import LanguageContext from '@lib/contexts/languageContext';
import { countryToFlag } from './PhoneInput';

export function CountryCombobox({
  country = null,
  defaultSelectedCountryCode = null,
  onChange,
}) {
  const [open, setOpen] = React.useState(false);
  const triggerRef = React.useRef(null);
  const listRef = React.useRef(null);
  const [_countries, setCountries] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState(country);
  const lang = React.useContext(LanguageContext);

  React.useLayoutEffect(() => {
    if (open && triggerRef.current) {
      requestAnimationFrame(() => {
        const { width } = triggerRef.current.getBoundingClientRect();
        listRef.current.style.width = width + 'px';
      });
    }
  }, [open]);

  React.useEffect(() => {
    // eslint-disable-next-line
    const countries = require(`@lib/countries/${lang}.json`);
    setCountries(countries);
    if (!country && !defaultSelectedCountryCode) {
      setSelectedCountry(countries[0]);
      onChange(countries[0]);
    } else if (defaultSelectedCountryCode) {
      const country = countries.find(
        country => country.code === defaultSelectedCountryCode
      );
      setSelectedCountry(country);
      onChange(country);
    }
  }, []);

  return (
    <Popover open={open} onOpenChange={setOpen} modal={true}>
      <PopoverTrigger asChild>
        <Button
          ref={triggerRef}
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="flex justify-between w-full border border-neutral-300 rounded py-4 h-12"
        >
          {selectedCountry ? (
            <span className="flex gap-4">
              <span>{countryToFlag(selectedCountry?.code)}</span>
              <span>{selectedCountry?.label}</span>
            </span>
          ) : (
            'Select a country...'
          )}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0">
        <Command className="w-full">
          <CommandInput
            placeholder="Search framework..."
            onClick={e => {
              e.stopPropagation();
            }}
          />
          <CommandEmpty>No country found.</CommandEmpty>
          <CommandList ref={listRef}>
            <CommandGroup>
              {_countries.map((country, i) => {
                return (
                  <CommandItem
                    key={i}
                    className="px-4"
                    value={country.label}
                    disabled={false}
                    onSelect={() => {
                      setSelectedCountry(country);
                      onChange(country);
                      setOpen(false);
                    }}
                  >
                    <span className="flex gap-4">
                      <span>{countryToFlag(country.code)}</span>
                      <span>{country.label}</span>
                    </span>
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
