import { parse } from 'date-fns/parse';
import { isFuture } from 'date-fns/isFuture';
import { isPast } from 'date-fns/isPast';
import { isValid } from 'date-fns/isValid';
import { CurrentUser } from '@gql/generated';
import { compareDesc } from 'date-fns/compareDesc';
import { compareAsc } from 'date-fns/compareAsc';

const CurrentUserPolicy = {
  typePolicies: {
    CurrentUser: {
      fields: {
        accessUntil(accessUntil) {
          if (accessUntil instanceof Date) {
            return accessUntil;
          }
          return parse(accessUntil, 'yyyy-MM-dd', new Date());
        },
        hasWellness(_, { readField }) {
          const receipts = readField('receipts');

          const userUsedWellness =
            ['benify', 'wellness', 'ePassi'].includes(
              receipts?.[0]?.paymentType
            ) || String(receipts?.[0]?.number).startsWith('WellnessAccess');

          return userUsedWellness;
        },
        isPremium(_, { readField }) {
          const accessUntil = readField('accessUntil');
          return isFuture(new Date(accessUntil));
        },
        isNoneTrialPremium(_, { readField }) {
          const isPremium = readField('isPremium');
          const subscription: CurrentUser['subscription'] = readField(
            'subscription'
          );

          return isPremium && !subscription?.inTrialPeriod;
        },
        liveEventsAccess: {
          merge(_, coming, { readField }) {
            return coming.sort((a, b) => {
              const A_eventDate = new Date(
                readField('eventDates', a['liveEvent'])[0]
              );
              const B_eventDate = new Date(
                readField('eventDates', b['liveEvent'])[0]
              );
              if (isFuture(A_eventDate) && isFuture(B_eventDate)) {
                // if both events are upcoming we sort them ASC
                return compareAsc(A_eventDate, B_eventDate);
              } else {
                return compareDesc(A_eventDate, B_eventDate);
              }
            });
          },
        },
        challengeAccesses: {
          read(value, { readField }) {
            const userAccess = readField('accessUntil');
            return [...(value || [])].map(({ accessUntil, ...otherField }) => {
              // convert accessUntil from unix timestamp to Date
              const acessUntil = new Date(parseInt(accessUntil) * 1000);
              return {
                ...otherField,
                accessUntil: isValid(acessUntil) ? acessUntil : userAccess,
              };
            });
          },
        },
        courseAccesses: {
          read(value, { readField }) {
            return [...(value || [])]
              .filter(item => {
                return !readField('legacy', item.course);
              })
              .map(({ accessUntil, ...otherField }) => {
                // convert accessUntil from unix timestamp to Date
                const _accessUntil = new Date(parseInt(accessUntil) * 1000);

                return {
                  ...otherField,
                  accessUntil: isValid(_accessUntil)
                    ? _accessUntil
                    : new Date(1, 1, 1990),
                };
              })
              .sort((a, b) => {
                const date1 = a.accessUntil;
                const date2 = b.accessUntil;
                if (isPast(date1) && !isPast(date2)) {
                  return -1;
                } else if (isPast(date2) && !isPast(date1)) {
                  return 1;
                } else {
                  return 0;
                }
              });
          },
        },
      },
    },
  },
};

export default CurrentUserPolicy;
