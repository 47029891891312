import * as React from 'react';

const SvgCastIcon = (props) => (
  <svg
    width={22}
    height={18}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <path
        d="M21 3H3c-1.1 0-2 .9-2 2v3h2V5h18v14h-7v2h7c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2ZM1 18v3h3c0-1.66-1.34-3-3-3Zm0-4v2c2.76 0 5 2.24 5 5h2c0-3.87-3.13-7-7-7Zm0-4v2a9 9 0 0 1 9 9h2c0-6.08-4.93-11-11-11Z"
        id="cast-icon_svg__a"
      />
    </defs>
    <g transform="translate(-1 -3)" fill="none" fillRule="evenodd">
      <path opacity={0.1} d="M0 0h24v24H0z" />
      <mask id="cast-icon_svg__b" fill="#fff">
        <use xlinkHref="#cast-icon_svg__a" />
      </mask>
      <use fill="#000" xlinkHref="#cast-icon_svg__a" />
      <g mask="url(#cast-icon_svg__b)" fill="#FFF">
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);

export default SvgCastIcon;
