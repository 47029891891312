import * as React from 'react';

function SvgPanelIcon(props) {
  return (
    <svg viewBox="0 0 28 22" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17 5h7v2h-7zm0 5h7v2h-7zm0 5h7v2h-7zm9-15H2a2.002 2.002 0 00-2 2v18a2.002 2.002 0 002 2h24a2.003 2.003 0 002-2V2a2.002 2.002 0 00-2-2zM2 2h11v18H2V2zm13 18V2h11l.002 18H15z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgPanelIcon;
