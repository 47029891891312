import React, { Component } from 'react';
import { Mutation } from '@apollo/client/react/components';
import gql from 'graphql-tag';
import { Trans } from '@lingui/react';
import { Box, Flex } from '@components/layout/Grid';
import { H4 } from '@components/typography/Headings';
import { Header, Modal, Body, Footer } from './base';
import { PCancelSubscriptionModal } from '@components/texts';
import Button from '@components/buttons';
import FormGroup from '@components/form/FormGroup';
import NewInput from '@components/form/NewInput';
import { snackbarVar } from '@lib/apollo/policies';

const RESET_PASSWORD = gql`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email) {
      success
    }
  }
`;

export default class extends Component {
  state = {
    open: false,
    email: '',
    error: '',
  };

  handleOpenModal = () => {
    this.setState({
      open: true,
    });
  };

  handleCloseModal = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          open={this.state.open}
          handleClose={this.handleCloseModal}
          customWidth="500px"
        >
          <Mutation mutation={RESET_PASSWORD}>
            {(resetPassword, { loading }) => (
              <form
                onSubmit={e => {
                  e.preventDefault();
                  resetPassword({
                    variables: {
                      email: this.state.email,
                    },
                  }).then(({ data: { resetPassword } }) => {
                    if (resetPassword?.success === true) {
                      snackbarVar({
                        isSnackBarOpen: true,
                        goTo: 'NONE',
                        id: 'password_recovery',
                        snackBarMessage: 'Email successfully sent',
                      });
                      this.handleCloseModal();
                    } else {
                      this.setState({
                        error: 'Something went wrong',
                      });
                    }
                  });
                }}
              >
                <Header handleClose={this.handleCloseModal}>
                  <Box as={H4} mt="0px" mb="0px">
                    <Trans
                      id="forgot_password_modal.title"
                      message={'Forgotten your password?'}
                    />
                  </Box>
                </Header>
                <Body>
                  <Box mb="20px">
                    <PCancelSubscriptionModal>
                      <Trans
                        id="forgot_password_modal.paragraph"
                        message={
                          'Enter your email address and you will receive an email containing instructions on how to reset your password.'
                        }
                      />
                    </PCancelSubscriptionModal>
                  </Box>
                  <Box mb="20px">
                    <FormGroup errorMsg={this.state.error}>
                      <label htmlFor="email">
                        <Trans
                          id="forgot_password_modal.email"
                          message={`Email`}
                        />
                      </label>
                      <NewInput
                        type="email"
                        name="email"
                        id="email"
                        onChange={e => {
                          this.setState({
                            email: e.target.value,
                          });
                        }}
                        value={this.state.email}
                      />
                    </FormGroup>
                  </Box>
                </Body>
                <Footer>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    style={{
                      width: '100%',
                    }}
                  >
                    <Box px="10px">
                      <Button
                        type="button"
                        appearance="link"
                        onClick={this.handleCloseModal}
                        spinnerColor="white"
                      >
                        <Trans
                          id="forgot_password_modal.back"
                          message={`Back to login`}
                        />
                      </Button>
                    </Box>
                    <Box px="10px">
                      <Button
                        type="submit"
                        loading={loading}
                        spinnerColor="white"
                      >
                        <Trans
                          id="forgot_password_modal.send_instructions"
                          message={`Send Intructions`}
                        />
                      </Button>
                    </Box>
                  </Flex>
                </Footer>
              </form>
            )}
          </Mutation>
        </Modal>
        {this.props.children(this.handleOpenModal)}
      </React.Fragment>
    );
  }
}
