const merge = (existing, incoming) => {
  const mergedList = [...(existing?.data ?? []), ...(incoming?.data ?? [])];

  // better than doing multiple iterations with filter & some...
  const data = Object.values(
    mergedList.reduce((acc, item) => {
      acc[item.__ref] = item;
      return acc;
    }, {})
  );

  return {
    ...(incoming ?? {}),
    data,
  };
};

const PagedWellnessPackage = {
  typePolicies: {
    /* 
      ******** ISSUE ********
      because wellness query returns items with the same ID
      we get result with different content but same ID
      
      ******** SOLUTION ********
      disable caching by ID 
    */
    Wellness: {
      keyFields: false as const,
      keyArgs: [],
      fields: {
        courses: {
          keyFields: false as const,
          keyArgs: [],
          merge,
        },
        wellnessAccesses: {
          keyFields: false as const,
          keyArgs: [],
          merge,
        },
      },
    },
  },
};

export default PagedWellnessPackage;
