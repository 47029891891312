import { capitalize } from 'lodash';

const PaymentPolicy = {
  typePolicies: {
    Payment: {
      fields: {
        paymentProvider: {
          read(paymentProvider, { readField }) {
            const paymentProviderVariant = readField('paymentProviderVariant');
            if (paymentProvider === 'adyen' && paymentProviderVariant) {
              return capitalize(paymentProviderVariant);
            }

            return paymentProvider;
          },
        },
      },
    },
    ExtraPayment: {
      fields: {
        paymentProvider: {
          read(paymentProvider, { readField }) {
            const paymentProviderVariant = readField('paymentProviderVariant');
            if (paymentProvider === 'adyen' && paymentProviderVariant) {
              return capitalize(paymentProviderVariant);
            }

            return paymentProvider;
          },
        },
      },
    },
  },
};

export default PaymentPolicy;
