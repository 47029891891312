import { useContext } from 'react';
import { Trans } from '@lingui/react';
import styled from 'styled-components';
import AdyenForm, { PaymentMethods } from './AdyenFormModal';
import { Body, Header, Modal } from '../base';
import LanguageContext from '@lib/contexts/languageContext';
import { useAddPaymentMethod } from '@lib/hooks/useAddPaymentMethod';
import useFlags from '@lib/hooks/useFlags';
import { usePreference } from '@lib/hooks/usePreference';

const Title = styled.p`
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  margin-top: 0;
  margin-bottom: 20px;
`;

type Props = {
  onClose(): void;
  onPaymentMethodAdded({ newRecord }: { newRecord: boolean }): void;
  zIndex?: number;
};

export const AddPaymentMethodModal = (props: Props) => {
  const { onClose, onPaymentMethodAdded } = props;
  const [flags] = useFlags();
  const lang = useContext(LanguageContext);
  const preferences = usePreference();
  const {
    loading,
    error,
    addMethod,
    handleAdditionalPaymentDetails,
  } = useAddPaymentMethod({
    redirectTo: `${process.env.DOMAIN}/${lang}/me/settings/payment-methods`,
    onPaymentDetailsAdded(data) {
      onPaymentMethodAdded({ newRecord: data.newRecord });
    },
    onPaymentMethodAdded(data) {
      onPaymentMethodAdded({ newRecord: data.newRecord });
    },
  });

  let enabledPaymentMethods: PaymentMethods = ['card'];
  if (flags.disable_trustly_add_payment_method !== true && lang === 'se') {
    enabledPaymentMethods.push('trustly');
  }

  return (
    <Modal zIndex={props?.zIndex} open>
      <Header handleClose={onClose} />
      <Body pb={40}>
        <Title>
          <Trans id="add_new_payment_method_modal.title" />
        </Title>
        <AdyenForm
          loading={loading}
          amount={0}
          currency={preferences.currency}
          onSubmit={addMethod}
          onAdditionalDetails={handleAdditionalPaymentDetails}
          paymentError={error}
          enabledPaymentMethods={enabledPaymentMethods}
          showStoredMethods={false}
          onClose={onClose}
        />
      </Body>
    </Modal>
  );
};
