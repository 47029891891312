import styled from 'styled-components';

export const H1 = styled.h1`
  font-size: 34px;
  font-weight: 300;
  color: ${props => props.theme.colors.neutrals.black};
`;

export const H2 = styled.h2`
  font-size: 24px;
  font-weight: 300;
  color: ${props => props.theme.colors.neutrals.black};
`;
export const H3 = styled.h3<{
  bold?: boolean;
  uppercase?: boolean;
  capitalize?: boolean;
}>`
  font-size: 18px;
  font-weight: ${props => (props.bold ? '600' : '300')};
  color: ${props => props.theme.colors.neutrals.black};
  text-transform: ${props => (props.uppercase ? 'uppercase' : null)};
  text-transform: ${props => (props.capitalize ? 'capitalize' : null)};
  margin: 0;
`;

export const H4 = styled.h4<{
  bold?: boolean;
  uppercase?: boolean;
  capitalize?: boolean;
}>`
  font-size: 16px;
  font-weight: ${props => (props.bold ? '600' : 'normal')};
  color: ${props =>
    props.color ? props.color : props.theme.colors.neutrals.black};
  text-transform: ${props => (props.uppercase ? 'uppercase' : null)};
  text-transform: ${props => (props.capitalize ? 'capitalize' : null)};
`;

export const H5 = styled.h5<{
  bold?: boolean;
  uppercase?: boolean;
  capitalize?: boolean;
}>`
  font-size: 13px;
  font-weight: ${props => (props.bold ? '600' : 'normal')};
  color: ${props => props.theme.colors.neutrals.black};
  text-transform: ${props => (props.uppercase ? 'uppercase' : null)};
`;

export const H6 = styled.h1`
  font-size: 11px;
  font-weight: 600;
  color: ${props => props.theme.colors.neutrals.black};
`;
