import * as React from 'react';

const SvgSearchIcon = props => (
  <svg viewBox="0 0 28 29" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M28 26.59 20.45 19A11 11 0 1 0 19 20.45L26.59 28 28 26.59ZM3 12a9 9 0 1 1 18 0 9 9 0 0 1-18 0Z"
      fill="#000"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgSearchIcon;
