import styled from 'styled-components';
import { Modal } from '@organisms/modals/base';

export const PaymentModal = styled(Modal)`
  overflow-y: scroll;

  .MuiBackdrop-root {
    display: none;
  }

  .MuiDialog-container {
    position: relative;
    height: unset;
    min-height: 100vh;
  }

  .MuiDialog-paperScrollPaper {
    max-height: none;
  }

  .MuiDialog-container.MuiDialog-scrollPaper:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    /* bottom: 0; */
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
`;
