import * as React from 'react';

function SvgChevronUp(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          id="chevron-up_svg__a"
          d="M0 5.399l1.4 1.4 4-4 4 4 1.4-1.4L5.4 0z"
        />
      </defs>
      <g
        transform="matrix(1 0 0 -1 0 7)"
        fill="none"
        fillRule="evenodd"
        opacity={0.7}
      >
        <mask id="chevron-up_svg__b" fill="#fff">
          <use xlinkHref="#chevron-up_svg__a" />
        </mask>
        <g mask="url(#chevron-up_svg__b)" fill="#000" fillOpacity={0.75}>
          <path d="M0 0h12v7H0z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgChevronUp;
