import { PromotionKind, UserSubscription } from '@gql/generated';

const AvailableSubscriptionPlan = {
  typePolicies: {
    AvailableSubscriptionPlan: {
      fields: {
        subscriptions(subscriptions, ctx) {
          const { variables } = ctx;
          if (variables['_filter_subscriptions_'] === false) {
            return subscriptions;
          }

          // if a promotion is available we want to exclude its related original none-promotion subscription
          // example: remove original quarterly in-favore of quarterly discount
          const filteredSubs = subscriptions.reduce(
            (acc, subscription: UserSubscription) => {
              let key = subscription.subscriptionPlan;
              if (
                subscription.promotionKind &&
                ![
                  'global',
                  'subscription_discount_code',
                  'subscription_full_access_code',
                ].includes(subscription.promotionKind)
              ) {
                key = subscription.promotionName;
              }
              const sub = { ...subscription };

              // if discount > amount, the plan is probably for an offer that renews as a different subscription
              if (sub.discountedAmount > sub.amount) {
                sub.amount = sub.discountedAmount;
                sub.discountedAmount = null;
              }
              acc[key] = sub;

              return acc;
            },
            {}
          );

          // sort subs
          return Object.values(filteredSubs).sort(
            (a: UserSubscription, b: UserSubscription) => {
              if (
                a.promotionKind &&
                a.promotionKind !== PromotionKind['Global']
              ) {
                return -1;
              } else if (
                b.promotionKind &&
                b.promotionKind !== PromotionKind['Global']
              ) {
                return 1;
              }

              return a.months > b.months ? 1 : -1;
            }
          );
        },
      },
    },
  },
};

export default AvailableSubscriptionPlan;
