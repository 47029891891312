import * as React from 'react';

function SvgChallengeTypeIc(props) {
  return (
    <svg width={28} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M23 19h-6a3 3 0 00-3 3v2h2v-2a1 1 0 011-1h6a1 1 0 011 1v2h2v-2a3 3 0 00-3-3zm-7-5a4 4 0 108 0 4 4 0 00-8 0zm6 0a2 2 0 11-4 0 2 2 0 014 0zm-14 .59L5.41 12 4 13.41l4 4 7-7L13.59 9 8 14.59zM12 22H2V2h7.172l3.414 3.414.586.586H26v8h2V6a2 2 0 00-2-2H14L10.586.586A2 2 0 009.172 0H2a2 2 0 00-2 2v20a2 2 0 002 2h10v-2z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgChallengeTypeIc;
