import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/react';
import { Button as button } from '@components/ButtonV2';

const Element = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 1px solid #e6e9eb;
  padding: 14px 16px;
  outline: 1px solid transparent;
  transition: outline 300ms ease-in-out;

  :hover {
    outline: 1px solid #e6e9eb;
    cursor: pointer;
  }
`;

const Button = styled(button)`
  width: 100%;
  justify-content: center;
  padding: 15px;
  transition: background-color 200ms ease-in-out, box-shadow 200ms ease-in-out;
  overflow: hidden;

  --border: #56b9e5;
  --bg-color: #56b9e5;

  &:hover {
    --border: #129edc;
    --bg-color: #129edc;
    box-shadow: 0 0, 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14);
  }
`;

const Header = styled.p`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
`;

const Logo = styled.img`
  height: 30px;
  border: 1px solid rgba(0, 27, 43, 0.17);
  border-radius: 3px;
  padding: 5px 8px;
  margin-right: 8px;
`;

type EpassiFormProps = {
  data: EpassiData;
};

export const EpassiForm = (props: EpassiFormProps) => {
  const formRef = useRef<HTMLFormElement>();
  const { data } = props;

  useEffect(() => {
    setTimeout(() => {
      // sometimes form submits without values, so we wrap in timeout instead of RAF
      formRef.current.submit();
    }, 500);
  }, []);

  return (
    <form ref={formRef} action={process.env.EPASSI_ENDPOINT} method="post">
      <input type="hidden" name="STAMP" value={data.stamp} />
      <input type="hidden" name="SITE" value={data.site} />
      <input type="hidden" name="MAC" value={data.mac} />
      <input type="hidden" name="AMOUNT" value={data.amount} />
      <input type="hidden" name="FEE" value={data.fee} />
      <input type="hidden" name="VAT_VALUE" value={data.vatValue} />
      <input type="hidden" name="REJECT" value={data.rejectUrl} />
      <input type="hidden" name="CANCEL" value={data.cancelUrl} />
      <input type="hidden" name="RETURN" value={data.returnUrl} />
      <input type="hidden" name="NOTIFY_URL" value={data.notifyUrl} />
      <input type="hidden" value="ePassi" />
    </form>
  );
};

type EpassiData = Record<
  | 'stamp'
  | 'site'
  | 'mac'
  | 'amount'
  | 'fee'
  | 'vatValue'
  | 'rejectUrl'
  | 'cancelUrl'
  | 'returnUrl'
  | 'notifyUrl',
  string
>;

export type Props = {
  getEpassiData(): Promise<EpassiData>;
};
export const EpassiElement = (props: Props) => {
  const { getEpassiData } = props;
  const [data, setData] = useState<EpassiData>(null);
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    try {
      setLoading(true);
      const ePassiData = await getEpassiData();
      setData(ePassiData);
    } catch (ex) {
      //
    } finally {
      setLoading(false);
    }
  };

  return (
    <Element>
      <Header>
        <Logo src="/static/epassi.png" />
        <Trans id="payment_modal.wellness_methods.epassi.title" />
      </Header>
      <Button type="submit" value="ePassi" loading={loading} onClick={onClick}>
        <Trans id="payment_modal.wellness_methods.epassi.pay_btn" />
      </Button>
      {data ? <EpassiForm data={data} /> : null}
    </Element>
  );
};
