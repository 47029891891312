import * as React from 'react';

function SvgRepeatIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 456.559 456.559"
      {...props}
    >
      <path d="M351.79 151.874a13.392 13.392 0 01-9.498-3.931c-5.238-5.245-5.238-13.75 0-18.995l53.02-53.006-53.02-53.013c-5.238-5.245-5.238-13.75 0-18.995 5.245-5.245 13.75-5.245 18.995 0l62.511 62.511a13.436 13.436 0 010 18.996l-62.511 62.504a13.395 13.395 0 01-9.497 3.929z" />
      <path d="M42.958 227.428c-7.413 0-13.428-6.015-13.428-13.428v-80.932c0-38.907 31.647-70.554 70.554-70.554h314.218c7.413 0 13.428 6.015 13.428 13.428 0 7.413-6.015 13.428-13.428 13.428H100.083c-24.094 0-43.697 19.604-43.697 43.697V214c0 7.414-6.015 13.428-13.428 13.428zM357.162 394.049H42.258c-7.413 0-13.428-6.015-13.428-13.428s6.015-13.428 13.428-13.428h314.903c24.101 0 43.704-19.604 43.704-43.697v-82.534c0-7.413 6.015-13.428 13.428-13.428 7.413 0 13.428 6.015 13.428 13.428v82.534c.001 38.906-31.653 70.553-70.559 70.553z" />
      <path d="M104.769 456.559a13.392 13.392 0 01-9.498-3.931L32.76 390.117a13.436 13.436 0 010-18.996l62.511-62.504c5.245-5.245 13.75-5.245 18.995 0 5.238 5.245 5.238 13.75 0 18.995l-53.02 53.006 53.02 53.013c5.238 5.245 5.238 13.75 0 18.995a13.378 13.378 0 01-9.497 3.933z" />
    </svg>
  );
}

export default SvgRepeatIcon;
