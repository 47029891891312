import * as React from 'react';

function SvgVideoIcon(props) {
  return (
    <svg viewBox="0 0 24 26" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9 13v8l7-4-7-4zm13 13H2a2.002 2.002 0 01-2-2V10a2.002 2.002 0 012-2h20a2.002 2.002 0 012 2v14a2.002 2.002 0 01-2 2zM2 10v14h20V10H2zm0-6h20v2H2zm2-4h16v2H4z"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgVideoIcon;
