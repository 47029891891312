import React from 'react';
import styled from 'styled-components';

const Element = styled.div`
  display: ${props => (props.showMobile ? 'none' : 'block')};
  border-bottom: 1px solid
    ${props =>
      props.color ? props.color : props.theme.colors.secondary['gray-4']};
  @media (max-width: ${props => `${props.theme.viewports.tablet}px`}) {
    display: ${props => (props.showMobile ? 'block' : null)};
  }
`;

const Divider = props => {
  return <Element {...props} />;
};

export default Divider;
