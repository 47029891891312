import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Styled, { useTheme } from 'styled-components';
import Icon, { icons } from '@components/Icon';
import { Trans } from '@lingui/react';

// {color, font-size} ARE INHERITED PROPERTIES
// TO CUSTOMIZE A COMPONENT JUST USE CLASSNAME

const Wrapper = Styled.span`
  position: relative;
  display: inline-flex;
  margin: 0;
  z-index: 9;
`;

export const TooltipText = Styled.span<{ show: boolean; position?: number }>`
  display: ${({ show }) => (show ? 'initial' : 'none')};
  position: absolute;
  top: 100%;
  right: ${({ position }) => position ?? 0}px;
  width: 400px;
  max-width: 90vw;
  white-space: wrap;
  border-radius: 5px;
  padding: 10px;
  margin-right: -6vw;
  margin-top: 10px;
  background-color: ${props => props.theme.colors.neutrals['white-darker']};
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
  
  &:after,
  &:before {
    bottom: 100%;
    left: ${({ position }) => {
      // add 5 to center tooltip under icon
      return `${position + 5}px`;
    }};
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: ${props =>
      props.theme.colors.neutrals['white-darker']};
    border-width: 5px;
    margin-left: -5px;
  }

  &:before {
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: ${props =>
      props.theme.colors.neutrals['white-darker']};
    border-width: 6px;
    margin-left: -6px;
  }
`;

declare type Props = {
  className?: string;
  children?: React.ReactNode | string;
  translationId?: string;
  showTooltip?: boolean;
  icon?:
    | {
        name?: typeof icons[number];
        color?: string;
        fontSize?: string;
      }
    | JSX.Element;
};

const iconDefaults = {
  color: 'gray',
  fontSize: '14px',
};

const IconTooltip = ({
  className,
  children,
  translationId,
  showTooltip,
  icon = {},
}: Props) => {
  const tooltipAnchor = useRef();
  const tooltipRef = useRef();
  const [position, setPosition] = useState(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const updateTooltipPosition = useCallback(() => {
    const tooltipAnchorEl = tooltipAnchor?.current as HTMLElement;
    const tooltipEl = tooltipRef?.current as HTMLElement;
    if (tooltipAnchorEl && tooltipEl) {
      const { left: anchorLeft } = tooltipAnchorEl?.getBoundingClientRect();
      const { width } = tooltipEl?.getBoundingClientRect();

      let left = -50;
      if (anchorLeft + left + width > window.innerWidth) {
        left = window.innerWidth - (anchorLeft + width) - 20;
      }
      tooltipEl.style.left = left + 'px';
      setPosition(-left);
    }
  }, [tooltipAnchor?.current, tooltipRef?.current]);

  useEffect(() => {
    if (tooltipVisible) {
      updateTooltipPosition();
    }
  }, [tooltipVisible]);

  let events = {},
    IconElement;

  // component controlled externally
  if (!showTooltip) {
    events = {
      onMouseOver: () => setTooltipVisible(true),
      onMouseOut: () => setTooltipVisible(false),
    };
  }

  if (React.isValidElement(icon)) {
    IconElement = React.cloneElement(icon, { ...events });
  } else {
    const iconProps = Object.assign(iconDefaults, icon);
    IconElement = <Icon name="ygb-icon-questionb" {...iconProps} {...events} />;
  }

  return (
    <Wrapper ref={tooltipAnchor} className={`iconTooltip ${className}`}>
      {IconElement}
      <TooltipText
        ref={tooltipRef}
        show={tooltipVisible || showTooltip}
        position={position}
      >
        {translationId ? <Trans id={translationId} /> : children}
      </TooltipText>
    </Wrapper>
  );
};

export default IconTooltip;
