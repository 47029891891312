import { CurrentUser, ProgramCategory } from '@gql/generated';
import {} from '../contexts/UserProvider';

let manager;

function getUserProperties(currentUser: CurrentUser) {
  let subscription_type =
    currentUser?.subscription?.currentSubscriptionPlan?.planType;

  if (
    ['monthly', 'quarterly', 'yearly'].includes(
      currentUser?.subscription?.currentSubscriptionPlan?.planType
    )
  ) {
    subscription_type = `${subscription_type}_${currentUser?.subscription?.status}`;
  }
  return {
    user_id: currentUser?.id,
    subscription_type,
    access_until: currentUser?.accessUntil,
  };
}
class AmplitudeManager {
  amplitude;
  userProperties;

  constructor(currentUser: CurrentUser) {
    const amplitude = require('amplitude-js').default;
    this.amplitude = amplitude.getInstance();
    this.amplitude.init(process.env.AMPLITUDE_KEY, currentUser?.id || '');
    if (currentUser?.id) {
      // eslint-disable-next-line
      this.userProperties = getUserProperties(currentUser);
      this.setUserProperties(this.userProperties);
    }
  }

  setUserProperties(userProperties = null) {
    if (userProperties) {
      this.amplitude.setUserProperties(userProperties);
    }
  }

  sendPlayVideoEvent(data) {
    const event = 'single video play';
    if (this.userProperties) {
      this.amplitude.logEvent(event, { ...this.userProperties, ...data });
    }
  }

  sendPlaylistPlayEvent() {
    const event = 'playlist play';
    if (this.userProperties) {
      this.amplitude.logEvent(event, this.userProperties);
    }
  }

  sendVideoAddedToFavorites(data = {}) {
    const event = 'video added to favorites';
    if (this.userProperties) {
      this.amplitude.logEvent(event, { ...this.userProperties, ...data });
    }
  }

  sendPlaylistAddedToFavorites(data = {}) {
    const event = 'playlist added to favorites';
    if (this.userProperties) {
      this.amplitude.logEvent(event, { ...this.userProperties, ...data });
    }
  }

  sendVideoAddToPlaylistEvent(data = {}) {
    const event = 'video add to playlist';
    if (this.userProperties) {
      this.amplitude.logEvent(event, { ...this.userProperties, ...data });
    }
  }

  sendPlaylistCreatedEvent(data = {}) {
    const event = 'playlist created';
    if (this.userProperties) {
      this.amplitude.logEvent(event, { ...this.userProperties, ...data });
    }
  }

  sendClickedScheduleEvent(data = {}) {
    const event = 'clicked on schedule';
    if (this.userProperties) {
      this.amplitude.logEvent(event, { ...this.userProperties, ...data });
    }
  }

  sendClickedShareVideoEvent(data = {}) {
    const event = 'clicked on share video';
    if (this.userProperties) {
      this.amplitude.logEvent(event, { ...this.userProperties, ...data });
    }
  }

  sendClickedSharePlaylistEvent(data = {}) {
    const event = 'clicked on share playlist';
    if (this.userProperties) {
      this.amplitude.logEvent(event, { ...this.userProperties, ...data });
    }
  }

  sendRecommendationsEditedEvent() {
    const event = 'recommendations edited';
    if (this.userProperties) {
      this.amplitude.logEvent(event, { ...this.userProperties });
    }
  }

  sendUpgradedPlanEvent(data = {}) {
    const event = 'upgraded to a paying plan';
    if (this.userProperties) {
      this.amplitude.logEvent(event, { ...this.userProperties, ...data });
    }
  }
  sendSubscriptionCancelledEvent(data = {}) {
    const event = 'subscription canceled';
    if (this.userProperties) {
      this.amplitude.logEvent(event, { ...this.userProperties, ...data });
    }
  }
  sendSearchEvent(data = {}) {
    const event = 'search submitted';
    if (this.userProperties) {
      this.amplitude.logEvent(event, { ...this.userProperties, ...data });
    }
  }

  sendJoinedLiveEvent(data = {}) {
    const event = 'joined event';
    if (this.userProperties) {
      this.amplitude.logEvent(event, { ...this.userProperties, ...data });
    }
  }

  sendPaidLiveEvent(data = {}) {
    const event = 'paid event';
    if (this.userProperties) {
      this.amplitude.logEvent(event, { ...this.userProperties, ...data });
    }
  }

  sendLiveEventVoucherPurchase(data = {}) {
    const event = 'Live - Purchased gift';
    if (this.userProperties) {
      this.amplitude.logEvent(event, { ...this.userProperties, ...data });
    }
  }

  sendUsedLiveEventCode(data = {}) {
    const event = 'User - LIVE code used';
    if (this.userProperties) {
      this.amplitude.logEvent(event, { ...this.userProperties, ...data });
    }
  }

  sendClickedLiveRecordingEvent(data = {}) {
    const event = 'clicked on recording URL';
    if (this.userProperties) {
      this.amplitude.logEvent(event, { ...this.userProperties, ...data });
    }
  }

  sendLiveSignupEvent(data = {}) {
    const event = 'signed up from live';
    if (this.userProperties) {
      this.amplitude.logEvent(event, { ...this.userProperties, ...data });
    }
  }

  sendProgramAccessGrantedEvent({
    title,
    slug,
    themes,
    duration,
    access_code,
  }) {
    const event = 'Program - access granted';
    if (this.userProperties) {
      this.amplitude.logEvent(event, {
        ...this.userProperties,
        program_title: title,
        program_slug: slug,
        program_theme: themes,
        program_duration: duration,
        access_code,
      });
    }
  }

  sendChallengeAccessGrantedEvent({ title, themes, duration, access_code }) {
    const event = 'Challenge - access granted';
    if (this.userProperties) {
      this.amplitude.logEvent(event, {
        ...this.userProperties,
        program_title: title,
        program_theme: themes,
        program_duration: duration,
        access_code,
      });
    }
  }

  sendUpgradedFromProgramPageEvent({ title, subscription }) {
    const event = 'Program - upgraded from program page';
    if (this.userProperties) {
      this.amplitude.logEvent(event, {
        ...this.userProperties,
        program_title: title,
        subscription,
      });
    }
  }

  sendSignupFromProgramPageEvent({ title }) {
    const event = 'Program - signed up from program page';
    this.amplitude.logEvent(event, {
      program_title: title,
    });
  }

  sendProgramCompletionEvent({ title }) {
    const event = 'Program - completed 100%';
    if (this.userProperties) {
      this.amplitude.logEvent(event, {
        ...this.userProperties,
        program_title: title,
      });
    }
  }

  sendProgramPurchasedGiftEvent({ title, amount, currency, slug }) {
    const event = 'Program - Purchased gift';
    if (this.userProperties) {
      this.amplitude.logEvent(event, {
        ...this.userProperties,
        program_title: title,
        amount,
        slug,
        currency,
      });
    }
  }

  sendProgramPurchaseEvent({
    title,
    slug,
    amount,
    discountedAmount,
    category,
  }) {
    let event = 'Program - Purchase';
    if (category === ProgramCategory['Courses']) {
      event = 'Course - Purchase';
    }
    if (this.userProperties) {
      this.amplitude.logEvent(event, {
        ...this.userProperties,
        program_title: title,
        discountedAmount,
        amount,
        slug,
      });
    }
  }

  sendCoursePurchasedGiftEvent({ title, amount, currency, slug }) {
    const event = 'Course - Purchased gift';
    if (this.userProperties) {
      this.amplitude.logEvent(event, {
        ...this.userProperties,
        program_title: title,
        amount,
        slug,
        currency,
      });
    }
  }

  sendProgramGiftActivatedEvent({
    title,
    amount,
    currency,
    slug,
    access_code,
  }) {
    const event = 'Program - Gift activated';
    if (this.userProperties) {
      this.amplitude.logEvent(event, {
        ...this.userProperties,
        program_title: title,
        amount,
        currency,
        slug,
        access_code,
      });
    }
  }

  sendCourseGiftActivatedEvent({ title, amount, currency, slug, access_code }) {
    const event = 'Course - Gift activated';
    if (this.userProperties) {
      this.amplitude.logEvent(event, {
        ...this.userProperties,
        program_title: title,
        amount,
        slug,
        currency,
        access_code,
      });
    }
  }

  sendProgramVideoCompletionEvent({
    programTitle,
    videoTitle,
    weekTitle,
    videoSlug,
  }) {
    const event = 'Program - video completed';
    if (this.userProperties) {
      this.amplitude.logEvent(event, {
        ...this.userProperties,
        program_title: programTitle,
        video_title: videoTitle,
        week_title: weekTitle,
        video_slug: videoSlug,
      });
    }
  }

  sendSurveyStartedEvent({ title, program_title, program_slug, part_slug }) {
    const event = 'Program - survey started';
    if (this.userProperties) {
      this.amplitude.logEvent(event, {
        ...this.userProperties,
        title,
        program_title,
        program_slug,
        part_slug,
      });
    }
  }

  sendSurveyCompletedEvent({ title, program_title, program_slug, part_slug }) {
    const event = 'Program - survey completed';
    if (this.userProperties) {
      this.amplitude.logEvent(event, {
        ...this.userProperties,
        title,
        program_title,
        program_slug,
        part_slug,
      });
    }
  }

  sendRegisterEvent({ source }) {
    const event = 'Register';
    this.amplitude.logEvent(event, {
      source,
    });
  }

  sendRegisterCodeUser({ code, type, discount, access_time }) {
    const event = 'Registration - Step 2: Code used';
    this.amplitude.logEvent(event, {
      ...(this.userProperties || {}),
      code,
      type,
      discount,
      access_time,
    });
  }

  sendRegisterPlayTrialStarted(data: {
    plan: string;
    amount: number;
    currency: string;
  }) {
    const event = 'Registration - Play Trial Started';
    this.amplitude.logEvent(event, {
      ...data,
      ...(this.userProperties || {}),
    });
  }

  sendRegisterPlaySubscriptionStarted(data: {
    plan: string;
    amount: number;
    currency: string;
    ab_test: string;
  }) {
    const event = 'Registration - Play Subscription Started';
    this.amplitude.logEvent(event, {
      ...(this.userProperties || {}),
      ...data,
    });
  }

  sendRegisterFreeTrialSelected() {
    const event = 'Registration - Step 2: Free Trial Selected';
    this.amplitude.logEvent(event, {
      ...(this.userProperties || {}),
    });
  }

  sendRegisterFreePlanSelected() {
    const event = 'Registration - Step 2: Free Plan Selected';
    this.amplitude.logEvent(event, {
      ...(this.userProperties || {}),
    });
  }

  sendRegisterInstantSubscriptionSelected() {
    const event = 'Registration - Step 2: Instant Subscription Selected';
    this.amplitude.logEvent(event, {
      ...(this.userProperties || {}),
    });
  }

  sendRegisterWellnessTrialStarted() {
    const event = 'Registration - Wellness trial started';
    this.amplitude.logEvent(event, {
      ...(this.userProperties || {}),
    });
  }

  sendRegisterTrialPlanSelected({ offer_name, plan, amount, currency }) {
    const event = 'Registration - Step 2: Plan selected';
    this.amplitude.logEvent(event, {
      ...(this.userProperties || {}),
      offer_name,
      plan,
      amount: amount + currency,
    });
  }

  sendRegisterPaymentMethodAdded({ method_type }) {
    const event = 'Registration - Step 2: Payment method added';
    this.amplitude.logEvent(event, {
      ...(this.userProperties || {}),
      method_type,
    });
  }

  sendInstantTrialSubscriptionPayment({
    plan,
    payment_type,
    amount,
    currency,
  }) {
    const event = 'Registration - Instant trial subscription payment done';
    this.amplitude.logEvent(event, {
      ...(this.userProperties || {}),
      plan,
      payment_type,
      amount: amount + currency,
    });
  }

  //
  sendProfileCompletedEvent({ funnel }) {
    const event = 'Register - Profile details completed';
    this.amplitude.logEvent(event, {
      ...(this.userProperties || {}),
      funnel,
    });
  }

  //
  sendSearchTerm({ keyword, category }) {
    const event = 'Search - User searched term';
    this.amplitude.logEvent(event, {
      ...(this.userProperties || {}),
      keyword,
      category,
    });
  }

  sendSearchViewAll({ category }) {
    const event = 'Search - User clicked view all';
    this.amplitude.logEvent(event, {
      ...(this.userProperties || {}),
      category,
    });
  }

  sendSearchClickedTab({ tab }) {
    const event = 'Search - User selected tab';
    this.amplitude.logEvent(event, {
      ...(this.userProperties || {}),
      tab,
    });
  }

  sendSearchClickedSuggestion({ searchQuery, suggestion }) {
    const event = 'Search - User clicked suggestion';
    this.amplitude.logEvent(event, {
      ...(this.userProperties || {}),
      keyword: searchQuery,
      suggestion,
    });
  }

  sendSearchClickedCorrection({ keyword, correction }) {
    const event = 'Search - User clicked correction';
    this.amplitude.logEvent(event, {
      ...(this.userProperties || {}),
      keyword,
      correction,
    });
  }

  sendJoinedBundleWaitList({ name, slug, client_name, category }) {
    const event = 'Bundle - Joined Waitlist';
    this.amplitude.logEvent(event, {
      ...(this.userProperties || {}),
      name,
      slug,
      client_name,
      category,
    });
  }

  sendJoinedBundle({ name, slug, client_name, code, category }) {
    const event = 'Bundle - Joined';
    this.amplitude.logEvent(event, {
      ...(this.userProperties || {}),
      name,
      slug,
      client_name,
      code,
      category,
    });
  }

  sendTrialReclaimed({ source }) {
    const event = 'User - reclaimed trial';
    this.amplitude.logEvent(event, {
      ...(this.userProperties || {}),
      source,
    });
  }

  sendTrialRejected() {
    const event = 'User - rejected trial reclaim';
    this.amplitude.logEvent(event, {
      ...(this.userProperties || {}),
    });
  }

  sendPlayOverviewClicked({ id, type, title, slug }) {
    const event = 'Play Overview - Content clicked';
    this.amplitude.logEvent(event, {
      ...(this.userProperties || {}),
      id,
      slug,
      title,
      type,
    });
  }

  HardChurnCancelationStarted({ plan_type }) {
    const event = 'Hard churn - Cancelation started';
    this.amplitude.logEvent(event, {
      ...(this.userProperties || {}),
      plan_type,
    });
  }

  HardChurnReasonSelected({ plan_type, reason }) {
    const event = 'Hard churn - Reason selected';
    this.amplitude.logEvent(event, {
      ...(this.userProperties || {}),
      plan_type,
      reason,
    });
  }

  HardChurnUpsellOfferTaken({
    plan_name,
    plan_type,
    plan_amount,
    plan_currency,
  }) {
    const event = 'Hard churn - Upsell offer taken';
    this.amplitude.logEvent(event, {
      ...(this.userProperties || {}),
      plan_name,
      plan_type,
      plan_amount,
      plan_currency,
    });
  }

  HardChurnUpsellOfferDismissed({
    plan_name,
    plan_type,
    plan_amount,
    plan_currency,
  }) {
    const event = 'Hard churn - Upsell offer dismissed';
    this.amplitude.logEvent(event, {
      ...(this.userProperties || {}),
      plan_name,
      plan_type,
      plan_amount,
      plan_currency,
    });
  }

  HardChurnCancelationConfirmed({ plan_name }) {
    const event = 'Hard churn - Cancelation confirmed';
    this.amplitude.logEvent(event, {
      ...(this.userProperties || {}),
      plan_name,
    });
  }
}

export function getAmplitude(userProperties = null): AmplitudeManager {
  if (manager && manager.userProperties) {
    return manager;
  } else {
    manager = new AmplitudeManager(userProperties);
    return manager;
  }
}
