import * as React from 'react';

function SvgMonitorCheckIcon(props) {
  return (
    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fillRule="evenodd">
        <path d="M16 4v2H4v16h24v-6h2v6a2 2 0 01-2 2h-8v4h4v2H8v-2h4v-4H4a2 2 0 01-2-2V6a2 2 0 012-2h12zm2 20h-4v4h4v-4zM28.41 4.5L30 6.08 21 15l-5-4.96 1.59-1.57L21 11.85l7.41-7.35z" />
      </g>
    </svg>
  );
}

export default SvgMonitorCheckIcon;
