import * as React from 'react';

function SvgPulseIcon(props) {
  return (
    <svg width={14} height={14} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5 13.5a.5.5 0 01-.46-.31L2.165 7.5H0v-1h2.5a.5.5 0 01.46.31L5 11.64 9.03.825A.5.5 0 019.5.5a.5.5 0 01.465.34L11.86 6.5H14v1h-2.5a.5.5 0 01-.475-.34L9.5 2.5 5.47 13.175A.5.5 0 015 13.5z"
        fill="#243240"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgPulseIcon;
