import * as React from 'react';

function SvgPlaylistIcon(props) {
  return (
    <svg viewBox="0 0 12 11" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 0h9v1H0V0zm0 3h9v1H0V3zm0 3h6v1H0V6zm8.5 0L12 8.5 8.5 11V6z"
        fill="#000"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgPlaylistIcon;
