import React from 'react';
import Styled from 'styled-components';
import * as Layout from '@ecosystems/layout.register';

const RightContainer = Styled(Layout.RightContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.neutrals.white};
`;

const LeftContainer = Styled(Layout.LeftContainer)`
  display: flex;
  justify-content: center;
`;

const LeftContainerComponent = ({ className = '', children }) => {
  return <LeftContainer className={className}>{children}</LeftContainer>;
};

const RightContainerComponent = ({ className = '', children }) => {
  return <RightContainer className={className}>{children}</RightContainer>;
};

export default {
  ...Layout,
  RightContainer: RightContainerComponent,
  LeftContainer: LeftContainerComponent,
};
