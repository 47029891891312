const PaymentMethodPolicy = {
  typePolicies: {
    PaymentMethod: {
      fields: {
        variant: {
          read(variant, { readField }) {
            const provider = readField('provider');
            if (provider === 'trustly') {
              // on trustly the variant contains the bank name, we dont need that
              return 'trustly';
            } else {
              return variant;
            }
          },
        },
      },
    },
  },
};

export default PaymentMethodPolicy;
