import LanguageContext from '@lib/contexts/languageContext';
import { formatDuration as formatDurationFn } from 'date-fns/formatDuration';
import { sv, nb, fi, enGB } from 'date-fns/locale';
import { useCallback, useContext } from 'react';

const localeMap = {
  en: enGB,
  se: sv,
  no: nb,
  fi,
};

type FormationDurationOptions = Exclude<
  Parameters<typeof formatDurationFn>[1],
  'locale'
> & {
  onlyNumeric?: boolean;
};

// date-fns/formatDuration doesnt have a 'onlyNumeric' option for formatDistance control
// so we overwrite locale.formatDistance ourselfs
const formatDuration = (
  duration: Parameters<typeof formatDurationFn>[0],
  options: FormationDurationOptions = {},
  lang: string
) => {
  const locale = localeMap[lang as keyof typeof localeMap];

  const { format, zero, delimiter } = options;
  const formatted = formatDurationFn(duration, {
    format,
    zero,
    delimiter,
    locale,
  });

  return formatted;

  // disable until date-fns is fixed
  // date-fns v3 remoted numericOnly option
  const value = Object.values(duration)[0];
  return `${value} ${formatted.split(' ').at(-1)}`;
};

export const useFormatDuration = () => {
  const lang = useContext(LanguageContext);

  const _formatDuration = useCallback(
    (
      duration: Parameters<typeof formatDurationFn>[0],
      options: FormationDurationOptions = {}
    ) => {
      return formatDuration(duration, options, lang);
    },
    [lang]
  );

  return _formatDuration;
};
