const PriceBreakdown = {
  typePolicies: {
    PriceBreakdown: {
      fields: {
        accessDays(_, { readField }) {
          //
          const priceBreakdown = readField('priceBreakdown');
          let access_days = 0;
          if (priceBreakdown?.planType === 'yearly') {
            access_days += priceBreakdown?.planCount * 365;
          } else if (priceBreakdown?.planType === 'quarterly') {
            access_days += priceBreakdown?.planCount * 3 * 30;
          } else if (priceBreakdown?.planType === 'monthly') {
            access_days += priceBreakdown?.planCount * 30;
          }
          if (priceBreakdown?.extraDays > 0) {
            access_days += priceBreakdown?.extraDays;
          }

          return access_days;
        },
      },
    },
  },
};

export default PriceBreakdown;
