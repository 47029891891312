import React from 'react';
import Styled from 'styled-components';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { useRouter } from 'next/router';
import { Body } from '@organisms/modals/base';
import Icon from '@components/Icon';
import buttons from '@components/buttons';
import { Flex } from '@components/layout/Grid';
import { ProgramCategory } from '@gql/generated';

const Buttons = Styled(buttons)`
  margin: 0 10px;
`;

const Wrapper = Styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const Title = Styled.p`
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`;

const Text = Styled.p`
  font-size: 16px;
  margin: 15px 0 0;
`;

declare type Props = {
  amountCents?: number;
  currency?: string;
  programCategory?: ProgramCategory;
  onClose(): void;
};

export const VoucherPaymentConfirmationMessage = (props: Props) => {
  const router = useRouter();

  const { lang } = router?.query;

  const { amountCents, currency, programCategory } = props;

  return (
    <>
      <Body pb={40} data-testid="VoucherPaymentConfirmationMessage">
        <Wrapper>
          <Icon
            name="ygb-icon-checkmark"
            fontSize="24px"
            color="white"
            style={{
              lineHeight: 1,
              backgroundColor: '#93BDBB',
              borderRadius: '50%',
              padding: '50px',
              margin: '0 auto 20px',
            }}
          />
          <Title>
            <Trans id="metadata.payment.success.title" />
          </Title>
          <Text>
            <Trans
              id="programs_2021.buy_gift.confirmation_modal_text"
              values={{
                paymentAmount: `${Math.floor(amountCents / 100).toFixed(
                  2
                )} ${currency}`,
              }}
            />
          </Text>
        </Wrapper>
        <Flex justifyContent="center" pt={40}>
          {programCategory ? (
            <Buttons
              href={`/${lang}/${programCategory}`}
              appearance="default-blue"
            >
              <Trans
                id={
                  programCategory === ProgramCategory['Courses']
                    ? t`programs_2021.buy_gift.go_courses_btn`
                    : t`programs_2021.buy_gift.go_programs_btn`
                }
              />
            </Buttons>
          ) : null}
          <Buttons href={`/${lang}/me/dashboard`} appearance="outline">
            <Trans id="programs_2021.buy_gift.go_dashboard_btn" />
          </Buttons>
        </Flex>
      </Body>
    </>
  );
};
