import React, { useState } from 'react';
import LoginModal, { LoginModalProps } from '@organisms/modals/LoginModal';

const useLoginModal = (
  props: LoginModalProps = {}
): [
  React.ReactElement,
  { isOpen: boolean; openModal(): void; closeModal(): void }
] => {
  const { open = false, ...otherProps } = props;
  const [isOpen, setOpen] = useState(open);

  const content = (
    <LoginModal
      {...otherProps}
      open={isOpen}
      onClose={() => {
        setOpen(false);
        props.onClose?.();
      }}
    />
  );

  return [
    content,
    {
      isOpen,
      openModal: () => setOpen(true),
      closeModal: () => setOpen(false),
    },
  ];
};

export default useLoginModal;
