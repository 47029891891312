import React from 'react';
import styled from 'styled-components';

export const SpinnerEl = styled.span<{ spinnerColor?: string; size: number }>`
  .c-spinner {
    --spinner-duration: 1.4s;
    --spinner-color: ${({ spinnerColor }) =>
      spinnerColor ? spinnerColor : 'var(--spinner-color, #ffffff)'};
    --spinner-size: ${({ size }) => (size ? `${size}px` : '16px')};
    --spinner-width: 2px;

    position: relative;
    box-sizing: border-box;
    display: inline-block;
    height: var(--spinner-size);
    width: var(--spinner-size);
    animation: spinner-reveal var(--spinner-duration) steps(1, end) infinite,
      spinner-rotation var(--spinner-duration) ease-in-out infinite;
    clip-path: inset(0 0 0 50%);
  }
  .c-spinner::before,
  .c-spinner::after {
    box-sizing: border-box;
    content: '';
    width: 100%;
    height: 100%;
    border: var(--spinner-width) solid var(--spinner-color);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    clip-path: inset(0 50% 0 0);
    animation: spinner-rotation-a var(--spinner-duration) linear infinite;
    transition: border-color 0.5s ease-out;
  }

  .c-spinner::after {
    animation-name: spinner-rotation-b;
  }
  .c-spinner--big {
    --spinner-size: 64px;
  }
  /***
 * Animations
 */
  @keyframes spinner-rotation {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes spinner-rotation-a {
    40%,
    60% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(540deg);
    }
  }
  @keyframes spinner-rotation-b {
    40% {
      transform: rotate(180deg);
    }
    80% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(540deg);
    }
  }
  @keyframes spinner-reveal {
    40% {
      clip-path: inset(0 0 0 0);
    }
    80% {
      clip-path: inset(0 50% 0 0);
    }
  }
`;

declare type Props = {
  className?: string;
  size?: number;
  spinnerColor?: string;
  style?: any;
};

const Spinner = ({
  className,
  size,
  spinnerColor,
  style,
  ...otherProps
}: Props) => (
  <SpinnerEl
    className={className}
    size={size}
    style={style}
    spinnerColor={spinnerColor}
    {...otherProps}
  >
    <span className="c-spinner" />
  </SpinnerEl>
);

export default Spinner;
