import React from 'react';
import styled from 'styled-components';
import { Box } from '@components/layout/Grid';
import Icon from '@components/Icon';

const CloseButton = styled.button`
  border: none;
  padding: 0;
  margin: 0 0 0 auto;
  background: none;
  cursor: pointer;
`;

const ModalHeader = styled(Box)`
  display: flex;

  @media (max-width: ${(props) => `${props.theme.viewports.tablet + 1}px`}) {
    width: 100%;
    background-color: ${({ backgroundColor }) =>
      backgroundColor ? backgroundColor : 'white'};
    z-index: 11;
    box-shadow: 0 2px 2px 0
      ${(props) => props.theme.colors.neutrals['white-darkest']};

    h4 {
      /* cancel all H4 margins, header has its own padding */
      margin: 0;
    }
  }
`;

type Props = {
  className?: string;
  sticky?: boolean;
  handleClose?(): void;
  closeButton?: React.ReactNode;
  backgroundColor?: string;
  closeIconColor?: string;
  noCloseButton?: boolean;
  children?: React.ReactNode;
};
const Header = (props: Props) => {
  return (
    <ModalHeader
      px={[25, 25, 30]}
      py={[25, 25, 30]}
      backgroundColor={props.backgroundColor}
      sticky={props.sticky}
      className={props.className}
    >
      {props.children}
      {!props.noCloseButton && (
        <CloseButton type="button">
          {props.closeButton ? (
            props.closeButton
          ) : (
            <Icon
              name="ygb-icon-close"
              color={props.closeIconColor ? props.closeIconColor : null}
              fontSize="15px"
              onClick={() => props?.handleClose?.()}
            />
          )}
        </CloseButton>
      )}
    </ModalHeader>
  );
};

export default Header;
