import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/react';
import { Box } from '@components/layout/Grid';
import { PaymentMethod as paymentMethod } from './PaymentMethod';
import { PaymentMethod as PaymentMethodType } from '@gql/generated';
import { Modal, Body, Header } from '../base';
import { Button } from '@components/ButtonV2';
import Icon from '@components/Icon';
import Spinner from '@components/Spinner';

const Loading = styled.p`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutrals.gray};
  text-align: center;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  cursor: progress;
`;

const Title = styled.p`
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  margin-top: 0;
  margin-bottom: 20px;
`;

const PaymentMethod = styled(paymentMethod)<{
  selected?: boolean;
  expired?: boolean;
}>`
  border: 1px solid ${({ selected }) => (selected ? '#129edc' : '#d8d8d8')};
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: border-color 0.2s ease-in-out;

  ${({ expired }) =>
      expired
        ? `
            opacity: 0.5;
            pointer-events: none;
            user-select: none;
          `
        : ''}
    :hover {
    border-color: #129edc;
  }
`;

const AddNewMethod = styled.button`
  width: 100%;
  color: #129edc;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding: 15px 20px;
  background-color: white;
  cursor: pointer;
  transition: border-color 0.2s ease-in-out;

  :hover {
    border-color: currentColor;
  }
`;

const PayButton = styled(Button)`
  width: 100%;
  justify-content: center;
  max-width: 520px;
`;

const _Body = styled(Body)`
  overflow-y: auto;
  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    margin-top: -20px;
  }
`;

const InlineContainer = styled.div`
  width: 100%;
  padding: 0;

  ${PayButton} {
    width: 100%;
    max-width: none;
  }

  ${_Body} {
    padding: 0;
    margin: 0;
  }
`;

type Props = {
  amount: number;
  currency: string;
  paymentMethods: PaymentMethodType[];
  onContinueToPayment(item?: PaymentMethodType): void;
  onClose(): void;
  disaplyAs?: 'modal' | 'inline';
  chooseMethodButtonText?: React.ReactNode;
  useOtherMethodButtonText?: React.ReactNode;
};

export const ChoosePaymentMethodModal = (props: Props) => {
  const {
    amount,
    currency,
    paymentMethods,
    onContinueToPayment,
    onClose,
    disaplyAs = 'modal',
    chooseMethodButtonText,
    useOtherMethodButtonText,
  } = props;
  const [loading, setLoading] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState(null);

  const continueToPayment = selected => {
    setLoading(true);
    onContinueToPayment(selected);
  };

  useEffect(() => {
    const defaultMethod = paymentMethods.find(item => item.isDefault);
    if (defaultMethod) {
      setSelectedMethod(defaultMethod);
    } else if (paymentMethods?.length > 0) {
      setSelectedMethod(paymentMethods[0]);
    }
  }, []);

  const Container = disaplyAs === 'modal' ? Modal : InlineContainer;

  return (
    <Container open>
      {disaplyAs === 'modal' && <Header handleClose={onClose} />}
      <_Body pb={40} data-testid="ChoosePaymentMethodModal">
        {loading ? (
          <Loading data-testid="ChoosePaymentMethodModal--Loading">
            <Spinner size={28} />
          </Loading>
        ) : null}
        <Box>
          <Box mb={20}>
            <Title>
              <Trans id="payment_methods_modal.choose_stored_method_label" />
            </Title>
            {paymentMethods
              .sort((a, b) => {
                if (a.status === 'expired' && b.status !== 'expired') {
                  return 1;
                } else {
                  return -1;
                }
              })
              ?.map(item => {
                return (
                  <PaymentMethod
                    key={item.id}
                    item={item}
                    onClick={() => setSelectedMethod(item)}
                    selected={
                      item.status !== 'expired' &&
                      item.id === selectedMethod?.id
                    }
                    expired={item.status === 'expired'}
                  />
                );
              })}

            <PayButton
              onClick={() => continueToPayment(selectedMethod)}
              disabled={!selectedMethod}
            >
              {chooseMethodButtonText ?? (
                <>
                  <Icon name="ygb-icon-programlock-ic" fontSize="14px" /> &nbsp;
                  <Trans id="metadata.pay" /> &nbsp;
                  {amount} {currency}
                </>
              )}
            </PayButton>
          </Box>
          <Box mt={40}>
            <Title>
              <Trans id="payment_methods_modal.add_method_label" />
            </Title>
            <AddNewMethod onClick={() => continueToPayment(null)}>
              {useOtherMethodButtonText ?? (
                <Trans id="payment_method_modal.add_new_method" />
              )}
            </AddNewMethod>
          </Box>
        </Box>
      </_Body>
    </Container>
  );
};
