import * as React from 'react';

const SvgExploreIcon = props => (
  <svg viewBox="0 0 14 15" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5 10H1V1.5h9v4h1v-4a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1V10a1 1 0 0 0 1 1h4v-1Zm9 3.79-1.555-1.555a3 3 0 1 0-.71.71L13.29 14.5l.71-.71Zm-4-1.29a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z"
      fill="#000"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgExploreIcon;
