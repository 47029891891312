import * as Sentry from '@sentry/nextjs';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      traceXHR: true,
      traceFetch: true,
    }),
  ],
  ignoreErrors: [
    '[Graphql error] User with this email is not found',
    // android devices issue
    'ResizeObserver loop limit exceeded',
    // known issue in windows, can be fixed from settings
    'Not in fullscreen mode',
    // error caused by appleBot, no trace stack to find it & its consuming sentry quota for no reason
    'Non-Error exception captured with keys: defaultPrevented, fixed_',
  ],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.1,
  // beforeBreadcrumb(breadcrumb, hint) {
  //   if (
  //     breadcrumb.category === 'fetch' &&
  //     (String(breadcrumb.data?.url) === location.origin + '/api' ||
  //       // for prod until we deploy auth
  //       String(breadcrumb.data?.url).endsWith('yogobe.com/graphql'))
  //   ) {
  //     const body = JSON.parse(hint.input[1].body);
  //     console.log({ body });
  //     breadcrumb.data.operationName = body.operationName;
  //   }
  //   return breadcrumb;
  // },
  environment: String(process.env.DOMAIN).startsWith('https://yogobe.com')
    ? 'production'
    : 'development',
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
