import { normalizeImagePath } from '@lib/helpers';

const InstructorAssetsPolicy = {
  typePolicies: {
    InstructorAssets: {
      // for some reason read functions on fields is not called & its caused next/image to throw..
      merge(existing, incoming) {
        const fields = ['externalImage', 'squareImage', 'titleImage'];

        const data = {
          ...incoming,
        };
        fields.forEach(field => {
          if (data[field]) {
            data[field] = normalizeImagePath(data[field]);
          }
        });

        return { ...existing, ...data };
      },
    },
  },
};

export default InstructorAssetsPolicy;
