import { VIDEO_NOT_FOUND_KEY } from '@lib/constants';
import { normalizeImagePath } from '@lib/helpers';

const PlaylistPolicy = {
  typePolicies: {
    Playlist: {
      fields: {
        videos: {
          read(videos, { readField }) {
            return (videos || []).filter(ref => {
              const identifier = readField('identifier', ref);
              return identifier !== VIDEO_NOT_FOUND_KEY;
            });
          },
        },
        thumbnailUrl: {
          merge(_, incoming) {
            return normalizeImagePath(incoming);
          },
        },
      },
    },
  },
};

export default PlaylistPolicy;
