import * as React from 'react';

function SvgTimeoutClockIcon(props) {
  return (
    <svg width={23} height={26} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 9h2v9h-2V9zM8 0h6v2H8V0zm15 7l-1.42-1.41-2.25 2.25a10.94 10.94 0 101.18 1.65L23 7zM11 24a9 9 0 110-18 9 9 0 010 18z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgTimeoutClockIcon;
