import { normalizeImagePath } from '@lib/helpers';

const videoAssetsPolicy = {
  typePolicies: {
    VideoAssets: {
      fields: {
        thumbnailUrl: {
          merge(_, incoming) {
            return normalizeImagePath(incoming);
          },
        },
        thumbnailUrlMedium: {
          merge(_, incoming) {
            return normalizeImagePath(incoming);
          },
        },
      },
    },
  },
};

export default videoAssetsPolicy;
