import React, { useState } from 'react';
import Styled from 'styled-components';
import { format } from 'date-fns/format';
import { useRouter } from 'next/router';
import { Trans } from '@lingui/react';
import once from 'lodash/once';
import { Flex } from '@components/layout/Grid';
import { Modal, Header } from '@organisms/modals/base';
import PaymentConfirmationMessage from './SubscriptionPaymentConfirmation';
import { GenericPaymentFailedMessage } from './GenericPaymentFailedMessage';
import { ProgramPaymentConfirmationMessage } from './ProgramPaymentConfirmationMessage';
import { SubscriptionPaymentFailedMessage } from './SubscriptionPaymentFailedMessage';
import Spinner from '@components/Spinner';
import { hideURLParams } from '@lib/helpers';
import AnalyticsManager from '@lib/analytics/manager';
import { PAYMENT_CONFIRMATION_ACTION } from '@lib/constants';
import { VoucherPaymentConfirmationMessage } from './VoucherPaymentConfirmationModal';
import PaymentPendingMessage from './PaymentPending';
import { EpassiFailedPaymentModal } from './EpassiFailedPaymentModal';
import { WellnessPackagePaymentConfirmation } from './WellnessPackagePaymentConfirmation';
import { WellnessPackageFailed } from './WellnessPackageFailed';
import {
  OrderQuery,
  OrderStatus,
  PaymentProviderVariantKind,
  PaymentStatusKind,
  useOrderQuery,
} from '@gql/generated';
import { BundlePaymentConfirmationMessage } from './BundlePaymentConfirmationMessage';
import { BundlePaymentFailedMessage } from './BundlePaymentFailedMessage';
import useFlags from '@lib/hooks/useFlags';

const StyledModal = Styled(Modal)`
  overflow-y: scroll;

  .MuiBackdrop-root {
    display: none;
  }

  .MuiDialog-paperScrollPaper {
    max-height: none;
  }

  .MuiDialog-container.MuiDialog-scrollPaper:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    /* bottom: 0; */
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

declare type Props = {
  open?: boolean;
  orderId: string;
  onClose?(): void;
};

const trackEvents = once((order: OrderQuery['order']) => {
  const orderId = order?.orderId;
  //
  if (order?.__typename === 'WellnessPackagePayment') {
    if (order.wellnessPackage.packageable?.__typename === 'WellnessAccess') {
      if (
        order?.wellnessPackage?.priceBreakdown?.__typename === 'PriceBreakdown'
      ) {
        if (
          [OrderStatus.Completed, OrderStatus.Pending].includes(order?.status)
        ) {
          AnalyticsManager().sendPurchaseCustomAccessCompleteEvent({
            order_id: order.orderId,
            name: order?.wellnessPackage.name,
            value: Math.floor(order?.amountCents / 100),
            original_amount: Math.floor(order?.originalAmountCents / 100),
            currency: order?.amountCurrency,
            access_days: order?.wellnessPackage?.priceBreakdown?.accessDays,
            payment_type: order?.paymentProvider,
            wellness: true,
          });
        } else {
          AnalyticsManager().sendPurchaseCustomAccessFailEvent({
            order_id: order.orderId,
            name: order?.wellnessPackage.name,
            value: Math.floor(order?.amountCents / 100),
            original_amount: Math.floor(order?.originalAmountCents / 100),
            currency: order?.amountCurrency,
            access_days: order?.wellnessPackage?.priceBreakdown?.accessDays,
            payment_type: order?.paymentProvider,
            wellness: true,
          });
        }
      } else {
        const access_days = order?.wellnessPackage?.packageable?.periodValue;
        if (
          [OrderStatus.Completed, OrderStatus.Pending].includes(order?.status)
        ) {
          AnalyticsManager().sendWellnessAccessPurchaseCompleteEvent({
            order_id: order.orderId,
            access_days,
            name: order?.wellnessPackage.name,
            value: Math.floor(order?.amountCents / 100),
            original_amount: Math.floor(order?.originalAmountCents / 100),
            currency: order?.amountCurrency,
            payment_type: order?.paymentProvider,
            wellness: true,
          });
        } else {
          AnalyticsManager().sendWellnessAccessPurchaseCompleteEvent({
            order_id: order.orderId,
            access_days,
            name: order?.wellnessPackage.name,
            value: Math.floor(order?.amountCents / 100),
            original_amount: Math.floor(order?.originalAmountCents / 100),
            currency: order?.amountCurrency,
            payment_type: order?.paymentProvider,
            wellness: true,
          });
        }
      }
    } else if (
      order?.wellnessPackage?.packageable?.__typename === 'Promotion'
    ) {
      if (
        [OrderStatus.Completed, OrderStatus.Pending].includes(order?.status)
      ) {
        AnalyticsManager().sendPurchaseLivePackageCompleteEvent({
          order_id: order.orderId,
          name: order?.wellnessPackage?.name,
          value: Math.floor(order?.amountCents / 100),
          original_amount: Math.floor(order?.originalAmountCents / 100),
          currency: order?.amountCurrency,
          payment_type: order?.paymentProvider,
          wellness: true,
        });
      } else {
        AnalyticsManager().sendPurchaseLivePackageFailEvent({
          order_id: order.orderId,
          name: order?.wellnessPackage?.name,
          value: Math.floor(order?.amountCents / 100),
          original_amount: Math.floor(order?.originalAmountCents / 100),
          currency: order?.amountCurrency,
          payment_type: order?.paymentProvider,
          wellness: true,
        });
      }
    }
  } else if (order?.__typename === 'SubscriptionPayment') {
    const extraFields = {
      wellness: null,
      wellness_provider: '',
    };
    if (order?.sourcePage === 'wellness') {
      extraFields['wellness'] = true;
      extraFields['wellness_provider'] = 'yogobe';
      if (order?.paymentProvider === 'epassi') {
        extraFields['wellness_provider'] = 'epassi';
      }
    }
    if (
      order?.status === OrderStatus.Completed ||
      order?.status === OrderStatus.Pending
    ) {
      AnalyticsManager().sendSubscriptionPurchaseCompleteEvent({
        order_id: order.orderId,
        months: order.months,
        value: Math.floor(order?.amountCents / 100),
        original_amount: Math.floor(order?.originalAmountCents / 100),
        currency: order?.amountCurrency,
        payment_type: order?.paymentProvider,
        source_page: order?.sourcePage,
        wellness: extraFields['wellness'],
        wellness_provider: extraFields['wellness_provider'],
      });
    } else {
      AnalyticsManager().SendSubscriptionPurchaseFailEvent({
        order_id: order.orderId,
        months: order.months,
        value: Math.floor(order?.amountCents / 100),
        original_amount: Math.floor(order?.originalAmountCents / 100),
        currency: order?.amountCurrency,
        payment_type: order?.paymentProvider,
        sourcePage: order?.sourcePage,
        wellness: extraFields['wellness'],
        wellness_provider: extraFields['wellness_provider'],
      });
    }
  } else if (order?.__typename === 'BundlePayment') {
    if (
      order?.status === OrderStatus.Completed ||
      order?.status === OrderStatus.Pending
    ) {
      AnalyticsManager().sendBundlePurchaseCompleteEvent({
        orderId: order.orderId,
        name: order?.bundle?.title,
        id: order?.bundle?.id,
        value: Math.floor(order?.amountCents / 100),
        original_amount: Math.floor(order?.originalAmountCents / 100),
        currency: order?.amountCurrency,
        payment_type: order?.paymentProvider,
        sourcePage: order?.sourcePage,
      });
    } else {
      AnalyticsManager().SendBundlePurchaseFailEvent({
        orderId: order.orderId,
        name: order?.bundle?.title,
        id: order?.bundle?.id,
        value: Math.floor(order?.amountCents / 100),
        original_amount: Math.floor(order?.originalAmountCents / 100),
        currency: order?.amountCurrency,
        payment_type: order?.paymentProvider,
        sourcePage: order?.sourcePage,
      });
    }
  } else if (order?.__typename === 'GenericProgramPayment') {
    if (order?.status === OrderStatus.Completed) {
      AnalyticsManager().sendProgramAccessGrantedEvent({
        id: order?.program?.id,
        title: order?.program?.title,
        slug: order?.program?.slug,
        category: order?.program?.category,
      });
      AnalyticsManager().sendCoursePaymentCompleteEvent({
        order_id: orderId,
        item_type: order?.program?.category,
        id: order?.program?.id,
        title: order?.program?.title,
        slug: order?.program?.slug,
        value: Math.floor(order?.amountCents / 100),
        original_amount: Math.floor(order?.originalAmountCents / 100),
        currency: order?.amountCurrency,
        payment_type: order?.paymentProvider,
        voucher_purchase: order?.voucher,
      });
    } else {
      AnalyticsManager().sendCoursePaymentFailedEvent({
        order_id: orderId,
        item_type: order?.program?.category,
        id: order?.program?.id,
        title: order?.program?.title,
        slug: order?.program?.slug,
        value: Math.floor(order?.amountCents / 100),
        original_amount: Math.floor(order?.originalAmountCents / 100),
        currency: order?.amountCurrency,
        payment_type: order?.paymentProvider,
        voucher_purchase: order?.voucher,
      });
    }
  }
});

const PaymentConfirmationModal = (props: Props) => {
  const { open = false, orderId } = props;
  const [isOpen, setIsOpen] = useState(open);
  const router = useRouter();
  const [flags] = useFlags();
  const { data, loading, refetch: refreshOrder } = useOrderQuery({
    onCompleted(resp) {
      if (resp.order?.orderId) {
        trackEvents(resp?.order);
      }
    },
    variables: {
      id: orderId,
    },
  });

  if (!isOpen || !data?.order?.orderId) {
    return null;
  }

  let onClose = async () => {
    setIsOpen(false);
    await hideURLParams(PAYMENT_CONFIRMATION_ACTION);
    props?.onClose?.();
  };

  let content = null;
  const order = data?.order;
  const { lang } = router?.query;

  if (
    flags.enable_epassi_pending_modal &&
    order?.paymentProvider === 'epassi' &&
    order?.status === 'pending'
  ) {
    content = (
      <PaymentPendingMessage
        title={<Trans id="payment_subscription_pending.modal.title" />}
        body={<Trans id="payment_confirmation_modal.epassi_pending_message" />}
        onContinue={onClose}
      />
    );
  } else if (
    order?.paymentProvider === 'epassi' &&
    [
      PaymentStatusKind.Rejected,
      PaymentStatusKind.Failed,
      PaymentStatusKind.Timeout,
    ].includes(order.state)
  ) {
    return (
      <EpassiFailedPaymentModal
        loading={false}
        order={order}
        onClose={onClose}
      />
    );
  } else if (order?.__typename === 'WellnessPackagePayment') {
    if (order?.status === OrderStatus.Pending) {
      if (order?.paymentProvider === 'adyen') {
        content = (
          <PaymentPendingMessage
            title={<Trans id="payment_subscription_pending.modal.title" />}
            body={
              order?.paymentProviderVariant ===
              PaymentProviderVariantKind['Swish'] ? (
                <Trans id="paymentconfirmation_wellness.pending_modal.swish_desc" />
              ) : (
                <Trans id="paymentconfirmation_wellness.pending_modal.desc" />
              )
            }
            onContinue={onClose}
            enableWait={
              order?.paymentProviderVariant ===
              PaymentProviderVariantKind['Swish']
            }
            onRefreshOrder={refreshOrder}
            maxWait={20 * 1000}
            refreshRate={4 * 1000}
          />
        );
      } else {
        content = (
          <PaymentPendingMessage
            title={
              <Trans id="paymentconfirmation_wellness.pending_modal.title" />
            }
            body={
              <Trans id="paymentconfirmation_wellness.pending_modal.desc" />
            }
            onContinue={() => {
              window.location.href = `/${lang}/me/dashboard`;
            }}
            enableWait={true}
            onRefreshOrder={refreshOrder}
            maxWait={20 * 1000}
            refreshRate={4 * 1000}
          />
        );
      }
    } else if (order?.status === OrderStatus.Completed) {
      if (
        order?.wellnessPackage?.packageable?.__typename === 'WellnessAccess'
      ) {
        onClose = async () => {
          setIsOpen(false);
          if (!router.pathname.startsWith('/[lang]/me/')) {
            window.location.href = `/${lang}/me/dashboard`;
          }
          props?.onClose?.();
        };
      }
      content = (
        <WellnessPackagePaymentConfirmation
          wellnessPackage={order?.wellnessPackage}
          amountCents={order?.amountCents}
          currency={order?.amountCurrency}
          onClose={onClose}
        />
      );
    } else {
      content = <WellnessPackageFailed onClose={onClose} />;
    }
  } else if (order?.__typename === 'BundlePayment') {
    if (order?.status === OrderStatus.Pending) {
      content = (
        <PaymentPendingMessage
          title={<Trans id="payment_bundle_pending.modal.title" />}
          body={<Trans id="payment_bundle_pending.modal.body" />}
          enableWait={
            order?.paymentProviderVariant ===
            PaymentProviderVariantKind['Swish']
          }
          onRefreshOrder={refreshOrder}
          refreshRate={5 * 1000}
          maxWait={20 * 1000}
          onContinue={() => {
            window.location.href = `/${lang}/me/dashboard`;
          }}
        />
      );
    } else if (order?.status === OrderStatus.Completed) {
      onClose = async () => {
        setIsOpen(false);
        props?.onClose?.();
      };

      content = (
        <BundlePaymentConfirmationMessage
          currency={order?.amountCurrency}
          amountCents={order?.amountCents}
          onClose={onClose}
        />
      );
    } else if (order?.status === OrderStatus.Failed) {
      content = <BundlePaymentFailedMessage onClose={onClose} />;
    }
  } else if (order?.__typename === 'SubscriptionPayment') {
    if (order?.status === OrderStatus.Pending) {
      content = (
        <PaymentPendingMessage
          title={<Trans id="payment_subscription_pending.modal.title" />}
          body={<Trans id="payment_subscription_pending.modal.body" />}
          onContinue={() => {
            window.location.href = `/${lang}/me/dashboard`;
          }}
        />
      );
    } else if (order?.status === OrderStatus.Completed) {
      onClose = async () => {
        setIsOpen(false);
        if (router.query.to) {
          window.location.href = router.query.to as string;
        } else if (!router.pathname.startsWith('/[lang]/me/')) {
          window.location.href = `/${lang}/me/dashboard`;
        }

        props?.onClose?.();
      };

      content = (
        <PaymentConfirmationMessage
          currency={order?.amountCurrency}
          months={order?.months}
          paymentAmount={Math.floor(order?.amountCents / 100)}
          recurringPaymentAmount={Math.floor(order?.recurringAmountCents / 100)}
          recurringDate={format(new Date(order?.recurringDate), 'MMM dd yyyy')}
          onClose={onClose}
        />
      );
    } else if (order?.status === OrderStatus.Failed) {
      content = <SubscriptionPaymentFailedMessage />;
    }
  } else if (order?.__typename === 'GenericProgramPayment') {
    if (order?.status === OrderStatus.Completed) {
      if (order?.voucher) {
        content = (
          <VoucherPaymentConfirmationMessage
            onClose={onClose}
            amountCents={order?.amountCents}
            currency={order.amountCurrency}
            programCategory={order?.program.category}
          />
        );
      } else {
        content = (
          <ProgramPaymentConfirmationMessage
            amountCents={order?.amountCents}
            currency={order?.amountCurrency}
            onClose={onClose}
          />
        );
      }
    } else if (order?.status === OrderStatus.Pending) {
      content = (
        <PaymentPendingMessage
          title={<Trans id="payment_course_pending.modal.title" />}
          body={
            order?.paymentProviderVariant ===
            PaymentProviderVariantKind['Swish'] ? (
              <Trans id="payment_course_pending.modal.swish_body" />
            ) : (
              <Trans id="payment_course_pending.modal.body" />
            )
          }
          onContinue={onClose}
          // we dont have trustly
          enableWait={true}
          onRefreshOrder={refreshOrder}
          maxWait={20 * 1000}
          refreshRate={4 * 1000}
        />
      );
    } else {
      content = <GenericPaymentFailedMessage onClose={onClose} />;
    }
  }
  // LivePayment is not covered here because live payments have a confirmation page
  // AddPayment only shows a snackBar so not modal here
  else {
    return null;
  }

  return (
    <StyledModal key="modal" open={isOpen} handleClose={onClose}>
      <Header handleClose={onClose} />
      {loading ? (
        <Flex aligContent="center">
          <Spinner size={24} />
        </Flex>
      ) : null}
      {content}
    </StyledModal>
  );
};

export default PaymentConfirmationModal;
