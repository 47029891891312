import { normalizeImagePath } from '@lib/helpers';

const PlaylistAssets = {
  typePolicies: {
    PlaylistAssets: {
      fields: {
        thumbnailUrl: {
          merge(_, incoming) {
            return normalizeImagePath(incoming);
          },
        },
      },
    },
  },
};

export default PlaylistAssets;
