import * as React from 'react';

function SvgInfoOutlineIcon(props) {
  return (
    <svg
      width={14}
      height={14}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <path
        d="M6 10h1.333V6H6v4zm.667-10A6.67 6.67 0 000 6.667a6.67 6.67 0 006.667 6.666 6.67 6.67 0 006.666-6.666A6.67 6.67 0 006.667 0zm0 12a5.34 5.34 0 01-5.334-5.333 5.34 5.34 0 015.334-5.334A5.34 5.34 0 0112 6.667 5.34 5.34 0 016.667 12zM6 4.667h1.333V3.333H6v1.334z"
        fill="#243240"
      />
    </svg>
  );
}

export default SvgInfoOutlineIcon;
