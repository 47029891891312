import {
  ProgramElement,
  ProgramVideo,
  ProgramWeek,
  VideoMeta,
} from '@gql/generated';
import { genericFieldResolvers } from './GenericProgram';
import { isPast } from 'date-fns/isPast';
import { addDays } from 'date-fns/addDays';

function enhanceVideoObject({ element, weekSlug, programSlug }) {
  const _currentElement = { ...element };
  // we generate url to video, to make the navigation from the header card easy
  _currentElement.path = `${programSlug}/${weekSlug}/${element.slug}`;
  return _currentElement;
}

export default {
  typePolicies: {
    WeeklyProgram: {
      keyFields: ['slug'],
      fields: {
        ...genericFieldResolvers,
        //
        currentElement(_, { readField }) {
          const weeks = readField('weeks') as [];
          let currentElement: ProgramElement = null;
          weeks.forEach((weekRef, weekIndex) => {
            const elements = readField('elements', weekRef) as ProgramVideo[];
            const weekSlug = readField('slug', weekRef) as string;
            const programSlug = readField('slug') as string;

            // we find video marked as current
            const element = elements.find((element, elementIndex) => {
              const { progressIsCurrent } = element;
              if (weekIndex === 0 && elementIndex === 0) {
                // we set first video to be currentElement as fallback
                currentElement = enhanceVideoObject({
                  element,
                  weekSlug,
                  programSlug,
                });
              }

              if (progressIsCurrent) {
                return true;
              }
              return false;
            });

            // if video is found then we enhance the object with the needed data through out the components
            if (element) {
              currentElement = enhanceVideoObject({
                element,
                weekSlug,
                programSlug,
              });
            }
          });

          if (currentElement?.__typename === 'ProgramDocument') {
            return {
              title: currentElement.title,
              path: currentElement['path'],
            };
          } else if (currentElement?.__typename === 'ProgramVideo') {
            return {
              title:
                currentElement.customTitle ||
                currentElement.title ||
                readField('title', currentElement.video),
              path: currentElement['path'],
            };
          } else if (currentElement?.__typename === 'ProgramLiveEvent') {
            return {
              title: currentElement.title || currentElement.liveEvent.title,
              path: currentElement['path'],
            };
          } else {
            return null;
          }
        },
        videosCount(_, { readField }) {
          let count = 0;
          (readField('weeks') as ProgramWeek[]).forEach(week => {
            count += readField('videosCount', week) as number;
          });
          return count;
        },
        progress(_, { readField }) {
          const weeks = readField('weeks') as ProgramWeek[];
          const totalProgress = weeks.reduce((total, week) => {
            const progress: ProgramWeek['progress'] = readField(
              'progress',
              week
            );
            return total + progress;
          }, 0);
          return parseInt((totalProgress / weeks.length).toString());
        },
      },
    },
    ProgramWeek: {
      fields: {
        progress: {
          merge(_, incoming) {
            return parseFloat(Math.round(incoming).toString());
          },
        },
        videosCount(_, { readField }) {
          return (
            (readField('elements') || []).filter(
              (element: ProgramElement) => element.__typename === 'ProgramVideo'
            ).length || null
          );
        },
        duration(_, { readField }) {
          const elements = readField('elements') as [];
          const totalMinutes = elements.reduce(
            (total, element: ProgramElement) => {
              if (element.__typename === 'ProgramVideo') {
                const videoMeta: VideoMeta = readField('meta', element.video);
                return total + videoMeta?.minutes;
              }
              return total;
            },
            0
          );
          const hours = Math.trunc(totalMinutes / 60);
          const minutes = totalMinutes % 60;
          return (
            (hours > 0 ? `${hours}H` : '') +
            (minutes > 0 ? `${minutes}Min` : '')
          );
        },
      },
    },
    ProgramDocument: {
      keyFields: false as const,
    },
    ProgramLiveEvent: {
      keyFields: false as const,
      fields: {
        isExpired(_, { readField }) {
          try {
            const slug = readField('slug');
            const liveEvent = readField('liveEvent');
            const dates = readField('eventDates', liveEvent);
            if (dates) {
              const lastDate = dates[dates.length - 1];
              return isPast(addDays(new Date(lastDate), 3));
            }

            return false;
          } catch (ex) {
            console.error({ ex });
            return false;
          }
        },
      },
    },
  },
};
