import React, { useEffect, useRef, useCallback, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import Link from 'next/link';
import debounce from 'lodash/debounce';
import { Title } from './MenuContent';

const Header = styled.div<{ open: boolean }>`
  cursor: pointer;

  ${Title} {
    background-image: ${({ open }) =>
      open ? 'url(/static/icons/minus.svg)' : 'url(/static/icons/plus.svg)'};
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 100%;
  }

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    &:hover ${Title} {
      color: ${({ theme }) => theme.colors.primary['blue-1']};
    }

    ${Title} {
      padding-left: 20px;
      background-position: 5px;
    }
  }
`;

const Mask = styled.div`
  height: 0px;
  overflow: hidden;
  transition: height 400ms ease-out;
`;

const Body = styled.ul`
  list-style: none;
  padding: 10px 0;
  margin-left: 5px;
`;

const Item = styled.li`
  padding: 5px 0 5px 5px;
  margin-top: 5px;
`;

const Anchor = styled(Link)`
  display: flex;
  color: #242424;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-decoration: none;

  &:after {
    content: '';
    width: 14px;
    height: 14px;
    margin: 2px 0 0 auto;
    background-image: url(/static/icons/arrow-right-dark.svg);
    background-repeat: no-repeat;
    background-size: 14px;
    background-position: center 100%;
  }

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    &:after {
      content: '';
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-left: 6px;
      background-image: url(/static/icons/arrow-right-blue.svg);
      background-repeat: no-repeat;
      background-size: 14px;
      background-position: center 100%;
      opacity: 0;
      transform: translateX(10px);
      transition: opacity 200ms ease, transform 200ms ease;
    }

    :hover {
      color: ${({ theme }) => theme.colors.primary['blue-1']};

      :after {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
`;

const Subdropdown = props => {
  const maskRef = useRef<HTMLDivElement>();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const { header, items } = props;

  const updateHeight = () => {
    if (maskRef.current) {
      const child = maskRef.current?.firstChild as HTMLDivElement;
      if (child !== undefined) {
        let height = 0;
        if (open) {
          height = child.getBoundingClientRect().height;
        }
        maskRef.current.style.height = height + 'px';
      }
    }
  };

  const resizeHandler = useCallback(
    debounce(updateHeight, 500, { maxWait: 2000 }),
    []
  );

  useEffect(() => {
    updateHeight();

    if (window.matchMedia(`(${theme.viewports.tablet}px)`)) {
      window.addEventListener('resize', resizeHandler);
      return () => window.removeEventListener('resize', resizeHandler);
    }
  }, [open]);

  return (
    <div>
      <Header
        open={open}
        onClick={() => {
          setOpen(open => !open);
        }}
      >
        {header}
      </Header>
      <Mask ref={maskRef}>
        <Body>
          {items.map((item, i) => {
            return (
              <Item key={i}>
                <Anchor href={item.link}>{item.text}</Anchor>
              </Item>
            );
          })}
        </Body>
      </Mask>
    </div>
  );
};

export default Subdropdown;
