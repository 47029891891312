import styled, { keyframes } from 'styled-components';

const loadingAnimation = keyframes`
  0%{
    background-position: -468px 0
  }
  100%{
     background-position: 468px 0
  }
`;

const bar = styled.div`
  border-radius: 1px;
  background: linear-gradient(
    to right,
    rgb(247, 247, 247) 8%,
    rgb(240, 240, 240) 18%,
    rgb(247, 247, 247) 33%
  );
  background-size: 800px 104px;
  display: block;
  width: 100%;
  line-height: 1;
  height: 10px;
  margin-bottom: 20px;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${loadingAnimation};
  animation-timing-function: linear;
`;

export const darkerBar = styled(bar)`
  background: linear-gradient(
    to right,
    rgb(240, 240, 240) 8%,
    rgb(233, 233, 233) 18%,
    rgb(240, 240, 240) 33%
  );
`;

export default bar;
