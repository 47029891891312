import { PROGRAMS_PAGE_SLUG } from './constants';

export default {
  en: {
    communityLinks: [
      {
        BEYOGA365: '/se/beyoga365',
      },
      {
        YOGAMOM365: '/se/yogamom365',
      },
    ],
    socialMediaLinks: [
      {
        Facebook: 'https://www.facebook.com/yogobe.com',
      },
      {
        Instagram: 'https://instagram.com/yogobe',
      },
      {
        Youtube: 'https://www.youtube.com/user/YogobeOfficial',
      },
      {
        LinkedIn: 'https://www.linkedin.com/company/yogobe-ab',
      },
    ],
    servicesLinks: [
      { Pricing: '/en/pricing' },
      {
        'Terms and conditions': '/en/terms-and-conditions',
      },
      {
        'Privacy Policy': '/en/terms-and-conditions',
      },
    ],
  },
  se: {
    yogobeLinks: [
      { Videobibliotek: '/se/videos/genres/all' },
      { 'Marketplace - kurser och resor': '/se/marketplace' },
      { Träningsprogram: `/se/${PROGRAMS_PAGE_SLUG}` },
      { Blogg: '/se/blog' },
      { Webshop: 'https://store.yogobe.com/' },
      { Mecenat: '/se/register/mecenat' },
    ],
    discoverLinks: [
      { 'Mitt Behov': '/se/behov' },
      { 'Allt om Yoga': '/se/yoga' },
      { Yogapositioner: '/se/yoga/positioner' },
      { Yogaformer: '/se/yoga/stilar' },
      { Meditation: '/se/meditation' },
      { 'Träning & Rörelse': '/se/movement' },
      { Mindfulness: '/se/mindfulness' },
      { Ayurveda: '/se/ayurveda/om-ayurveda' },
    ],
    communityLinks: [
      {
        BEYOGA365: '/se/beyoga365',
      },
      {
        YOGAMOM365: '/se/yogamom365',
      },
    ],
    socialMediaLinks: [
      {
        Facebook: 'https://www.facebook.com/yogobe.com',
      },
      {
        Instagram: 'https://instagram.com/yogobe',
      },
      {
        Youtube: 'https://www.youtube.com/user/YogobeOfficial',
      },
      {
        LinkedIn: 'https://www.linkedin.com/company/yogobe-ab',
      },
    ],
    servicesLinks: [
      {
        'Priser och tjänster': '/se/pricing',
      },
      {
        'Kontakta oss': '/se/kontakt',
      },
      {
        'Frågor och svar': '/se/faq',
      },
      {
        Press: '/se/press',
      },
      { Integritetpolicy: '/se/integritetspolicy' },
      {
        Villkor: '/se/villkor',
      },
    ],
  },
  fi: {
    communityLinks: [
      {
        BEYOGA365: '/se/beyoga365',
      },
      {
        YOGAMOM365: '/se/yogamom365',
      },
    ],
    socialMediaLinks: [
      {
        Facebook: 'https://www.facebook.com/yogobe.com',
      },
      {
        Instagram: 'https://instagram.com/yogobe',
      },
      {
        Youtube: 'https://www.youtube.com/user/YogobeOfficial',
      },
      {
        LinkedIn: 'https://www.linkedin.com/company/yogobe-ab',
      },
    ],
    servicesLinks: [
      { Pricing: '/fi/pricing' },
      {
        'Privacy Policy': '/fi/terms-and-conditions',
      },
      {
        Yhteystiedot: '/fi/yhteystiedot',
      },
      {
        FAQ: '/fi/faq',
      },
      {
        Media: '/fi/media',
      },
      {
        Ehdot: '/fi/ehdot',
      },
    ],
  },
  no: {
    communityLinks: [
      {
        BEYOGA365: '/se/beyoga365',
      },
      {
        YOGAMOM365: '/se/yogamom365',
      },
    ],
    socialMediaLinks: [
      {
        Facebook: 'https://www.facebook.com/yogobe.com',
      },
      {
        Instagram: 'https://instagram.com/yogobe',
      },
      {
        Youtube: 'https://www.youtube.com/user/YogobeOfficial',
      },
      {
        LinkedIn: 'https://www.linkedin.com/company/yogobe-ab',
      },
    ],
    servicesLinks: [
      { 'Priser och tjänster': '/no/pricing' },
      {
        Kontakt: '/no/kontakt',
      },
      {
        FAQ: '/no/faq',
      },
      {
        Media: '/no/media',
      },
      {
        Vilkar: '/no/vilkar',
      },
    ],
  },
};
