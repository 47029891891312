import * as React from 'react';

const SvgPlayIcon = props => (
  <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7 1a6 6 0 1 1 0 12A6 6 0 0 1 7 1Zm0-1a7 7 0 1 0 0 14A7 7 0 0 0 7 0ZM5 10.5a.5.5 0 0 1-.5-.5V4a.5.5 0 0 1 .245-.43.5.5 0 0 1 .5 0l5.5 3a.5.5 0 0 1 0 .88l-5.5 3A.5.5 0 0 1 5 10.5Zm.5-5.66v4.32L9.455 7 5.5 4.84Z"
      fill="#000"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgPlayIcon;
