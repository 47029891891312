import React from 'react';
import styled from 'styled-components';

const Span = styled.span`
  /** Margins **/
  ${props =>
    props.margin &&
    `
      margin: ${props.margin}px;
    `}

  ${props =>
    props.marginBottom &&
    `
      margin-bottom: ${props.marginBottom}px;
    `}

  ${props =>
    props.marginTop &&
    `
      margin-top: ${props.marginTop}px;
    `}

  ${props =>
    props.marginLeft &&
    `
      margin-left: ${props.marginLeft}px;
    `}

  ${props =>
    props.marginRight &&
    `
      margin-right: ${props.marginRight}px;
    `}

  ${props =>
    props.verticalInline &&
    `
      display: inline-block;
      vertical-align: ${props.verticalInline};
    `}

  /** Colors and sizing **/
  color: ${props => (props.color ? props.color : null)};

  font-size: ${props => (props.fontSize ? props.fontSize : null)};

  font-weight: ${props => (props.bold ? '600' : 'normal')};

  text-transform: ${props => (props.upperCase ? 'uppercase' : 'normal')};
`;

const span = props => (
  <Span
    margin={props.margin}
    marginBottom={props.marginBottom}
    marginTop={props.marginTop}
    marginLeft={props.marginLeft}
    marginRight={props.marginRight}
    color={props.color}
    fontSize={props.fontSize}
    bold={props.bold}
    upperCase={props.upperCase}
    verticalInline={props.verticalInline}
    data-testid={props['data-testid']}
  >
    {props.children}
  </Span>
);

export default span;
