import * as React from 'react';

const SvgStoreIcon = (props) => (
  <svg
    width={14}
    height={13}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="m14 3.84-1-3a.5.5 0 0 0-.5-.34h-11a.5.5 0 0 0-.5.34l-1 3A.595.595 0 0 0 0 4v3a.5.5 0 0 0 .5.5H1v5h1v-5h3v5h8v-5h.5A.5.5 0 0 0 14 7V4a.595.595 0 0 0 0-.16Zm-2 7.66H6v-4h6v4Zm1-5h-2v-2h-1v2H7.5v-2h-1v2H4v-2H3v2H1V4.08l.86-2.58h10.28L13 4.08V6.5Z"
      fill="#000"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgStoreIcon;
