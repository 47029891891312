import Link from 'next/link';
import styled, { css } from 'styled-components';

/**
 * Utils
 */

export function resetMarginPadding() {
  return `
    margin: 0;
    padding: 0;
  `;
}

export const H1Error = styled.h1<{ fontSize?: string }>`
  font-size: ${p => (p.fontSize ? p.fontSize : '80px')};
  color: ${props => props.theme.colors.neutrals['white']};
  font-weight: bold;
  ${resetMarginPadding()}

  @media (max-width: ${props => `${props.theme.viewports.mobile}px`}) {
    font-size: 40px;
  }
`;

export const PError = styled.p`
  font-size: 24px;
  font-weight: 300;
  color: ${props => props.theme.colors.neutrals['white']};
  ${resetMarginPadding()};
  a {
    color: ${props => props.theme.colors.neutrals['white']};
  }
`;

export const LISignup = styled.li`
  font-size: 13px;
  font-weight: normal;
`;

export const ULConfirmPublicPlaylistModal = styled.ul`
  font-size: 13px;
  color: ${props => props.theme.colors.neutrals.black};
`;

export const LIConfirmPublicPlaylistModal = styled.li`
  margin-bottom: 5px;
`;

export const ULFooter = styled.ul`
  list-style-type: none;
  ${resetMarginPadding()} margin-top: 20px;
`;

export const LIFooter = styled.li`
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;

  & a {
    color: white;
    font-weight: normal;
  }
`;

export const InputActionTextHelper = styled.p<{
  success?: boolean;
  error?: boolean;
}>`
  margin-top: 5px;
  font-size: 13px;
  font-weight: 300;
  font-style: italic;
  color: ${props => props.theme.colors.primary['black']};

  ${props =>
    props.success ? `color: ${props.theme.colors.secondary['green-3']};` : ''};

  ${props =>
    props.error ? `color: ${props.theme.colors.secondary['red-1']};` : ''};
`;

export const PNewSubscriptionModal = styled.p`
  max-width: 30em;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
`;

export const ProgramCardDuration = styled.span`
  color: white;
  font-size: 10px;
  font-weight: 500;
`;
export const CourseCardPrice = styled.span`
  color: white;
  font-size: 16px;
  font-weight: 600;
`;

export const PSignUp = styled.p`
  font-size: 13px;
  font-weight: normal;
  color: ${props => props.theme.colors.neutrals['black']};
`;

export const ParagraphProgram = styled.p`
  font-size: 16px;
  font-weight: normal;
  line-height: 1.94;
  color: ${props => props.theme.colors.neutrals.gray};
  ${resetMarginPadding()};
`;

export const ParagraphMeDashboard = styled.p`
  font-size: 16px;
  ${resetMarginPadding()}
  color: ${props =>
    props.color ? props.color : props.theme.colors.neutrals['black-ligther']};
`;

export const PFaq = styled.p`
  font-size: 13px;
  font-weight: normal;
  color: ${props => props.theme.colors.neutrals.black};
  margin: 0;
`;

export const PCancelSubscriptionModal = styled.p`
  font-size: 13px;
  font-weight: normal;
  margin: 0;
`;

export const CopyrightFooter = styled.p`
  color: white;
  font-size: 13px;
  font-weight: 300;
`;

export const LanguagesMenuFooter = styled.p`
  color: white;
  font-size: 16px;
  font-weight: 300;
  margin: 0;

  & a {
    color: inherit;
    font-weight: normal;
  }
`;

export const ParagraphNewCourseCard = styled.p<{ italic?: boolean }>`
  ${resetMarginPadding()};
  font-size: 13px;
  color: ${props => props.theme.colors.neutrals.gray};

  ${props => (props.italic ? 'font-style: italic;' : '')};
`;

export const DescriptionHistoryItemCard = styled.p`
  font-size: 13px;
  color: ${props => props.theme.colors.neutrals.gray};
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PBanner = styled.p<{ dark?: boolean; bold?: boolean }>`
  color: ${props => (props.dark ? props.theme.colors.neutrals.black : `white`)};
  ${resetMarginPadding()} font-size: 13px;
  line-height: 16px;
  font-weight: ${props => (props.bold ? 500 : 200)};
  a {
    color: #fff;
  }
`;

export const BodyPAccordion = styled.div`
  font-size: 13px;
  font-weight: normal;
  padding: 0;
  margin: 0;
`;

export const HeaderPAccordion = styled.p`
  font-size: 13px;
  font-weight: 600;
  color: ${props => props.theme.colors.primary['blue-2']};
  padding: 0;
  margin: 0;
`;

export const LabelSettingsSubscriptions = styled.h4`
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.colors.primary['black']};
  margin: 0;
  margin-bottom: 10px;
`;

export const HelperSettingsSubscriptions = styled.p<{ italic?: boolean }>`
  font-size: 13px;
  font-weight: 300;
  color: ${props => props.theme.colors.primary['black']};
  margin: 0;
  margin-bottom: 10px;
  font-style: ${props => (props.italic ? 'italic' : null)};
`;

export const PSettingsReferralPrograms = styled.p`
  font-size: 13px;
  font-weight: normal;
  color: ${props => props.theme.colors.neutrals['black']};
`;

export const PSettingsHeaderTitle = styled.p`
  margin: 0;
  font-size: 13px;
  line-height: 1.38;
  color: ${props => props.theme.colors.neutrals.black};
  font-style: italic;
`;

export const PSettingsFringeHealthBenefits = styled.p`
  font-size: 13px;
  font-weight: normal;
  color: ${props => props.theme.colors.neutrals['black']};
`;

export const Helper = styled.p`
  margin-top: 5px;
  font-size: 13px;
  font-weight: 300;
  font-style: italic;
  color: ${props => props.theme.colors.primary['black']};
`;

export const BioParagraphProfileHeader = styled.p`
  ${resetMarginPadding()} font-size: 13px;
  color: ${props => props.theme.colors.neutrals.gray};

  & a {
    color: ${props => props.theme.colors.primary['blue-2']};
  }
`;

export const NewsCardDate = styled.p`
  margin: 0;
  font-size: 13px;
  font-weight: normal;
`;

export const NewsCardTitle = styled.h5<{ color: string }>`
  font-size: 24px;
  font-weight: 600;
  color: ${props => props.color};
`;

export const NewsCardDescription = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: ${props => props.color};
`;

export const TeamCardP = styled.p`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: ${props => props.theme.colors.primary['blue-1']};
`;

export const HomeStarterP = styled.p`
  font-size: 18px;
  font-weight: 300;
  margin: 0;
  color: ${props => props.theme.colors.neutrals.black};
`;

export const HomeAsSubscriber = styled.p`
  font-size: 18px;
  font-weight: 300;
  color: ${props => props.theme.colors.neutrals.black};
  margin: 0;
`;

export const HomeUlSubscriber = styled.ul`
  ${resetMarginPadding()};
`;

export const HomeLiSubscriber = styled.li`
  list-style-type: none;
  margin: 0;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.colors.neutrals.black};
`;

export const H1Positions = styled.h1`
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: ${props => props.theme.colors.neutrals.black};
  padding: 0;
`;

export const PPositions = styled.p`
  font-size: 13px;
  font-weight: normal;
  color: ${props => props.theme.colors.neutrals.black};
  ${resetMarginPadding()};
  line-height: 23px;
`;

export const TitlePositions = styled.p`
  font-size: 13px;
  font-weight: 600;
  ${resetMarginPadding()}
  color: ${props => props.theme.colors.neutrals.black};
`;

export const DescriptionPositions = styled.p`
  font-size: 13px;
  font-weight: normal;
  ${resetMarginPadding()}
  color: ${props => props.theme.colors.neutrals.gray};
`;

export const H2Positions = styled.h2`
  font-size: 18px;
  font-weight: 300;
  color: ${props => props.theme.colors.neutrals.black};
  ${resetMarginPadding()};
`;

export const H1Position = styled.h1`
  font-size: 34px;
  font-weight: 300;
  ${resetMarginPadding()}
  color: ${props => props.theme.colors.neutrals.black};
`;

export const H2Position = styled.h1`
  font-size: 16px;
  font-weight: 600;
  ${resetMarginPadding()}
  color: ${props => props.theme.colors.neutrals.black};
`;

export const H3Position = styled.h1`
  font-size: 13px;
  font-weight: 600;
  ${resetMarginPadding()}
  color: ${props => props.theme.colors.neutrals.black};
`;

export const SubTitlePosition = styled.p`
  font-size: 11px;
  font-weight: 600;
  color: ${props => props.theme.colors.neutrals.black};
  ${resetMarginPadding()};
`;

export const SubTitle = styled.p<{ customStyle?: any }>`
  font-size: 13px;
  line-height: 23px;
  color: ${props => props.theme.colors.neutrals.black};
  ${resetMarginPadding()};
  margin-top: 8px;

  /* custom style - should already be wrapper in styled.css */
  ${props => props.customStyle}
`;

export const PPosition = styled.div`
  h2 {
    margin-bottom: 10px;
    color: ${props => props.theme.colors.neutrals['black']};
    font-size: 16px;
    font-weight: 600;
  }

  p {
    font-size: 13px;
    font-weight: normal;
    color: ${props => props.theme.colors.neutrals.black};
    ${resetMarginPadding()};
  }

  ul {
    font-size: 13px;
    font-weight: normal;
    color: ${props => props.theme.colors.neutrals.black};
  }
`;

export const H2SectionHeader = styled.h2`
  font-size: 18px;
  font-weight: 300;
  color: ${props => props.theme.colors.neutrals.black};
  margin: 0;
  padding: 0;
  line-height: 40px;
`;

/**
 * PostCard
 */

export const H3PostCard = styled.h3`
  font-size: 34px;
  font-weight: bold;
  ${resetMarginPadding()}
  color: ${props => props.theme.colors.neutrals.black};
`;

export const CategoryPostCard = styled.p`
  ${resetMarginPadding()} font-size: 13px;
  font-weight: 600;
  color: ${props => props.theme.colors.neutrals.black};
`;

export const DatePostCard = styled.p`
  font-size: 13px;
  font-weight: normal;
  ${resetMarginPadding()}
  color: ${props => props.theme.colors.neutrals.black};
`;

export const DescriptionPostCard = styled.p`
  font-size: 18px;
  font-weight: 300;
  ${resetMarginPadding()}
  color: ${props => props.theme.colors.neutrals.black};
`;

/**
 * Layout
 */

export const MainSubHeaderTitle = styled.h1`
  ${resetMarginPadding()};
  color: #253238;
  font-size: 48px;
  @media (max-width: ${props => `${props.theme.viewports.tablet}px`}) {
    font-size: 32px;
  }
  font-weight: bold;
`;

export const MainSubHeaderDescription = styled.p`
  ${resetMarginPadding()};
  color: #253238;
  font-size: 13px;
  line-height: 23px;
`;

/**
 * Testimonial
 */

export const TestimonialText = styled.p`
  font-size: 30px;
  font-style: italic;
  font-weight: 300;
  color: ${props => props.theme.colors.neutrals.white};
  ${resetMarginPadding()};
`;

export const TestimonialAuthor = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.colors.neutrals.white};
  ${resetMarginPadding()};
`;

/**
 * Yogobe Store
 */

export const StoreTitle = styled.p`
  font-size: 34px;
  font-weight: bold;
  color: ${props => props.theme.colors.neutrals.white};
  ${resetMarginPadding()};
`;

export const StoreDescription = styled.p`
  font-size: 24px;
  font-weight: 300;
  color: ${props => props.theme.colors.neutrals.white};
  ${resetMarginPadding()};
`;

export const CardTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  ${resetMarginPadding()};
  color: ${props => props.theme.colors.neutrals.black};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CardDescriptionDivStyledP = styled.p`
  display: -webkit-box;
  height: 100%;
  font-size: 14px;
  color: ${props => props.theme.colors.neutrals.gray};
  line-height: 19px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  ${resetMarginPadding()};
`;

export const CardDescription = styled.p`
  display: -webkit-box;
  font-size: 14px;
  color: ${props => props.theme.colors.neutrals.gray};
  height: 100%;
  line-height: 22px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  ${resetMarginPadding()};
`;

/**
 * EmptyResults
 */

export const EmptyResultsText = styled.div`
  ${resetMarginPadding()};
  font-size: 18px;
  font-weight: 300;
`;

export const EmptyResultsDescription = styled.div`
  max-width: 700px;
  font-size: 13px;
  color: ${props => props.theme.colors.neutrals['gray']};
  font-weight: 300;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  ${resetMarginPadding()};
`;

export const EmptyResultsTextAction = styled.div`
  ${resetMarginPadding()};
  font-size: 13px;
  color: ${props => props.theme.colors.neutrals.gray};
`;

export const EmptyResultsLi = styled.li`
  list-style-type: none;
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.colors.neutrals.black};
  margin: 10px 10px;
  cursor: pointer;
`;

export const EmptyResultsUl = styled.ul`
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0px -10px;
  justify-content: center;
`;

export const PlaylistsCollectionSubHeaderA = styled(Link)<{ $bold?: boolean }>`
  text-decoration: none;
  cursor: pointer;
  font-size: 13px;
  font-weight: ${props => (props.$bold ? 600 : 'normal')};
  ${resetMarginPadding()};
  color: #404040;
  &:first-letter {
    text-transform: capitalize;
  }
`;

export const PlaylistsCollectionSubHeaderP = styled.p<{ bold?: boolean }>`
  font-size: 13px;
  font-weight: ${props => (props.bold ? 600 : 'normal')};
  ${resetMarginPadding()};
  color: #404040;
  &::first-letter {
    text-transform: uppercase;
  }
`;

export const FormHelper = styled.span`
  font-size: 13px;
  font-weight: 500;
  color: #e53b4d;
`;

/**
 * Instructor Meta
 */

export const InstructorMetaTitle = styled.div`
  ${resetMarginPadding()};
  font-size: 13px;
  font-weight: 600;
`;

export const InstructorMetaDescription = styled.div`
  ${resetMarginPadding()};
  color: ${props => props.theme.colors.neutrals['black']};
  font-size: 13px;
`;

/**
 * Static Pages
 */

export const H3StaticPage = styled.h3`
  ${resetMarginPadding()};
  font-size: 24px;
  font-weight: 300;
`;

export const PStaticPage = styled.p`
  ${resetMarginPadding()};
  color: ${props => props.theme.colors.neutrals['black']};
  font-size: 13px;
`;

export const PStaticPagePrison = styled.div`
  ${resetMarginPadding()};
  color: ${props => props.theme.colors.neutrals['black']};
  font-size: 13px;
  line-height: 23px;
  b {
    display: inline-block;
    margin: 0;
    padding: 0;
  }
  strong {
    margin: 0;
    padding: 0;
  }
`;
export const PStaticPagePS = styled.div`
  ${resetMarginPadding()};
  color: ${props => props.theme.colors.neutrals['black']};
  font-size: 13px;
  line-height: 23px;
  b {
    display: inline-block;
    margin: 0;
    padding: 0;
  }
  strong {
    margin: 0;
    padding: 0;
  }
  ul {
    margin: 0;
    margin-bottom: 20px;
    padding-left: 20px;
    list-style-type: none;
    li {
      &:before {
        font-family: 'ygb-font' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: '\\e944';
        color: #cd206a;
        margin-right: 10px;
      }
    }
  }
`;

export const DivStaticPage = styled.div`
  ${resetMarginPadding()};
  color: ${props => props.theme.colors.neutrals['black']};
  font-size: 13px;
`;

export const H4StaticPage = styled.h4`
  ${resetMarginPadding()};
  color: ${props => props.theme.colors.neutrals['black']};
  font-size: 16px;
  font-weight: 600;
`;

export const H5StaticPage = styled.h5`
  ${resetMarginPadding()};
  color: ${props => props.theme.colors.neutrals['black']};
  font-size: 13px;
  font-weight: 600;
`;

export const TestimonialStatic = styled.p`
  ${resetMarginPadding()};
  color: ${props => props.theme.colors.neutrals['black']};
  margin-top: 17px;
  font-size: 16px;
  line-height: 24px;
`;

export const LiStaticPage = styled.li`
  ${resetMarginPadding()};
  font-size: 13px;
  color: ${props => props.theme.colors.neutrals['black']};
`;

export const PlaylistSingleTitle = styled.h2`
  ${resetMarginPadding()};
  font-size: 18px;
  font-weight: 300;
  color: ${props => props.theme.colors.neutrals['black']};
`;

export const PlaylistSingleMeta = styled.div`
  ${resetMarginPadding()};
  font-size: 13px;
  font-weight: normal;
  color: ${props => props.theme.colors.neutrals['gray']};
`;

export const UlLiStyling = styled.div`
  ul {
    font-size: 13px;
    font-weight: normal;
    color: ${props => props.theme.colors.neutrals.black};
  }
`;

export const BlogSingleH1 = styled.h1`
  ${resetMarginPadding()};
  font-size: 34px;
  font-weight: normal;
  color: ${props => props.theme.colors.neutrals.white};
  font-weight: bold;
  @media (max-width: ${props => `${props.theme.viewports.mobile}px`}) {
    font-size: 14px;
  }
`;

export const BlogSingeMeta = styled.span`
  ${resetMarginPadding()};
  font-size: 13px;
  font-weight: normal;
  color: ${props => props.theme.colors.neutrals.black};
`;

export const ProgressLabelPrograms = styled.span`
  ${resetMarginPadding()};
  font-size: 12px;
  font-weight: 600;
  color: ${props => props.theme.colors.neutrals.black};
`;

export const ProgramsDuration = styled.span`
  ${resetMarginPadding()};
  font-size: 13px;
  color: #444444;
`;

export const ProgramsResume = styled.span`
  ${resetMarginPadding()};
  font-size: 13px;
  color: ${props => props.theme.colors.neutrals['black']};

  .bold {
    font-weight: 600;
  }
`;

export const ProgramsAccess = styled.span`
  ${resetMarginPadding()};
  font-size: 13px;
  color: ${props => props.theme.colors.neutrals['gray']};
  font-style: italic;
`;

export const ProgramsLabel = styled.span`
  ${resetMarginPadding()};
  border-radius: 5px;
  background-color: ${props => props.theme.colors.primary['blue-2']};
  color: white;
  padding: 5px;
  margin-right: 5px;
  font-size: 11px;
  text-transform: uppercase;
`;

export const ProgramsTitle = styled.span`
  ${resetMarginPadding()};
  color: ${props => props.theme.colors.neutrals['black']};
  font-weight: bold;
  font-size: 24px;

  @media (max-width: ${props => `${props.theme.viewports.tablet}px`}) {
    font-size: 20px;
  }
`;

export const ProgramsSchedule = styled.p`
  ${resetMarginPadding()};
  font-weight: 300;
  font-size: 16px;
  color: ${props => props.theme.colors.neutrals['gray']};
  margin: 0;

  @media (max-width: ${props => `${props.theme.viewports.tablet}px`}) {
    text-align: center;
  }
`;

export const ProgramsTotalVideosDuration = styled.div`
  ${resetMarginPadding()};
  font-weight: 600;
  font-size: 11px;
  color: ${props => props.theme.colors.neutrals['black']};

  @media (max-width: ${props => `${props.theme.viewports.tablet}px`}) {
    text-align: center;
  }
`;

export const NextDayDateLabel = styled.span`
  font-size: 13px;
  font-weight: 600;
  color: ${props => props.theme.colors.neutrals['black']};
`;

export const NextDayTitle = styled.h3<{ isCurrent?: boolean }>`
  width: 100%;
  font-size: 16px;
  color: ${props =>
    props.isCurrent ? '#243240' : props.theme.colors.neutrals['black']};
  font-weight: ${props => (props.isCurrent ? 600 : 400)};
  margin: 0;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ProgramsSingleBoxTitle = styled.div`
  ${resetMarginPadding()};
  color: ${props => props.theme.colors.neutrals['black']};
  font-size: 24px;
  font-weight: 300;
`;

export const ProgramsSingleBoxDescription = styled.div`
  ${resetMarginPadding()};
  color: ${props => props.theme.colors.neutrals['black']};
  font-size: 13px;
`;

export const ProgramCollapsibleTextTitle = styled.div`
  ${resetMarginPadding()};
  display: flex;
  color: ${props => props.theme.colors.neutrals['black']};
  font-size: 22px;
  font-weight: 400;

  @media (max-width: ${props => `${props.theme.viewports.tablet}px`}) {
    font-size: 18px;
  }
`;

export const ProgramCollapsibleTitleMeta = styled.div`
  ${resetMarginPadding()};
  font-size: 11px;
  font-weight: 600;
  color: ${props => props.theme.colors.neutrals['gray']};
  text-transform: uppercase;
`;

export const ProgramIntroductionStyles = styled.div`
  ul {
    margin: 0;
    padding: 0;
  }

  p,
  li {
    font-size: 16px;
    color: ${props => props.theme.colors.neutrals['black']};
    margin: 0;
    padding: 0;
    line-height: 23px;
  }
`;

export const ProgramTitleVideo = styled.div`
  color: ${props => props.theme.colors.neutrals['black']};
  font-size: 18px;
  font-weight: 300;
`;

export const ProgramDescriptionVideo = styled.div`
  color: ${props => props.theme.colors.neutrals['gray']};
  font-size: 13px;
`;

export const ProgramStepTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${props => props.theme.colors.neutrals['black']};
  font-weight: 600;
`;

export const sectionTitleStyle = css`
  @media (max-width: ${({ theme }) => theme.viewports.mobile}px) {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.neutrals.black};
    font-weight: normal;
    line-height: 1.4;
  }
`;
//
export const ErrorMessage = styled.p`
  font-size: 14px;
  color: ${props => props.theme.colors.secondary['red-1']};
  margin: 10px;
`;

export const Striked = styled.span`
  text-decoration: line-through;
`;
