import Router from 'next/router';
import { ApolloClient } from '@apollo/client';
import { LOGIN_ACTION, PAYMENT_CONFIRMATION_ACTION } from './constants';
import {
  BundleCategoryKind,
  CurrentUser,
  LiveEventPaymentKind,
  Price,
  PriceTypeKind,
  ProgramAccessStatus,
  ProgramCategory,
  RegisterSourceBundleDocument,
  RegisterSourceBundleQuery,
  RegisterSourceGenericProgramDocument,
  RegisterSourceGenericProgramQuery,
  RegisterSourceInstructorDocument,
  RegisterSourceInstructorQuery,
  RegisterSourceLiveDocument,
  RegisterSourceLiveQuery,
  RegistrationSourceKind,
} from '@gql/generated';

export function borders(type) {
  return `
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    ${type === 'both' &&
      `
        border-left: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
        border-radius: 3px;
      `};

    ${type === 'left' &&
      `
        border-right: 0;
        border-left: 1px solid #dddddd;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      `};

    ${type === 'right' &&
      `
        border-left: 0;
        border-right: 1px solid #dddddd;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      `};
    `;
}

export const getMargins = (index, length, nextline = 0) => {
  const margins = {
    mb: [20, 20],
  };

  if (index === 0 || index === nextline)
    return {
      ...margins,
      pl: 0,
      pr: [0, 20],
    };

  if (index === length - 1 || index === length + nextline - 1)
    return {
      ...margins,
      pl: [0, 20],
      pr: 0,
    };

  return {
    ...margins,
    px: [0, 10],
  };
};

export const getUserProgramAccess = ({
  user,
  programSlug,
  programCategory,
}: {
  programSlug: string;
  user: CurrentUser;
  programCategory: ProgramCategory;
}) => {
  if (programSlug) {
    if (
      user?.courseAccesses &&
      programCategory === ProgramCategory['Courses']
    ) {
      return user.courseAccesses?.find(p => {
        if (p.course.slug === programSlug) {
          return true;
        }
      });
    } else if (
      user?.programAccess &&
      programCategory === ProgramCategory['Programs']
    ) {
      return user.programAccess?.find(p => {
        if (p.program.slug === programSlug) {
          return true;
        }
      });
    } else if (
      programCategory === ProgramCategory['Challenges'] &&
      user?.challengeAccesses
    ) {
      return user.challengeAccesses?.find(p => {
        if (p.program.slug === programSlug) {
          return true;
        }
      });
    }
  }
  return null;
};

export const hideURLParams = action_type => {
  if (action_type === PAYMENT_CONFIRMATION_ACTION) {
    const expectedParams = [
      'action',
      'amount',
      'amount_cents',
      'subscription_type',
      'recurring_date',
      'recurring_amount',
      'months',
      'recurring_currency',
      'currency',
      'amount_currency',
      'email',

      // these are probably sent by adyen
      'payload',
      'type',
      'resultCode',
      'order_id',
      'transact',
      'transaction',
    ];

    const url = new URL(window.location.href);

    const params = Object.fromEntries(
      Array.from(url.searchParams.entries()).filter(([key, value]) => {
        if (expectedParams.includes(key)) {
          return false;
        }

        return true;
      }, {})
    );

    const url_ = { pathname: url.pathname, query: params };
    return Router.replace(url_, url_, { shallow: true });
  } else if (action_type === LOGIN_ACTION) {
    // TODO: handle login clean up here
  }
};

export const getVideoDescription = video => {
  if (video?.content) {
    const { longDescription, shortDescription } = video.content;
    return shortDescription || longDescription;
  }
  return '';
};

export function normalizeImagePath(value) {
  if (String(value).startsWith('//')) {
    return 'https:' + value;
  }
  return value;
}

export const canBuyProgramGift = (price: Price, priceType: PriceTypeKind) => {
  if (
    [
      PriceTypeKind['AccessCodesOnly'],
      PriceTypeKind['BySubscription'],
      PriceTypeKind['FreeForAll'],
    ].includes(priceType) ||
    price === null
  ) {
    return false;
  }

  return true;
};

export const canExtendProgramAccess = (
  price: Price,
  priceType: PriceTypeKind,
  accessStatus: ProgramAccessStatus
) => {
  if (
    ([PriceTypeKind['BySubscription'], PriceTypeKind['FreeForAll']].includes(
      priceType
    ) ||
      price === null) &&
    ![
      ProgramAccessStatus['Expired'],
      ProgramAccessStatus['ExpiringSoon'],
    ].includes(accessStatus)
  ) {
    return false;
  }

  return true;
};

export const getSourceFromReferrer = async (
  apolloClient: ApolloClient<any>,
  data: ReturnType<typeof extrapolateSourceFromReferrer>
) => {
  try {
    if (
      [
        RegistrationSourceKind.Challenge,
        RegistrationSourceKind.Course,
        RegistrationSourceKind.Program,
        RegistrationSourceKind.B2hProgram,
      ].includes(data?.type)
    ) {
      const resp = await apolloClient.query<RegisterSourceGenericProgramQuery>({
        query: RegisterSourceGenericProgramDocument,
        variables: {
          slug: data.params.slug,
        },
      });

      const source = {
        [ProgramCategory['Challenges']]: RegistrationSourceKind.Challenge,
        [ProgramCategory['Programs']]: RegistrationSourceKind.Program,
        [ProgramCategory['Courses']]: RegistrationSourceKind.Course,
        [ProgramCategory['Healthcare']]: RegistrationSourceKind.B2hProgram,
      }[resp?.data?.genericProgram?.category];

      return [{ source, source_id: resp?.data?.genericProgram?.id }];
    } else if (RegistrationSourceKind.Live === data?.type) {
      const resp = await apolloClient.query<RegisterSourceLiveQuery>({
        query: RegisterSourceLiveDocument,
        variables: {
          slug: data.params.slug,
        },
      });

      return [
        {
          source: RegistrationSourceKind.Live,
          source_id: resp?.data?.liveEvent?.id,
        },
      ];
    } else if (RegistrationSourceKind.Instructor === data?.type) {
      const resp = await apolloClient.query<RegisterSourceInstructorQuery>({
        query: RegisterSourceInstructorDocument,
        variables: {
          slug: data.params.slug,
        },
      });
      return [{ source: data?.type, source_id: resp?.data?.instructor?.id }];
    } else if (RegistrationSourceKind.Bundle === data?.type) {
      const resp = await apolloClient.query<RegisterSourceBundleQuery>({
        query: RegisterSourceBundleDocument,
        variables: {
          slug: data.params.slug,
          client: data.params.client,
        },
      });

      let source: RegistrationSourceKind = data?.type;
      if (resp?.data?.bundle?.bundleCategory === BundleCategoryKind['FaR']) {
        source = RegistrationSourceKind.B2hFar;
      } else if (
        resp?.data?.bundle?.bundleCategory === BundleCategoryKind['ContentHub']
      ) {
        source = RegistrationSourceKind.ContentHubBundle;
      }

      return [{ source, source_id: resp?.data?.bundle?.id }];
    } else if (
      [RegistrationSourceKind.Video, RegistrationSourceKind.Playlist].includes(
        data?.type
      )
    ) {
      return [{ source: data?.type, source_id: data?.params?.slug }];
    } else if (RegistrationSourceKind.Blog === data?.type) {
      return [{ source: data?.type, source_id: data?.params?.slug || '' }];
    } else if (RegistrationSourceKind.Wellness === data?.type) {
      return [{ source: data?.type, source_id: data?.params?.slug || '' }];
    }
  } catch (ex) {
    return [{ source: null, source_id: null }];
  }
};

export const extrapolateSourceFromReferrer = (currentUrl: string) => {
  //
  const patterns = [
    {
      type: RegistrationSourceKind.Video,
      regex: /^\/(en|se|fi|no)\/videos(?:\/(?:overview(?:\/.*)?|genres(?:\/.*)?|(?<slug>[^/?]*)))?(\?.*)?/,
    },
    {
      type: RegistrationSourceKind.Playlist,
      regex: /^\/(se|en|fi|no)\/playlists(?:\/(?<slug>[^/]+))?(\?.*)?/,
    },
    {
      type: RegistrationSourceKind.Course,
      regex: /^\/(se|en|fi|no)\/courses(?:\/(?<slug>[^/]+)\/?.*)?(\?.*)?/,
    },
    {
      type: RegistrationSourceKind.Program,
      regex: /^\/(se|en|fi|no)\/programs(?:\/(?<slug>[^/]+)\/.*)?(\?.*)?/,
    },
    {
      type: RegistrationSourceKind.Challenge,
      regex: /^\/(se|en|fi|no)\/challenges(?:\/(?<slug>[^/]+)\/.*)?(\?.*)?/,
    },
    {
      type: RegistrationSourceKind.B2hProgram,
      regex: /^\/(se|en|fi|no)\/healthcare(?:\/(?:(?<slug>[^/]+)\/(?:overview|get-started|\w+-\d+\/\w+-\d+))|\/(programs)?)?(\?.*)?/,
    },
    {
      type: RegistrationSourceKind.Live,
      regex: /^\/(se|en|fi|no)\/live(?:\/(?<slug>[^/]+))?(\?.*)?/,
    },
    {
      type: RegistrationSourceKind.Instructor,
      regex: /^\/(se|en|fi|no)\/i\/(?<slug>[a-zA-Z0-9_-]+)/,
    },
    {
      type: RegistrationSourceKind.Bundle,
      regex: /^\/(se|en|fi|no)\/c\/(?<client>[^/]+)\/b\/(?<slug>[^/?]+)(?:\?.*)?/,
    },
    {
      type: RegistrationSourceKind.Blog,
      regex: /^\/(se|en|fi|no)\/blog\/?((?!category\/)(?<slug>[^\/]+)?|category\/[^\/]*)?(?:\/|$)/,
    },
    {
      type: RegistrationSourceKind.Wellness,
      regex: /^\/(en|se|fi|no)\/friskvardsbidrag(?:\/(?<slug>[^/?]+)\/?.*)?(?:\?.*)?/,
    },
    {
      type: RegistrationSourceKind.ContentHubBundle,
      regex: /^\/(en|se|fi|no)\/needs-and-themes/,
    },
  ];

  for (const pattern of patterns) {
    const match = currentUrl.match(pattern.regex);
    if (match) {
      const params = Array.prototype.reduce.call(
        Object.entries(match.groups || {}),
        (acc, item) => {
          if (item[1]) {
            acc[item[0]] = item[1];
          }
          return acc;
        },
        {}
      );

      return {
        type: pattern.type,
        params,
      };
    }
  }

  return {
    type: RegistrationSourceKind.Video,
    params: {},
  };
};
