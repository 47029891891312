import React, { EventHandler, useRef, useEffect, ChangeEvent } from 'react';
import Styled from 'styled-components';
import { P } from '@components/typography/Paragraph';

const Wrapper = Styled.div`
  position: relative;
`;

const Input = Styled.input`
  position: absolute;
  left: 27.5px;
  top: 50%;
  height: 20px;
  width: 20px;
  border: 1px solid ${({ theme }) => theme.colors.neutrals['black-1']};
  border-radius: 50%;
  margin-top: 0;
  margin-right: 15px;
  background-color: white;
  appearance: none;
  transform: translate(-50%, -50%);
  cursor: pointer;
  outline: none;

  &:checked {
    border: 5px solid ${({ theme }) => theme.colors.primary['blue-2']};
    background-color: ${({ theme }) => theme.colors.neutrals.white};
  }
`;

const Label = Styled.label`
  display: block;
  border: 2px solid transparent;
  border-radius: 3px;
  padding: 15px 20px 15px 55px;
  background-color: ${({ theme }) => theme.colors.neutrals['light-gray']};
  cursor: pointer;

  ${Input}:checked ~ & {
    border: 2px solid ${({ theme }) => theme.colors.primary['blue-2']};
    background-color: ${({ theme }) => theme.colors.neutrals['white']};
  }
  /* for performance we should use a before/after for background with opacity */
  transition: background-color 200ms ease-in;
`;

const Title = Styled(P)`
  color: ${({ theme }) => theme.colors.neutrals['black']};
  font-weight: 500;
  line-height: 28px;
  margin: 0 0 5px;
`;

const Desc = Styled(P)`
  color: ${({ theme }) => theme.colors.neutrals['black']};
  line-height: 22px;
  margin: 0;
`;

declare type Props = {
  className?: string;
  name?: string;
  title?: string | React.ReactNode;
  desc?: string | React.ReactNode;
  value?: string;
  onChange?: EventHandler<ChangeEvent<HTMLInputElement>>;
  checked?: boolean;
  selected?: boolean;
};

const SubscriptionOption = ({
  className = '',
  name,
  title,
  desc,
  value,
  onChange = () => null,
  selected = false,
  ...otherProps
}: Props) => {
  const inputid = Math.floor(Math.random() * 1000).toString();
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (otherProps.hasOwnProperty('checked')) {
      inputRef.current.checked = true;
    }
  }, []);

  return (
    <Wrapper
      className={className}
      data-testtype="SubscriptionOption"
      data-testid={otherProps['data-testid'] || 'SubscriptionOption'}
      {...(selected ? { 'data-testselected': true } : {})}
    >
      <Input
        ref={inputRef}
        id={inputid}
        type="radio"
        name={name}
        value={value}
        onChange={onChange}
        data-testid="SubscriptionOption--Input"
        checked={selected}
      />
      <Label htmlFor={inputid} data-testid="SubscriptionOption--Label">
        <Title fontSize="18px" data-testid="SubscriptionOption--Title">
          {title}
        </Title>
        <Desc fontSize="14px" data-testid="SubscriptionOption--Desc">
          {desc}
        </Desc>
      </Label>
    </Wrapper>
  );
};

export default SubscriptionOption;
