import React from 'react';
import styled from 'styled-components';

const Input = styled.input<{
  borderRadiusComplete: boolean;
  errorStyle: boolean;
}>`
  /** Layout **/
  width: 100%;
  background-color: ${props => props.theme.colors.neutrals.white};
  padding: 11px;
  font-size: 16px;
  outline: 0;
  border: 1px solid
    ${props =>
      props.errorStyle
        ? props.theme.colors.secondary['red-1']
        : props.theme.colors.interactive['color-18']};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  ${props =>
    props.borderRadiusComplete
      ? `
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        `
      : ''}
`;

type PropsType = React.InputHTMLAttributes<HTMLInputElement> & {
  type?: string;
  id?: string;
  name?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  errorStyle?: boolean;
  borderRadiusComplete?: boolean;
  style?: React.CSSProperties;
  required?: boolean;
  readOnly?: boolean;
};

const Input2 = React.forwardRef(
  (props: PropsType, ref: React.RefObject<HTMLInputElement>) => {
    return (
      <Input
        ref={ref}
        type={props.type}
        id={props.id}
        value={props.value}
        readOnly={props.readOnly}
        onChange={props.onChange}
        onClick={props.onClick}
        errorStyle={props.errorStyle}
        style={props.style}
        borderRadiusComplete={props.borderRadiusComplete}
        {...props}
      />
    );
  }
);

Input2.toString = Input.toString;

export default Input2;
