import Link, { LinkProps } from 'next/link';
import styled, { css } from 'styled-components';
import { UrlObject } from 'url';
import Spinner, { SpinnerEl } from './Spinner';

const getAppearanceProperties = ({ outline = 'false', link = 'false' }) => {
  return {
    dark: css`
      ${({ theme }) => {
        const color = theme.colors.neutrals.black;
        if (link === 'true') {
          return `
            --color: ${color};
            --border: none;
            --bg-color: transparent;
            --px: 0px;
            --py: 0px;
          `;
        } else if (outline === 'true') {
          return `
            --color: ${color};
            --border: 1px solid ${color};
            --bg-color: transparent;
          `;
        } else {
          return `
            --color: #fff;
            --border: 1px solid ${color};
            --bg-color: ${color};
          `;
        }
      }}
    `,
    // legacy button color
    'default-blue': css`
      ${({ theme }) => {
        const color = theme.colors.primary['blue-2'];
        if (link === 'true') {
          return `
            --color: ${color};
            --border: none;
            --bg-color: transparent;
            --px: 0px;
            --py: 0px;
          `;
        } else if (outline === 'true') {
          return `
            --color: ${color};
            --border: 1px solid ${color};
            --bg-color: transparent;
          `;
        } else {
          return `
            --color: #fff;
            --border: 1px solid ${color};
            --bg-color: ${color};
          `;
        }
      }}
    `,
    blue: css`
      ${({ theme }) => {
        const color = theme.colors.primary['blue-1'];
        if (link === 'true') {
          return `
            --color: ${color};
            --border: none;
            --bg-color: transparent;
            --px: 0px;
            --py: 0px;
          `;
        } else if (outline === 'true') {
          return `
            --color: ${color};
            --border: 1px solid ${color};
            --bg-color: transparent;
          `;
        } else {
          return `
            --color: #fff;
            --border: 1px solid ${color};
            --bg-color: ${color};
          `;
        }
      }}
    `,
    'dark-blue': css`
      ${() => {
        const color = 'rgb(36, 50, 64)';
        if (link === 'true') {
          return `
            --color: ${color};
            --border: none;
            --bg-color: transparent;
            --px: 0px;
            --py: 0px;
          `;
        } else if (outline === 'true') {
          return `
            --color: ${color};
            --border: 1px solid ${color};
            --bg-color: transparent;
          `;
        } else {
          return `
            --color: #fff;
            --border: 1px solid ${color};
            --bg-color: ${color};
          `;
        }
      }}
    `,
    'crystal-blue': css`
      ${({ theme }) => {
        const color = theme.colors.primary.crystalBlue;
        if (link === 'true') {
          return `
            --color: ${color};
            --border: none;
            --bg-color: transparent;
            --px: 0px;
            --py: 0px;
          `;
        } else if (outline === 'true') {
          return `
            --color: ${color};
            --border: 1px solid ${color};
            --bg-color: transparent;
          `;
        } else {
          return `
            --color: #fff;
            --border: 1px solid ${color};
            --bg-color: ${color};
          `;
        }
      }}
    `,
    white: css`
      ${({ theme }) => {
        const color = theme.colors.neutrals['white'];
        if (link === 'true') {
          return `
            --color: ${color};
            --border: none;
            --bg-color: transparent;
            --px: 0px;
            --py: 0px;
          `;
        } else if (outline === 'true') {
          return `
            --color: ${color};
            --border: 1px solid ${color};
            --bg-color: transparent;
          `;
        } else {
          return `
            --color: ${theme.colors.neutrals.black};
            --border: 1px solid ${color};
            --bg-color: ${color};
          `;
        }
      }}
    `,
    stone: css`
      ${({ theme }) => {
        const color = theme.colors.primary.stone;

        if (link === 'true') {
          return `
            --color: ${color};
            --border: none;
            --bg-color: transparent;
            --px: 0px;
            --py: 0px;
          `;
        } else if (outline === 'true') {
          return `
            --color: ${color};
            --border: 1px solid ${color};
            --bg-color: transparent;
          `;
        } else {
          return `
            --color: ${theme.colors.neutrals.white};
            --border: 1px solid ${color};
            --bg-color: ${color};
          `;
        }
      }}
    `,
    green: css`
      ${({ theme }) => {
        const color = theme.colors.primary.green;

        if (link === 'true') {
          return `
            --color: ${color};
            --border: none;
            --bg-color: transparent;
            --px: 0px;
            --py: 0px;
          `;
        } else if (outline === 'true') {
          return `
            --color: ${color};
            --border: 1px solid ${color};
            --bg-color: transparent;
          `;
        } else {
          return `
            --color: ${theme.colors.neutrals.white};
            --border: 1px solid ${color};
            --bg-color: ${color};
          `;
        }
      }}
    `,
    gold: css`
      ${({ theme }) => {
        const color = theme.colors.secondary.gold;

        if (link === 'true') {
          return `
            --color: ${color};
            --border: none;
            --bg-color: transparent;
            --px: 0px;
            --py: 0px;
          `;
        } else if (outline === 'true') {
          return `
            --color: #5A4920;
            --border: 1px solid ${color};
            --bg-color: transparent;
          `;
        } else {
          return `
            --color: ${theme.colors.neutrals.white};
            --border: 1px solid ${color};
            --bg-color: ${color};
          `;
        }
      }}
    `,
    red: css`
      ${({ theme }) => {
        const color = theme.colors.secondary['red-1'];

        if (link === 'true') {
          return `
            --color: ${color};
            --border: none;
            --bg-color: transparent;
            --px: 0px;
            --py: 0px;
          `;
        } else if (outline === 'true') {
          return `
            --color: ${color};
            --border: 1px solid ${color};
            --bg-color: transparent;
          `;
        } else {
          return `
            --color: ${theme.colors.neutrals.white};
            --border: 1px solid ${color};
            --bg-color: ${color};
          `;
        }
      }}
    `,
    pink: css`
      ${({ theme }) => {
        const color = theme.colors.primary['pink-2'];

        if (link === 'true') {
          return `
            --color: ${color};
            --border: none;
            --bg-color: transparent;
            --px: 0px;
            --py: 0px;
          `;
        } else if (outline === 'true') {
          return `
            --color: ${color};
            --border: 1px solid ${color};
            --bg-color: transparent;
          `;
        } else {
          return `
            --color: ${theme.colors.neutrals.white};
            --border: 1px solid ${color};
            --bg-color: ${color};
          `;
        }
      }}
    `,

    'vibrant-blue': css`
      ${({ theme }) => {
        const color = theme.colors.secondary['blue'];

        if (link === 'true') {
          return `
            --color: ${color};
            --border: none;
            --bg-color: transparent;
            --px: 0px;
            --py: 0px;
          `;
        } else if (outline === 'true') {
          return `
            --color: ${color};
            --border: 1px solid ${color};
            --bg-color: transparent;
          `;
        } else {
          return `
            --color: ${theme.colors.neutrals.white};
            --border: 1px solid ${color};
            --bg-color: ${color};
          `;
        }
      }}
    `,
    'matcha-green': css`
      ${({ theme }) => {
        const color = theme.colors.secondary['green-3'];

        if (link === 'true') {
          return `
            --color: ${color};
            --border: none;
            --bg-color: transparent;
            --px: 0px;
            --py: 0px;
          `;
        } else if (outline === 'true') {
          return `
            --color: ${color};
            --border: 1px solid ${color};
            --bg-color: transparent;
          `;
        } else {
          return `
            --color: ${theme.colors.neutrals.white};
            --border: 1px solid ${color};
            --bg-color: ${color};
          `;
        }
      }}
    `,
  };
};

const getSizeProperties = ({ rounded }) => {
  return {
    large: css`
      --font-size: 16px;
      --border-radius: ${rounded === 'true' ? '100vh' : '5px'};
      --py: 18px;
      --px: 32px;
    `,
    medium: css`
      --font-size: 13px;
      --border-radius: ${rounded === 'true' ? '100vh' : '5px'};
      --py: 12px;
      --px: 24px;
    `,
    small: css`
      --font-size: 13px;
      --border-radius: ${rounded === 'true' ? '100vh' : '5px'};
      --py: 8px;
      --px: 12px;
    `,
  };
};

type Appearance = keyof ReturnType<typeof getAppearanceProperties>;
type Size = keyof ReturnType<typeof getSizeProperties>;

const EL = styled(({ children, ...props }) => {
  if (props.href) {
    return (
      <Link href={props.href} {...props}>
        {children}
      </Link>
    );
  } else {
    return (
      <button type="button" {...props}>
        {children}
      </button>
    );
  }
})<{
  appearance?: Appearance;
  size?: Size;
  rounded?: boolean;
  outline?: boolean;
  link?: boolean;
  disabled?: boolean;
}>`
  /* size & spacing */
  ${({ size = 'medium', rounded }) => getSizeProperties({ rounded })[size]}

  /* colors */
  ${({ appearance, link, outline }) =>
    getAppearanceProperties({ outline, link })[appearance]}


  &:hover {
    opacity: .9;
  }
  

  display: inline-flex;
  align-items: center;
  color: var(--color);
  font-size: var(--font-size);
  line-height: 1.4;
  font-weight: 600;
  text-decoration: none;
  border: var(--border, none);
  border-radius: var(--border-radius);
  padding: var(--py) var(--px);
  background-color: var(--bg-color);
  box-shadow: none;
  cursor: pointer;
  ${({ disabled }) =>
    disabled
      ? `
        opacity: .5;
        pointer-events: none;
      `
      : ''}


  ${SpinnerEl} .c-spinner {
    --spinner-color: var(--color);
  }
`;

export type Props = Partial<Omit<LinkProps, 'as'>> &
  React.ButtonHTMLAttributes<HTMLButtonElement> &
  Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> & {
    className?: string;
    appearance?: Appearance;
    size?: Size;
    href?: string | UrlObject;
    rounded?: boolean;
    outline?: boolean;
    disabled?: boolean;
    link?: boolean;
    loading?: boolean;
    onClick?(event: React.MouseEvent): void;
    children: React.ReactNode | React.ReactNode[];
  };

export const Button = (props: Props) => {
  const {
    className,
    href,
    appearance = 'blue',
    size = 'medium',
    disabled = false,
    rounded = null,
    outline = null,
    link = null,
    onClick = null,
    children,
    target,
    loading = false,
    ...otherProps
  } = props;

  return (
    <EL
      className={className}
      href={href}
      appearance={appearance}
      size={size}
      rounded={rounded ? 'true' : 'false'}
      link={link ? 'true' : 'false'}
      outline={outline ? 'true' : 'false'}
      onClick={onClick}
      target={target}
      disabled={disabled || loading}
      {...otherProps}
    >
      {loading ? <Spinner /> : children}
    </EL>
  );
};

Button.toString = EL.toString;
