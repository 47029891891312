import styled from 'styled-components';
import { Box, Flex } from '@components/layout/Grid';
import useFlags from '@lib/hooks/useFlags';
import { FacebookAuthButton } from '@ecosystems/socialAuth/FacebookAuth';
import { GoogleAuthButton } from '@ecosystems/socialAuth/GoogleAuth';
import { AppleAuthbutton } from '@ecosystems/socialAuth/AppleAuth';

const Title = styled.p`
  display: flex;
  align-items: center;
  color: #242424;
  font-size: 13px;
  text-transform: uppercase;
  line-height: 22px;
  opacity: 0.5;

  :after,
  :before {
    content: '';
    flex: 1;
    height: 1px;
    background-color: #242424;
    opacity: 0.3;
  }

  :after {
    margin-left: 8px;
  }

  :before {
    margin-right: 8px;
  }
`;

type UserInfo = {
  firstName: string;
  lastName: string;
  picture?: string;
};

export type AuthCallback = (token: string, userInfo?: UserInfo) => void;

type Props = {
  title?: string;
  onFacebookAuth: AuthCallback;
  onAppleAuth: AuthCallback;
  onGoogleAuth: AuthCallback;
  tokenOnly?: boolean;
};
const SocialAuth = (props: Props) => {
  const [flags] = useFlags();
  const { title = 'Or sign up with' } = props;

  return (
    <Box>
      <Title>{title}</Title>
      <Flex my={20}>
        <GoogleAuthButton
          onAuth={props.onGoogleAuth}
          tokenOnly={props.tokenOnly}
        />
        <AppleAuthbutton onAuth={props.onAppleAuth} />
        {!flags?.disable_fb_auth ? (
          <FacebookAuthButton
            onAuth={props.onFacebookAuth}
            tokenOnly={props.tokenOnly}
          />
        ) : null}
      </Flex>
    </Box>
  );
};

export default SocialAuth;
