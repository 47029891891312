import { createTheme } from '@material-ui/core';

export default createTheme({
  breakpoints: {
    // keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 425,
      md: 768,
      lg: 1024,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      light: `#56b9e5`, //purple[300],
      main: `#129edc`, //purple[500],
      dark: `#129edc`, //purple[700],
    },
    secondary: {
      light: `#fff`, //green[300],
      main: `#fff`, //green[500],
      dark: `#fff`, //green[700],
    },
    background: {
      default: '#fff',
    },
  },
});
