import { isPast } from 'date-fns/isPast';
import { isFuture } from 'date-fns/isFuture';
import { addDays } from 'date-fns/addDays';
import { LiveEventPaymentKind } from '@gql/generated';

function isClosed(_, { readField }) {
  const registrationEndDate = readField('registrationEndDate');
  const bookingAvailable = readField('bookingAvailable');
  const expiredEvent =
    isPast(new Date(registrationEndDate)) || !bookingAvailable;
  return expiredEvent;
}

function isExpired(_, { readField }) {
  const eventDates = readField('eventDates');
  return eventDates?.length > 0
    ? isPast(new Date(eventDates[eventDates?.length - 1]))
    : false;
}

function isComingSoon(_, { readField }) {
  const registrationStartDate = readField('registrationStartDate');
  const expiredEvent = readField('expiredEvent');
  const comingSoonEvent =
    !expiredEvent && isFuture(new Date(registrationStartDate));
  return comingSoonEvent;
}

export const liveEventPolicy = {
  typePolicies: {
    LinkToRecording: {
      keyFields: false as const,
      fields: {
        expiresAt(value, { readField }) {
          if (value) {
            return value;
          } else {
            const date = readField('eventDate');
            if (date) {
              return addDays(new Date(date), 3).toISOString();
            }
          }
        },
        isExpired(_, { readField }) {
          const date = readField('expiresAt');
          if (date) {
            return isPast(new Date(date));
          }
        },
      },
    },
    LiveEvent: {
      fields: {
        theme(value) {
          return String(value).replaceAll('_', ' ');
        },
        price(price, { readField }) {
          const paymentKind = readField('paymentKind');
          if (paymentKind === LiveEventPaymentKind['LivePremium']) {
            return null;
          }
          return price;
        },
        isExpired,
        isClosed,
        isComingSoon,
        instructors(_, { readField }) {
          const instructors = [];
          const primaryInstructor = readField('primaryInstructor');
          const otherInstructors = readField('otherInstructors');

          if (primaryInstructor) {
            instructors.push(primaryInstructor);
          }

          if (otherInstructors) {
            instructors.push(...otherInstructors);
          }

          return instructors;
        },
      },
    },
  },
};

export const searchliveEventPolicy = {
  typePolicies: {
    SearchLiveEvent: {
      fields: {
        price() {
          return null;
        },
        isClosed,
        isComingSoon,
      },
    },
  },
};
